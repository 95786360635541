<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>服务器管理</h1>
      <span class="breadcrumb" align="right">
        <el-button
          type="primary"
          size="medium"
          plain
          @click="toAdd"
          icon="el-icon-plus"
          >新增</el-button
        >
      </span>
    </section>
    <section class="content">
      <search-panel @search="search()">
        <search-field>
          <el-form-item label="服务器名称：">
            <el-input v-model="params.serverName" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="服务器IP：">
            <el-input v-model="params.serverIP" />
          </el-form-item>
        </search-field>
        <search-field :span = "2">
          <el-form-item label="用途：">
            <useTypeCheckbox
              v-model="params.serverMachineUseTypeList"
            ></useTypeCheckbox>
          </el-form-item>
        </search-field>
      </search-panel>

      <span style="color: #888">
        共
        <span class="text-red">{{ result.total }}</span> 条符合查询条件
      </span>

      <el-table
        v-loading="loading"
        :data="result.tableData"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">{{
            result.pageNumber * result.pageSize + scope.$index + 1
          }}</template>
        </el-table-column>
        <el-table-column prop="serverName" label="服务器名称"></el-table-column>
        <el-table-column prop="serverIP" label="服务器IP"></el-table-column>
        <el-table-column
          prop="lastModifiedDate"
          label="更新日期"
          align="center"
          :formatter="dateFormat"
          width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="toInfo(scope.row.id)"
              >详情</el-button
            >
            <el-button type="text" @click="toEdit(scope.row.id)"
              >修改</el-button
            >
            <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <list-pagination :total="result.total" @change="handleCurrentChange" />
    </section>
  </div>
</template>

<script>
import { getServerMachineList } from "@/api/server.js";
import moment from "moment";
import searchPanel from "@/components/list/SearchPanel.vue";
import searchField from "@/components/list/SearchField.vue";
import listPagination from "@/components/list/ListPagination.vue";
import useTypeCheckbox from "../components/useTypeCheckbox.vue";

export default {
  components: {
    searchPanel,
    searchField,
    listPagination,
    useTypeCheckbox,
  },
  data() {
    return {
      params: {
        serverName: "",
        serverIP: "",
        serverMachineUseTypeList:[],
        pageNumber: 0,
        pageSize: 10,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    };
  },
  methods: {
    search() {
      getServerMachineList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
      });
    },
    handleCurrentChange: function (val) {
      this.params.pageNumber = val - 1;
      this.search();
    },
    toAdd() {
      this.$router.push({
        name: "sys_admin_serverMachine_add",
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_info",
        params: { id: param },
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_edit",
        params: { id: param },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_del",
        params: { id: param },
      });
    },
    dateFormat: function (row, column) {
      return moment(row[column.property]).format("YYYY-MM-DD");
    },
  },
  created() {
    this.search();
  },
  mounted() {},
};
</script>

<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>