<template>
  <span v-if="zwwxAuthEnabled"><iframe :src="zwwxAuthLogoutUrl" style="width:0px;height:0px;display:none"></iframe></span>
</template>

<script>
import { toLogout } from "@/utils/loginManager";

export default {
  name: "logout",
  data() {
    return {
      logoutTimer: '',
      logoutDialogLoader: '',
      zwwxAuthEnabled: false,
      zwwxAuthLogoutUrl: window.APP_CONFIG.VUE_APP_ZWWX_AUTH_LOGOUT_URL,
      callback: false
    };
  },
  methods: {
    handleLogout() {
      if (this.logoutDialogLoader) {
        this.logoutDialogLoader.close();
      }
      toLogout(this.$store, this, false);
    },
  },
  mounted: function() {
    // if (this.zwwxAuthEnabled) {
    //   this.logoutTimer = setTimeout(this.handleLogout, 2000);
    if(this.callback){
      // }else{
      this.handleLogout();
    }
    //}
  },
  created: function() {
    if (window.APP_CONFIG) {
      this.zwwxAuthEnabled = window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === "true";
    }
    // 启用了粤政易登录，并且不带callback参数时，需要跳转到统一认证退出
    if (this.zwwxAuthEnabled && window.location.href.indexOf("callback=true") == -1) {
      // 直接跳转到统一认证的退出地址，带上当前的地址作为回调，并且设置一个参数，用于回调时判断这次请求是回调请求，不再跳转到统一认证的退出地址
      window.location.href = this.zwwxAuthLogoutUrl + window.location.href + "?callback=true";
      this.callback = false;
      // this.logoutDialogLoader = this.$loading({
      //   lock: true,
      //   text: "正在退出，请稍后......",
      //   spinner: "el-icon-loading",
      // });
    }
    else{
      this.callback = true;
    }
  },
  beforeDestroy() {
    if(this.logoutTimer){
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    this.logoutDialogLoader = null;
  },
  watch: {},
};
</script>
