import request from '@/api/request'

// 保存配置
export function saveConfig(appId, name, mode, config) {
  return request({
    url: `/plugin/save_${mode}_config`,
    params: {
      name,
      appId
    },
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(config)
  })
}

export function getConfig(appId, name) {
  return request({
    url: '/plugin/get_config',
    params: {
      name,
      appId
    }
  })
}
