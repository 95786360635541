<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col>
              <el-form-item label="任务名称：" >
                <el-input v-model="implementTask.taskName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：" >
                <el-input v-model="backendModule.moduleCode" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：" >
                <el-input v-model="backendModule.moduleName" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="模块版本号：">
                <el-input v-model="backendModule.version" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块版本标识：">
                <el-input v-model="backendModule.versionID" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="服务器：" >
                <serverSelector v-model="implementTask.serverMachineID"></serverSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="端口：" prop="implementTask.deployPort">
                <el-input v-model="implementTask.deployPort"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="部署路径：" >
                <el-input v-model="implementTask.deployPath"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="implementTask.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
          <el-button type="danger" icon="el-icon-upload2" @click="commit()">删除</el-button>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getImplementTaskById, delImplementTaskById,getBackendModuleById } from "@/api/backend.js";
import serverSelector from "../../components/serverSelector";
export default {
  components: { serverSelector },
  data() {
    return {
      moduleID:"",
      dataId: "",
      titleName: "删除实施任务",
      implementTask: {},
      backendModule: {},
    };
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.dataId = this.$route.params.id;
    getImplementTaskById(this.dataId).then((response) => {
      this.implementTask = response;
    });
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendModule = response;
    });
  },
  methods: {
    commit() {
      this.$confirm(
        "删除实施任务" +
          this.implementTask.taskName,
        "确认是否删除实施任务",
        {}
      ).then(() => {
        delImplementTaskById(this.dataId).then(
          (response) => {
            if (response) {
              this.toModule();
            }
          },
          (err) => {
            if (err) {
              this.$confirm("", "删除实施任务异常", {});
            }
          }
        );
      });
    },
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID,tabName:"taskInfo" }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_info",
        params: { id: param,moduleID:this.moduleID }
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>