<template>
	<div style="text-align: left;margin-top: 18px;">
		<el-pagination
			:total="total"
      :current-page="currentPage+1"
      :page-size="pageSize"
			medium
			background
			layout="prev, pager, next"
			prev-text="上一页"
			next-text="下一页"
			@current-change="change"
		/>
	</div>
</template>

<script>
export default {
  name: "ListPagination",
  props: {
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
  },
  methods: {
    change(page) {
      this.$emit('change', page);
    }
  }
};
</script>

<style>
</style>