import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import appModules from './modules/appModules'
import division from './modules/division'
import VuexPersistence from 'vuex-persist'
import cache from './modules/cache'
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ["user","division","appModules"]
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,user,division,appModules,cache
  },
  plugins: [vuexSession.plugin]
})
