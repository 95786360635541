<template>
  <div>
    <el-table
      v-loading="loading"
      :data="result.tableData"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" align="center" width="60">
        <template slot-scope="scope">{{result.pageNumber*result.pageSize+scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="moduleCode" label="模块代码"></el-table-column>
      <el-table-column prop="moduleName" label="模块名称"></el-table-column>
      <el-table-column prop="fileCode" label="配置文件代码"></el-table-column>
      <el-table-column prop="fileName" label="配置文件名称"></el-table-column>
      <el-table-column prop="version" label="版本号"></el-table-column>
      <el-table-column prop="versionID" label="版本标识" width="130"></el-table-column>
      <el-table-column prop="status" label="状态" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'NORMAL'">正常</span>
          <span v-else-if="scope.row.status == 'DELETED'">废置</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastModifiedDate"
        label="更新日期"
        align="center"
        :formatter="dateFormat"
        width="100"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="text" @click="toInfo(scope.row.id)">详情</el-button>
          <el-button type="text" @click="toEdit(scope.row.id)">修改</el-button>
          <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getBackendModuleById } from "@/api/backend.js";
import { getBackendConfigList } from "@/api/backend.js";
import moment from "moment";

export default {
  name: "moduleConfigList",
  components: {},
  props: {
    pmoduleID: {},
  },
  data() {
    return {
      params: {
        id: this.pmoduleID,
        moduleCode: "",
        versionID: "",
        status: "",
        configLevel: "",
        pageNumber: 0,
        pageSize: 100,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    };
  },
  methods: {
    search() {
      getBackendConfigList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_info",
        params: { id: param, moduleID: this.params.id },
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_edit",
        params: { id: param, moduleID: this.params.id },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_del",
        params: { id: param, moduleID: this.params.id },
      });
    },
    dateFormat: function (row, column) {
      return moment(row[column.property]).format("YYYY-MM-DD");
    },
  },
  created() {
    let backendModulePromise = new Promise((reslove) => {
      getBackendModuleById(this.params.id).then((response) => {
        reslove(response);
      });
    });
    backendModulePromise.then((backendModule)=>{
      this.params.moduleCode = backendModule.moduleCode;
      this.params.versionID = backendModule.versionID;
      this.search();
    });
    
  },
  mounted() {},
  watch: {
    pmoduleID(newVal) {
      this.params.id = newVal;
    },
  },
};
</script>

