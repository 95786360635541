<template>
  <div class="login-gb">
      <h1>HTTP Status 404 – Not Found</h1>
  </div>
</template>

<script>

export default {
  name: "404",
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    
  },
  mounted: function () {
  },
  destroyed() {
  },
};
</script>

<style scoped>
</style>
