<template>
  <div>
    <el-dialog title="选择所属部门" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-alert title="用户在粤政易中设置的所属部门未能在本系统匹配相应的部门，请选择本用户所属部门。" type="warning"> </el-alert>
      <el-form :model="userOrgForm" ref="userOrgForm" :rules="rules">
        <el-row>
          <el-col>
            <el-form-item label="所属区域：" prop="divisionCode">
              <division-selector v-model="userOrgForm.divisionCode" @change="divisionChange"></division-selector>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="所属机构：" prop="organizationId">
              <el-select v-model="userOrgForm.organizationId" placeholder="请先选择所属区划，再选择机构" style="width:100%" clearable>
                <el-option v-for="item in orgList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" icon="el-icon-upload2" @click="commit()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCurrentUser } from "@/utils/loginManager";
import { setUserOrg } from "@/api/auth";
import divisionSelector from "@/components/DivisionSelector";
import { getOrgByDivisionCode } from "@/api/baseobject.js";

export default {
  components: { divisionSelector },
  name: "Login",
  data() {
    return {
      userOrgForm: { organizationId: null },
      orgList: [], //机构控件选项
      dialogFormVisible: false,
      loader: {},
      thirdRedirectUrl: '',
      rules: {
        divisionCode: [{ required: true, message: "请选择行政区划", trigger: "blur" }],
        organizationId: [{ required: true, message: "请选择所属机构", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      inited: (state) => state.app.inited,
      orgID: (state) => state.user && state.user.userdata && state.user.userdata.userInfo && state.user.userdata.userInfo.orgID,
    }),
  },
  methods: {
    async authSuccess() {
      console.log("third account check success");
      if (this.$route.query.jtoken) {
        //await this.$store.dispatch("set_token", this.$route.query.jtoken);
        //await getCurrentUser(this.$store);
        if (this.$route.query.thirdUserId) {
          sessionStorage.setItem("thirdUserId", this.$route.query.thirdUserId);
          localStorage.setItem("thirdUserId", this.$route.query.thirdUserId);
        }

        if ("987654321987654321" === this.orgID) {
          if (window.APP_CONFIG.VUE_APP_ZWWX_SELECT_ORG === "true") {
            this.dialogFormVisible = true;
          } else {
            this.$alert("用户未与本地机构建立关系，请联系管理员处理", "用户数据异常", {
              confirmButtonText: "确定",
              callback: () => {
                this.$router.push({ name: "login" });
              },
            });
          }
        } else {
          this.toSuccessHome();
        }
      }
    },
    toSuccessHome() {
      console.log("start toSuccessHome");
      let redirectPage = { name: "home" };
      //增加从localStorage找loginAfterRedirect是为了解决从第三方回跳时sessionStorage被清空的问题。
      let loginAfterRedirect = sessionStorage.getItem("loginAfterRedirect") || localStorage.getItem("loginAfterRedirect");
      if (window.APP_CONFIG.VUE_APP_THIRD_AUTH_SUCCESS_HOME) {
        redirectPage = JSON.parse(window.APP_CONFIG.VUE_APP_THIRD_AUTH_SUCCESS_HOME);
      }
      if (loginAfterRedirect && JSON.parse(loginAfterRedirect).path) {
        redirectPage = JSON.parse(loginAfterRedirect);
        sessionStorage.removeItem("loginAfterRedirect");
        localStorage.removeItem("loginAfterRedirect");
      }
      //第三方重定向外部地址
      if(this.thirdRedirectUrl){
        window.open(decodeURIComponent(this.$route.query.thirdRedirectUrl))
      } else {
        this.$router.push(redirectPage);
      } 
    },
    commit() {
      this.$refs["userOrgForm"].validate((valid) => {
        if (valid) {
          this.loader = this.$loading({
            lock: true,
            text: "正在提交数据，请稍等......",
            spinner: "el-icon-loading",
          });
          setUserOrg(JSON.stringify(this.userOrgForm)).then(
            () => {
              this.loader.close();
              this.dialogFormVisible = false;
              getCurrentUser(this.$store);
              this.toSuccessHome();
            },
            (err) => {
              this.loader.close();
              if (err.response.status == 500) {
                this.$confirm(err.response.data.data, "设置用户所属机构异常", {});
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    divisionChange(d) {
      this.userOrgForm.organizationId = null;
      this.userOrgForm.divisionCode = d.code || "";

      this.orgList = [];
      if (d.code) {
        getOrgByDivisionCode(d.code).then((res) => {
          this.orgList = res.map((p_org) => {
            let s_org = { value: p_org.id, name: p_org.name };
            return s_org;
          });
        });
      }
    },
  },
  created: function() {
    if(this.$route.query.thirdRedirectUrl){
      this.thirdRedirectUrl = encodeURIComponent(this.$route.query.thirdRedirectUrl)
    }
  },
  watch: {
    inited() {
      console.log("inited，完成应用初始化，开始处理thirdAuthSuccess页面信息。");
      this.authSuccess();
    },
  },
};
</script>

<style scoped></style>
