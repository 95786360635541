<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>前端模块配置管理</h1>
      <span class="breadcrumb" align="right">
        <el-button
          type="primary"
          size="medium"
          plain
          icon="el-icon-plus"
          @click="add"
          >新增</el-button
        >
        <!-- <el-upload :limit="1" :action="uploadUrl" :data="uploadData" :headers="headers" :multiple="false" :on-change="onChanged" :auto-upload="false" :show-file-list="false" ref="upload">
					<el-button type="primary" size="medium" plain icon="el-icon-plus">新增</el-button>
				</el-upload> -->
      </span>
    </section>
    <section class="content">
      <search-panel @search="search()">
        <search-field>
          <el-form-item label="模块代码">
            <el-input v-model="params.name" @input="search" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="模块名称">
            <el-input v-model="params.description" @input="search" />
          </el-form-item>
        </search-field>
      </search-panel>
      <span style="color: #888">
        共 <span class="text-red">{{ total }}</span> 条符合查询条件
      </span>
      <el-table
        class="result"
        :data="list"
        border="border"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        @expand-change="handleExpandChange"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-if="!loadfailed"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            以下为该模块的所有历史版本
            <el-table
              :data="props.row.archives"
              style="width: 100%"
              :cell-style="archiveTableCellStyle"
              :show-header="false"
              :border="false"
              :key="props.row.updateKey"
            >
              <el-table-column
                prop="description"
                label="说明"
                width="300"
              ></el-table-column>
              <el-table-column
                prop="version"
                label="版本号"
                width="180"
              ></el-table-column>
              <el-table-column
                prop="moduleDesc"
                label="更新描述"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="createdDate"
                label="更新时间"
                :formatter="dateFormat"
                width="180"
              ></el-table-column>
              <!-- <el-table-column width="320">
								<template slot-scope="scope">{{ scope.row.id }}</template>
							</el-table-column> -->

              <el-table-column width="100">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="button-in-table"
                    v-if="scope.row.deleted"
                    @click="downloadUrl(scope.row)"
                    >下载</el-button
                  >
                  <!-- <el-button type="text" @click="rollback(scope.row)" v-if="scope.row.deleted">恢复</el-button> -->
                  <el-button
                    type="text"
                    @click="del(scope.row)"
                    v-if="scope.row.deleted"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="模块代码"
          width="180"
        ></el-table-column>
        <el-table-column prop="description" label="模块名称" width="300">
          <template slot-scope="scope">
            {{
              scope.row.description !== "undefined"
                ? scope.row.description
                : scope.row.name
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="version"
          label="版本号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="createdDate"
          label="更新时间"
          :formatter="dateFormat"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="moduleDesc"
          label="更新描述"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="button-in-table"
              v-if="!scope.row.deleted && !scope.row.isBuildin"
              @click="downloadUrl(scope.row)"
              >下载</el-button
            >
            <!-- <el-button type="text" class="button-in-table" @click="archive(scope.row)" v-if="!scope.row.deleted && !scope.row.isBuildin">废置</el-button>
						<el-button type="text" class="button-in-table" @click="rollback(scope.row)" v-if="scope.row.deleted && !scope.row.isBuildin">恢复</el-button> -->
            <!-- <el-button type="text" class="button-in-table" @click="update(scope.row)" v-if="!scope.row.isBuildin">
							<el-upload :limit="1" :action="uploadUrl" :data="uploadData" :headers="headers" :multiple="false" :on-change="onUpdateChanged" :auto-upload="false" :show-file-list="false" :ref="'file_' + scope.row.id" :before-upload="beforeUpload">更新</el-upload>
						</el-button> -->
            <el-button
              type="text"
              class="button-in-table"
              @click="update(scope.row)"
              v-if="!scope.row.isBuildin"
              >更新
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <list-pagination
        :total="total"
        :currentPage="params.pageNumber"
        @change="handleCurrentChange"
      />
    </section>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="pluginModule"
        :model="pluginModule"
        status-icon
        label-width="120px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="模块代码：" prop="name" v-if="updateItem.name">
              <el-input v-model="updateItem.name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="模块名称："
              prop="description"
              v-if="updateItem.description"
            >
              <el-input
                v-model="updateItem.description"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="备注：" prop="moduleDesc">
              <el-input
                v-model="pluginModule.moduleDesc"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="上传更新包：" v-if="!isAddOpt">
            <el-col :span="12">
              <el-upload
                :limit="1"
                :action="uploadUrl"
                :data="uploadData"
                :headers="headers"
                :multiple="false"
                :on-change="onUpdateChanged"
                :auto-upload="false"
                :show-file-list="false"
                ref="upDataload"
                ><el-button slot="trigger" size="small" type="primary"
                  >上传并更新</el-button
                ></el-upload
              >
            </el-col>
          </el-form-item>
        </el-row>
        <el-row v-if="isAddOpt">
          <el-form-item label="上传新增包：">
            <el-col :span="12">
              <el-upload
                :limit="1"
                :action="uploadUrl"
                :data="uploadData"
                :headers="headers"
                :multiple="false"
                :on-change="onChanged"
                :auto-upload="false"
                :show-file-list="false"
                ref="upload"
                ><el-button slot="trigger" size="small" type="primary"
                  >上传并新增</el-button
                ></el-upload
              >
            </el-col>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPluginModuleList2,
  getPluginModuleListArchives,
  rollbackPluginModuleLById,
  archivePluginModuleLById,
  delPluginModuleLById,
  downloadPluginModuleLById,
} from "@/api/pluginModule";
import JsZip from "jszip";
import cdn from "@/api//cdn.js";
import config from "@/config";
import axios from "@/api/request";
import searchPanel from "@/components/list/SearchPanel.vue";
import searchField from "@/components/list/SearchField.vue";
import listPagination from "@/components/list/ListPagination.vue";

export default {
  name: "ModuleList",
  components: {
    searchPanel,
    searchField,
    listPagination,
  },
  data() {
    return {
      list: [],
      archives: [],
      loading: false,
      loadfailed: false,
      uploadUrl: config.VUE_APP_API_URL + "/plugin/add",
      uploadData: {},
      pluginModule: { moduleDesc: "" },
      updateItem: {},
      title: "",
      isAddOpt: true,
      params: {
        name: "",
        description: "",
        deleted: false,
        pageNumber: 0,
        pageSize: 10,
        direction: "asc",
        directionProperties: "name",
      },
      total: 0,
      key: 1,
      dialogVisible: false,
      rules: {
        moduleDesc: [
          { required: true, message: "请填写更新的特性信息", trigger: "blur" },
          { min: 2, max: 2000, message: "请输入2-2000位字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    headers() {
      let token = this.$store.getters.token;
      return { "JWT-AUTHORIZATION": token };
    },
  },
  methods: {
    async handleExpandChange(row) {
      let res = await getPluginModuleListArchives(row.name);
      if (res) {
        row.archives = res.sort((a, b) =>
          a.version === b.version
            ? a.createdDate < b.createdDate
            : a.version < b.version
        );
        row.updateKey = +new Date();
        console.log(row);
      }
    },
    handleCurrentChange(val) {
      this.params.pageNumber = val - 1;
      this.doSearch();
    },
    downloadUrl(m) {
      //return config.VUE_APP_API_URL + `/module/${m.name}-${m.version}.zip`
      downloadPluginModuleLById(m.id).then(
        (response) => {
          if (!response) {
            this.$message.error("下载文件失败");
            return false;
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: "application/zip" })
          );
          // 文件地址
          const link = document.createElement("a");
          link.download = m.name + "." + m.version + ".zip";
          link.href = objectUrl;
          link.click();
          URL.revokeObjectURL(link); // 释放内存
        },
        (err) => {
          if (err) this.$confirm("", "下载文件异常，请联系管理员！", {});
        }
      );
    },
    sortArchivesModule(modules, key) {
      return modules.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return Date.parse(y) - Date.parse(x);
      });
    },
    search() {
      this.params.pageNumber = 0;
      this.doSearch();
    },
    async doSearch() {
      //this.$refs.upload.clearFiles()
      let res = await getPluginModuleList2(this.params);
      let list = (res && res.content) || [];
      list.map((x) => (x.updateKey = 1));
      this.list = list;
      this.total = res && res.totalElements;
    },
    archiveTableCellStyle() {
      return "border-right: none; color: #888888";
    },
    tableRowClassName({ row }) {
      if (row.deleted) {
        return "delete-row";
      }
      return "";
    },
    update(entity) {
     this.title = "更新模块";
      this.isAddOpt = false;
      this.$nextTick(() => {
        this.$refs.pluginModule.clearValidate();
        this.$refs.upDataload.clearFiles();
      });
      this.dialogVisible = true;
      //console.log(entity)
      this.updateItem = entity;
      this.pluginModule.moduleDesc = "";
      console.log(`pre update for ${this.updateItem.name}`);
    },
    archive(entity) {
      this.$confirm("确定废置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        archivePluginModuleLById(entity.id)
          .then((res) => {
            if (res == "ok") {
              this.$message({
                type: "success",
                message: "废置成功!",
              });
              window.location.reload();
            } else {
              this.search();
              this.$message.error("废置失败!");
            }
          })
          .catch(() => {
            this.search();
          });
      });
    },
    rollback(entity) {
      this.$confirm(
        "该模块版本已废置或有更新版本在用，是否确定将该模块恢复为此版本?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        rollbackPluginModuleLById(entity.id)
          .then((res) => {
            if (res == "ok") {
              this.$message({
                type: "success",
                message: "恢复成功!",
              });
              window.location.reload();
            } else {
              this.search();
              this.$message.error("恢复失败!");
            }
          })
          .catch(() => {
            this.search();
          });
      });
    },
    del(entity) {
      this.$confirm("确定永久删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPluginModuleLById(entity.id)
          .then((res) => {
            if (res == "ok") {
              this.$message({
                type: "success",
                message: "永久删除成功!",
              });
              window.location.reload();
            } else {
              this.search();
              this.$message.error("永久删除失败!");
            }
          })
          .catch(() => {
            this.search();
          });
      });
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    dateTimeFormatters(value) {
      if (value != null) {
        var dateee = new Date(value).toJSON();
        var date = new Date(+new Date(dateee) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "");
        return date;
      } else {
        return null;
      }
    },
    dateFormat(row, column) {
      if (row[column.property]) {
        var t = new Date(row[column.property]);
        return this.dateTimeFormatters(t);
      } else {
        return "-";
      }
    },
    get_module_info(file) {
      return new Promise((resolve, reject) => {
        let new_zip = new JsZip();
        new_zip
          .loadAsync(file.raw)
          .then((f) => {
            let _array = Object.values(f.files);
            let umd = _array.find((x) => {
              return (
                x.name.endsWith(".umd.js") || x.name.endsWith(".umd.min.js")|| x.name.endsWith("desktop_package_info.js")
              );
            });
            if (typeof umd !== "undefined") {
              umd.async("string").then((content) => {
                let reg = new RegExp('exports\\[\\"([^\\"]+)\\"\\]');
                let match = reg.exec(content);
                let moduleName =
                  match && match.length > 1 ? match[1] : "unknow";

                let cdnScript = Object.keys(cdn).reduce(
                  (p, c) => `${p} root["${c}"] = cdn["${c}"];`,
                  ""
                );

                // 强制转换css文件，避免加载出错
                content = content.replace('"css/"', `"/empty.css?"`);
                //平台管理打包是build,单独处理平台管理的上传
             if(umd.name==="desktop_package_info.js"){
               let str =content.replace("let package_info =","")
               let package_info = eval("(" + str + ")");
                resolve(package_info);
             }else{
               //其他模块是lib打包方式，打包的js文件内容已经包含package.json的信息
               let Fn = Function;
               let info = new Fn(
                  "cdn",
                  `let root = (typeof self !== 'undefined' ? self : this); ${cdnScript} ${content}; return self["${moduleName}"].default;`
                )(cdn);
                resolve(info.package);
             }
              });
            } else {
              reject();
            }
          })
          .catch(() => {
            console.log("not a zip file");
            reject();
          });
      });
    },
    expandVersions(flag, name) {
      console.log(`expandVersions: ${flag}, ${name}`);
      getPluginModuleListArchives().then((res) => {
        this.archives = res;
      });
    },
    onUpdateChanged(file) {
      this.$refs["pluginModule"].validate((valid) => {
        if (valid) {
          console.log("changed.");
          let that = this;
          if (!file.response) {
            console.log(`ready to update ${this.updateItem.id}`, file);
            let el = this.$refs["file_" + this.updateItem.id];
            console.log(el);
            let p = new Promise((resolve, reject) => {
              this.get_module_info(file).then((info) => {
                if (info.name !== that.updateItem.name) {
                  that.$alert(
                    `<strong>模块名称不一致！</strong><br />正在更新的模块为：<span style='color: #cc0000'>${that.updateItem.name}</span></br>实际上传的模块为：<span style='color: #cc0000'>${info.name}</span>`,
                    "更新失败",
                    {
                      type: "warning",
                      dangerouslyUseHTMLString: true,
                    }
                  );
                  reject();
                }
                info.id = this.guid();
                info.enabled = true;
                info.type = "应用模块";
                info.moduleDesc = this.pluginModule.moduleDesc;
                // 不能直接对uploadData赋值，否则会引发整个界面重新渲染，从而已选中的文件丢失。
                Object.keys(info).forEach((k) => {
                  that.uploadData[k] = info[k];
                });
                resolve();
              });
            });

            p.then(() => {
              archivePluginModuleLById(this.updateItem.id).then(() => {
                //let el = this.$refs["file_" + this.updateItem.id];
                //el.submit();
                this.uploadModuleFile(file.raw);
              });
            }).catch(() => {});
          } else {
            this.search();
            //window.location.reload()
          }
        } else {
          this.$refs.upDataload.clearFiles();
        }
      });
    },
    onChanged(file) {
      this.$refs["pluginModule"].validate((valid) => {
        if (valid) {
          let that = this;
          if (!file.response) {
            let p = new Promise((resolve) => {
              this.get_module_info(file).then((info) => {
                info.id = this.guid();
                info.enabled = true;
                info.type = "应用模块";
                info.moduleDesc = this.pluginModule.moduleDesc;
                that.uploadData = info;
                resolve();
              });
            });

            p.then(() => {
              if (this.list.some((x) => x.name === that.uploadData.name)) {
                that.$alert(
                  `<strong>模块<span style='color: #cc0000'>${that.uploadData.name}</span>已存在，请使用更新操作！</strong>`,
                  "添加失败",
                  {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                  }
                );
              } else {
                //that.$refs.upload.submit();
                this.uploadModuleFile(file.raw);
              }
            });
          } else {
            this.search();
            //window.location.reload()
          }
        } else {
          this.$refs.upload.clearFiles();
        }
      });
      //this.$refs.upload.clearFiles()
    },
    handleCreate() {},
    uploadModuleFile(file) {
      let form = new FormData();
      form.append("file", file);
      for (let [key, value] of Object.entries(this.uploadData)) {
        form.append(key, value);
      }
      axios
        .post("/plugin/add", form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          this.dialogVisible = false;
          this.search();
          //window.location.reload()
        });
    },
    add() {
      this.title = "新增模块";
      this.$nextTick(() => {
        this.$refs.pluginModule.clearValidate();
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      });
      this.dialogVisible = true;
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style scoped>
.el-upload__input {
  display: none !important;
}
.callout.callout-default {
  margin-top: 15px;
  color: #888 !important;
  background-color: #fff !important;
  border-left-color: #0097bc;
}
.el-table .delete-row {
  text-decoration: line-through;
  color: #888;
}
.button-in-table {
  padding: 0 !important;
}
.content-header .breadcrumb {
  top: 4px;
}
.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: rgb(48, 65, 86) !important;
  color: #fff !important;
  line-height: 24px;
}
</style>
