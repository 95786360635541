const appModules = {
    state: {
        appId: null,
        appModules: null,
        urlParams:{},//最后一次路由的params
    },
    mutations: {
        set_appId(state, param) {
            state.appId = param;
        },
        set_appModules(state, param) {
            state.appModules = param;
        },
        set_urlParams(state, param) {
            state.urlParams = param;
        },
    },
    actions: {
        set_appId(context, param) {
            context.commit('set_appId', param);
        },
        set_appModules(context, param) {
            context.commit('set_appModules', param);
        },
        set_urlParams(context, param) {
            context.commit('set_urlParams', param);
        }
    },
    getters: {
        appId: (state) => {
            return state.appId;
        },
        appModules: (state) => {
            return state.appModules;
        }
    }
}

export default appModules