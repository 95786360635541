import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/passTab'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.css'
import 'jquery-slimscroll'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'admin-lte/dist/css/AdminLTE.css'
import 'admin-lte/dist/css/skins/_all-skins.css'
import 'admin-lte/dist/js/adminlte.js'
import 'requirejs/require'
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'
import i18n from '@/i18n/index.js'
import IconSvg from './components/IconSvg'
import utils from './utils.js'
import VueClipBoard from 'vue-clipboard2'
Vue.use(ElementUI)
Vue.use(utils)
Vue.use(vuescroll)
Vue.component('icon-svg', IconSvg)

Vue.config.productionTip = false
Vue.config.devtools = true

import { load_modules,buildAppId } from '@/api/moduleLoader'
import { hasRole,hasPermission,hasRoleOrPermission } from '@/api/permission'

Vue.use({ hasRole,hasPermission,hasRoleOrPermission });
Vue.use(VueClipBoard)

import { initStorageListener, saveTempToken } from '@/utils/passTab'
import { processAuthOnAppInit, refresh_token, checkLoginTimeout, checkAuthOnAppInited, checkLoginValidity } from '@/utils/loginManager'

// 配置路由keepAlive状态
import cacheUtils from './utils/cacheUtils';
cacheUtils.setRouterBeforeEach(router);
initStorageListener(router, store)


new Vue({
	data() {
		return {
			beginTime: 0,
			winload: false,
			differTime: 0,
		}
	},
	router,
	store,
	i18n,
	render: (h) => h(App),
	methods: {
		//1、浏览器关闭和刷新会执行
		beforeunloadHandler(){
			sessionStorage.setItem("closeWindowTime",new Date().getTime());		
		},
	},
	async mounted() {
		try {
			sessionStorage.setItem("closeWindowTime",new Date().getTime());
			//浏览器刷新和关闭浏览器都会执行beforeunload、unload，但刷新比关闭相对耗时久
			window.addEventListener("beforeunload", this.beforeunloadHandler,false);

			//初始化appId
			await buildAppId(this);

			this.tokenTimer = setInterval(() => {
				refresh_token(store,this,true);
				
			}, 300000);//修改为5分钟刷新
			
			// 每10秒 调用检查时间的方法
			setInterval(() => {
				checkLoginTimeout(store,this);
				
			}, 10000);

			// 每30秒 检查当前客户端用户会话的有效性
			// let check_time_rate = store.state.app.config.check_time_rate
			// if(check_time_rate){
			// 	setInterval(() => {
			// 		checkLoginValidity(store,this);				
			// 	},check_time_rate-0);
			// }		

			//第一次加载时设置最新活动时间，并增加点击事件更新最新活动时间
			localStorage.setItem("lastActiveTime",new Date().getTime());
			document.onclick = function () {
				localStorage.setItem("lastActiveTime",new Date().getTime());
			}

			//第一次打开系统时处理认证相关信息
			await processAuthOnAppInit(this,store,this.$route);

			console.log("main.js init_start inited jwt : " + store.getters.token);

			// 把获取token的方法注入到Vue，从而传递给应用模块
			Vue.prototype.get_token = function () {
				return store.getters.token
			}
			
			store.commit('init_start')
			await load_modules(this,this.dialogLoader)

			//第一次打开系统模块加载完校验认证信息
			checkAuthOnAppInited(this,store,this.$route);
			//调用检测用户会话是否有效方法
			checkLoginValidity(store,this);				
			console.log("main.js mounted finish");
		} catch (error) {
			console.error("main.js 主程序加载异常："+error);
			this.$alert('您好，页面出现兼容性问题，请联系管理员检查。', {
				confirmButtonText: '关闭',
				center: true,
			});
		}finally{
			if(this.dialogLoader){
				this.dialogLoader.close();
			}
		}
		
		
	},
	async created() {
		if(!["login","dynamicLogin"].some(routeName => routeName == this.$router.currentRoute.name)){
			this.dialogLoader = this.$loading({
				lock: true,
				text: "正在加载，请稍后......",
				spinner: "el-icon-loading",
			});
		}
	},
	destroy() {
	},
	watch: {
		'$store.state.app.title': function (n) {
			document.title = n
		},
		'$store.state.user.token': function (n) {
			saveTempToken(n,false)
		},
		'$store.state.user.userdata_version': function () {
			let that = this;
			let appModules = store.getters.appModules;
			appModules.filter((s_appModule) => s_appModule.moduleType == 'PluginModule').forEach((appModule) => {
				let t_module = store.getters.actualModules()[appModule.moduleCode];
			if (t_module && t_module.initModuleGlobalData && typeof t_module.initModuleGlobalData === "function") {
				t_module.initModuleGlobalData(that); //各模块初始化以后执行initModuleGlobalData	
			}
			});
		},
	}
}).$mount('#app')
