<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="implementTaskForm"
          :model="implementTaskForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col>
              <el-form-item label="任务名称：" prop="implementTask.taskName">
                <el-input v-model="implementTaskForm.implementTask.taskName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：" prop="implementTask.moduleCode">
                <el-input v-model="backendModule.moduleCode" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：" prop="implementTask.moduleName">
                <el-input v-model="backendModule.moduleName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="模块版本号：">
                <el-input v-model="backendModule.version" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块版本标识：">
                <el-input v-model="backendModule.versionID" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="服务器：" prop="implementTask.serverMachineID">
                <serverSelector v-model="implementTaskForm.implementTask.serverMachineID"></serverSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="端口：" prop="implementTask.deployPort">
                <el-input v-model="implementTaskForm.implementTask.deployPort" oninput="value=value.replace(/[^\d]/g,'')" placeholder="该端口生成启动文件start.sh和restart.sh里的指定启动端口，若要修改启动服务端口可以到启动文件里面进行修改！"></el-input>
                <!-- <div><span style="color:red">说明：该端口生成启动文件start.sh和restart.sh里的指定启动端口，若要修改启动服务端口可以到启动文件里面进行修改！</span></div> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="部署基路径：" prop="implementTask.deployPath">
                <el-input v-model="implementTaskForm.implementTask.deployPath" placeholder="默认路径/home/java/，强烈推荐不要填写，使用默认"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="implementTaskForm.implementTask.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toModule">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { saveImplementTask, getBackendModuleById } from "@/api/backend.js";
import serverSelector from "../../components/serverSelector";
export default {
  components: { serverSelector },
  data() {
    return {
      titleName: "新建实施任务",
      moduleID: "",
      implementTaskForm: {
        implementTask: {},
      },
      backendModule: {},
      rules: {
        "implementTask.taskName": [
          { required: true, message: "请输入任务名称", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
        "implementTask.serverMachineID": [
          { required: true, message: "请选择服务器", trigger: "blur" },
        ],
        "implementTask.deployPort": [
          { required: true, message: "请输入部署端口", trigger: "blur" },
          { min: 4, max: 5, message: "请输入4-5位数字", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendModule = response;
      this.implementTaskForm.implementTask.backendModuleID=this.backendModule.id;
    });
  },
  methods: {
    commit() {
      this.$refs["implementTaskForm"].validate((valid) => {
        if (valid) {
          saveImplementTask(JSON.stringify(this.implementTaskForm)).then(
            (response) => {
              this.toInfo(response.id);
            },
            (err) => {
              if (err.response.status == 500) {
                this.$confirm(err.response.data.data, "新增后实施任务异常", {});
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_info",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID, tabName: "taskInfo" },
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>