import Vue from 'vue'
import VueI18n from 'vue-i18n'
//引入创建的zh.js 和 en.js，可以自己创建其他的语言，对应就行
import langZh from './lang/zh.js'
import langEN from './lang/en.js'
Vue.use(VueI18n)
const messages = {
  zh: {
    ...langZh
  },
  en: {
    ...langEN
  },
}
const i18n = new VueI18n({
  locale:'zh', // 语言标识
  messages,
})
export default i18n;

