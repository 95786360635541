import request from '@/api/request'


/*********************依赖包管理*****************************/

//依赖包查询列表接口
export function getDepPackageList(params) {
  return request({
    url: '/depPackage/list',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询依赖包
export function getDepPackageByName(params) {
  return request({
    url: '/depPackage/getByName',
    method: 'get',
    params: { packageName: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除依赖包
export function delDepPackageByName(params) {
  return request({
    url: '/depPackage/delByName',
    method: 'get',
    params: { packageName: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//测试服务器连接
export function testConnect(params) {
  return request({
    url: '/depPackage/testConnect',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}
