const user = {
    state: {
        userAppTag:"desktop-web-user",
        token:{},
        tokenSource:"",
        userdata:{userAccount:{},userInfo:{},roles:[]},
        userdata_version:0,//用户数据版本，刷新数据+1
        isFlagTime:0,//标识时间，用来区分是刷新浏览器还是关闭浏览器
    },
    mutations: {
        set_token(state,token) {
            state.token = token
        },
        set_userdata(state,userdata) {
            state.userdata = userdata
        },
        set_userdata_version(state) {
            state.userdata_version++;
        },
        set_tokenSource(state,tokenSource){
            state.tokenSource = tokenSource
        },
        set_isFlagTime(state,isFlagTime){
            state.isFlagTime = isFlagTime
        }
    },
    actions: {
        set_token(state,token) {
            state.commit('set_token', token)
        },
        set_userdata(state,userdata) {
            state.commit('set_userdata', userdata)
        },
        set_userdata_version(state) {
            state.commit('set_userdata_version')
        },
        set_tokenSource(state,tokenSource) {
            state.commit('set_tokenSource', tokenSource)
        },
        set_isFlagTime(state,isFlagTime) {
            state.commit('set_isFlagTime',isFlagTime)
        },
    },
    getters: {
        token: (state) => {
            return state.token
        },
        userdata: (state) => {
            return state.userdata
        },
        tokenSource: (state) => {
            return state.tokenSource
        },
        has_token: (state) => {
            return typeof(state.token) === 'string' && state.token.length > 0
        },
        isFlagTime: (state) => {
            return state.isFlagTime
        },
    }
}

export default user