<template>
  <div>
    <div class="notice" v-show="showDiaBox">
      <div>
        <span style="float: left">通知信息</span>
        <span style="float: right">
          <span @click="closeDiv">
            <i class="el-icon-close" style="font-size: 18px"></i>
          </span>
        </span>
      </div>
      <div class="contentStyle">
        <el-link :underline="false" @click="noticeDetailVisible = true">{{ topFiveNotices[currentNotice].noticeTitle }}</el-link>
      </div>
      <div class="allNotices">
        <div style="float:left;margin-top: 5px;">
          <el-link :underline="false" type="primary" @click="openAllNoticesVisible = true">查看全部通知</el-link>
        </div>
        <div style="float:left;margin-top: 5px;margin-left: 115px">
          <span @click="preNotice" style="margin-right: 15px"><i class="el-icon-arrow-left" style="color: #00aaff"></i></span>
          <span @click="nextNotice"><i class="el-icon-arrow-right" style="color: #00aaff"></i></span>
        </div>
      </div>
    </div>
    <el-dialog title="通知详情" :visible.sync="noticeDetailVisible" :close-on-click-modal="false">
      <el-form status-icon label-width="150px" :model="topFiveNotices[currentNotice]">
        <el-form-item label="通知标题:" class="label_text" style="text-align: left">
          <div class="text-input-div">{{ topFiveNotices[currentNotice].noticeTitle }}</div>
        </el-form-item>
        <el-form-item label="通知有效期限:" class="label_text" style="text-align: left">
          <div class="text-input-div">{{ topFiveNotices[currentNotice].validityStartTime }}至{{ topFiveNotices[currentNotice].validityEndTime }}</div>
        </el-form-item>
        <el-form-item label="通知内容:">
          <el-input style="margin-top: 12px" :disabled="true" type="textarea" :autosize="{ minRows: 4 }" v-model="topFiveNotices[currentNotice].noticeContent"></el-input>
        </el-form-item>
        <el-form-item label="相关链接:" class="label_text" style="text-align: left">
          <div class="text-input-div">{{ topFiveNotices[currentNotice].noticeLink }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="全部通知信息" :visible.sync="openAllNoticesVisible" :close-on-click-modal="false">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="当前通知" name="first">
          <el-table :data="result.tableData" element-loading-text="Loading" stripe fit highlight-current-row :show-header="false">
            <el-table-column prop="noticeTitle">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <span>{{ scope.row.noticeTitle }}</span>
                </div>
                <div>
                  {{ scope.row.createdDate }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <list-pagination :total="result.total" @change="handleCurrentChange" style="text-align: center" />
        </el-tab-pane>
        <el-tab-pane label="历史通知" name="second">
          <el-table :data="hisResult.tableData" element-loading-text="Loading" stripe fit highlight-current-row :show-header="false">
            <el-table-column prop="noticeTitle">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <span>{{ scope.row.noticeTitle }}</span>
                </div>
                <div>
                  {{ scope.row.createdDate }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <list-pagination :total="hisResult.total" @change="hisHandleCurrentChange" style="text-align: center" />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/api/request";
import moment from "moment";
import listPagination from "@/components/list/ListPagination.vue";

export default {
  components: { listPagination },
  name: "nologin-notice",
  data() {
    return {
      topFiveNotices: [{ noticeTitle: "" }],
      showDiaBox: false,
      currentNotice: 0,
      noticeDetailVisible: false,
      openAllNoticesVisible: false,
      activeName: "first",

      params: {
        pageNumber: 0,
        pageSize: 10,
        validityPeriodStartTime: "",
      },
      result: {
        tableData: [{ validityPeriod: "" }],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      hisParams: {
        pageNumber: 0,
        pageSize: 10,
        validityPeriodStartTime: "",
      },
      hisResult: {
        tableData: [{ validityPeriod: "" }],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
    };
  },
  methods: {
    //获取前五条不需登录可查看通知
    getPubTopFive: function() {
      axios({
		baseURL:this.$store.getters.config.adminBaseURL,
        url: "/pub/getPubTopFive",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.data) {
          this.topFiveNotices = res.data.noticeList;
          if (this.topFiveNotices && this.topFiveNotices.length > 0) {
            this.showDiaBox = true;
          }
        }
      });
    },
    //隐藏公告窗口
    closeDiv() {
      this.showDiaBox = false;
    },
    //当前通知的前一条通知
    preNotice() {
      if (this.currentNotice == 0) {
        this.currentNotice = this.topFiveNotices.length - 1;
      } else {
        this.currentNotice = this.currentNotice - 1;
      }
    },
    //当前通知的后一条通知
    nextNotice() {
      if (this.currentNotice == 4 || this.currentNotice == this.topFiveNotices.length - 1) {
        this.currentNotice = 0;
      } else {
        this.currentNotice = this.currentNotice + 1;
      }
    },
    //当前通知
    getPubAllNotices() {
      this.params.validityPeriodStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      axios({
		baseURL:this.$store.getters.config.adminBaseURL,
        url: "/pub/getPubAllNotices",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(this.params),
      }).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
      });
    },
    //历史通知
    getPubHisNotices() {
      this.hisParams.validityPeriodStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      axios({
        url: this.$store.getters.config.adminBaseURL + "/pub/getPubHisNotices",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(this.hisParams),
      }).then((response) => {
        this.hisResult.tableData = response.content;
        this.hisResult.total = response.totalElements;
        this.hisResult.pageNumber = response.number;
        this.hisResult.pageSize = response.size;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName == "second") {
        this.getPubHisNotices();
      }
    },
    handleCurrentChange: function(val) {
      this.params.pageNumber = val - 1;
      this.getPubAllNotices();
    },
    hisHandleCurrentChange: function(val) {
      this.hisParams.pageNumber = val - 1;
      this.getPubHisNotices();
    },
  },
  mounted: function() {},
  watch: {
    "$store.state.app.config.adminBaseURL": {
      deep: true,
      handler() {
        if(this.$store.getters.config.noticeTipsSwitch){
          this.getPubTopFive();
          this.getPubAllNotices();
        }
        
      },
    },
  },
};
</script>

<style scoped>
.notice {
  background-color: #ddd;
  float: right;
  margin-right: 80px;
  margin-top: -140px;
  height: 180px;
  width: 250px;
}
.allNotices {
  background-color: #fff;
  height: 30px;
  width: 250px;
  margin-top: 55px;
}
.contentStyle {
  margin-top: 75px;
  text-align: center;
}
</style>
