import axios from './request'

var svg = {}

const CONFIG_LEVEL = {
  DEV: 0,
  PRO: 1,
  DEPLOY: 2,
  USER: 3
}

const load_svg = async function (appId) {  
  const res = await axios({
    url: '/plugin/get_config',
    params: {
      name: 'icons',
      appId
    }
  })
  let all = res.sort((x, y) => CONFIG_LEVEL[y.level] - CONFIG_LEVEL[x.level])
  svg[appId] = all.reduce((p, c) => {
    return Object.assign(p, JSON.parse(c.value))
  }, {})
  return svg[appId]
}

const get_svg = async function(appId) {
  if (svg[appId] === undefined) {
    svg[appId] = load_svg(appId);
  }
  return svg[appId];
}

const save_svg = async function(appId, icons) {
  await axios({
    url: `/plugin/save_dev_config`,
    params: {
      name: 'icons',
      appId
    },
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data: JSON.stringify(icons)
  });
  await load_svg(appId);
}

export { load_svg, get_svg, save_svg }
