<template>
	<el-cascader
		:options="divisions"
		:props="divisionOptions"
		@change="change1"
		clearable
		style="width: 100%"
		ref="division"
		:value="code"
		:key="key2"
		:disabled="sdisabled"
	></el-cascader>
</template>

<script>
import { getDivision, getDivisionByCode } from "@/api/baseobject";

const getLevel = code => {
	if (!code || !code.length) {
		return -1;
	} else if (code.length >= 4 && code.substring(2, 4) === "00") {
		return 1;
	} else if (code.length >= 6 && code.substring(4, 6) === "00") {
		return 2;
	} else if (code.length == 6 && code.substring(4, 6) !== "00") {
		return 3;
	} else if (code.length >= 9 && code.substring(6, 9) === "000") {
		return 3;
	} else if (code.length == 9 && code.substring(6, 9) !== "000") {
		return 4;
	} else if (code.length == 12 && code.substring(9, 12) === "000") {
		return 4;
	} else if (code.length == 12 && code.substring(9, 12) !== "000") {
		return 5;
	} else if (code.length >= 15 && code.substring(12, 15) === "000") {
		return 5;
	} else if (code.length == 15 && code.substring(12, 15) !== "000") {
		return 6;
	} else {
		console.warn(`invalid division code ${code}`);
		return -2;
	}
};

export default {
	name: "division-selector",
	model: {
		prop: "code",
		event: "changeModel"
	},
	props: {
		code: {
			type: String,
			default: ""
		},
		code1: {
			type: String,
			default: ""
		},
		root: {
			type: String,
			default: ""
		},
		maxLevel: {
			type: Number,
			default: 5
		},
		disabled:{
			type:Boolean,
		}
	},
	data() {
		return {
			divisions: [],
			divisionOptions: {},
			key2: +new Date(),
			sdisabled:this.disabled,
		};
	},
	methods: {
		checkLeaf(list) {
			return (list || []).map(x => {
				let level = getLevel(x.value);
				let leaf = this.maxLevel && this.maxLevel <= level;
				return Object.assign(x, { leaf });
			});
		},
		lazyLoad(node, resolve) {
			let level = getLevel(node.value);
			if (this.maxLevel && this.maxLevel > level) {
				getDivision(node.value).then(response => {
					resolve(this.checkLeaf(response));
				});
			} else {
				resolve([]);
			}
		},
		getParents(code) {
			let parents = [];
			if (typeof code === "string") {
				if (code.length >= 2) {
					parents.push(code.substring(0, 2) + "0000");
				}
				if (code.length >= 4) {
					parents.push(code.substring(0, 4) + "00");
				}
				if (code.length >= 6) {
					parents.push(code.substring(0, 6));
				}
				if (code.length >= 9) {
					parents.push(code.substring(0, 9));
				}
				if (code.length >= 12) {
					parents.push(code.substring(0, 12));
				}
				if (code.length >= 15) {
					parents.push(code.substring(0, 15));
				}
			}
			return Array.from(new Set(parents));
		},
		change1(code) {
			console.log('change call', code);
			let d2, D2, d4, D4, d6, D6, d9, D9, d12, D12, d15, D15;
			if (code != null) {
				this.$refs.division.toggleDropDownVisible();

				if (!code || !code.length) {
					r = { code, d2, d4, d6, d9, d12, d15, D2, D4, D6, D9, D12, D15 };
				} else {
					// 省
					d2 = code.length >= 2 && code.substring(0, 2);
					D2 = code.length >= 2 && code.substring(0, 2) + "0000";
					// 市
					d4 = code.length >= 4 && code.substring(2, 4);
					D4 = code.length >= 4 && code.substring(0, 4) + "00";
					// 区
					d6 = code.length >= 6 && code.substring(4, 6);
					D6 = code.length >= 6 && code.substring(0, 6);
					// 镇街
					d9 = code.length >= 9 && code.substring(6, 9);
					D9 = code.length >= 9 && code.substring(0, 9) + "000";
					// 材居
					d12 = code.length >= 12 && code.substring(9, 12);
					D12 = code.length >= 12 && code.substring(0, 12);
					// 网格
					d15 = code.length >= 15 && code.substring(12, 15);
					D15 = code.length >= 15 && code.substring(0, 15);
				}
			}
			let r = { code, d2, d4, d6, d9, d12, d15, D2, D4, D6, D9, D12, D15 };			
			this.$emit("changeModel", r.code);
			this.$emit("change", r);
		},
		async loadOptionsByValue() {
			if (this.code) {
				let parents = this.getParents(this.code);
				let options = this.divisions;
				let match = options.find(x => parents.some(y => y === x.value));
				while (match) {
					if (!match.children || !match.children.length) {
						match.children = await getDivision(match.value);
					} 
					match = match.children.find(x => parents.some(y => y === x.value));
				}
				this.key2 = +new Date();
			}
		}
	},
	async mounted() {
		if (this.root) {
			let r = await getDivisionByCode(this.root);
			this.divisions = [r];
		} else {
			//获取当前用户行政区域
			getDivisionByCode(this.$store.getters.userdata.userAccount.divisionCode).then(response => {
					this.divisions = [{ name: response.name, value: response.value }];
				});
			
		}
		this.divisionOptions = {
			checkStrictly: true,
			value: "value",
			label: "name",
			lazy: true,
			expandTrigger: "hover",
			emitPath: false,
			lazyLoad: this.lazyLoad
		};

		this.loadOptionsByValue();
	},
	watch: {
		code() {
			this.loadOptionsByValue();
		}
	}
};
</script>

<style>
</style>