<template>
  <el-main style="background: #fff;">
    <el-form :disabled="true" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称：" prop="app.appName">
            <el-input v-model="appForm.app.appName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应用标识：" prop="app.appId">
            <el-input v-model="appForm.app.appId"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="状态：" prop="app.enabled">
            <enabledSelector v-model="appForm.app.enabled"></enabledSelector>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="备注：" prop="dataElement.description">
            <el-input v-model="appForm.app.description" type="textarea" :rows="2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-main>
</template>

<script>
import { getAppById } from "@/api/appModule.js";
import enabledSelector from "../components/enabledSelector";
export default {
  components: {
    enabledSelector,
  },
  props: {
    pdataId: {},
  },
  data() {
    return {
      dataId: this.pdataId,
      titleName: "应用详情信息",
      appForm: { app: {} },
    };
  },
  methods: {
    backList() {
      this.$router.push({ name: "sys_admin_app" });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_app_edit",
        params: { id: param },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_app_del",
        params: { id: param },
      });
    },
  },
  created() {
    getAppById(this.dataId).then((response) => {
      this.appForm.app = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {
    pappId(newVal) {
      this.dataId = newVal;
    },
  },
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>