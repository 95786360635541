import store from '../store/index';
import Vue from 'vue'
const {
    cachedRouteNames
} = store.state.cache;

// 定义添加缓存函数
const addRoutes = (route) => {
    const routeName = route.name;
    // 判断缓存是否已经存在路由name，若存在就无需再添加
    if (!cachedRouteNames.includes(routeName)) {
        store.commit('cache/update_cachedroutenames', {
            action: 'add',
            route: routeName
        });
    }
};
// 定义删除缓存函数
const deleteRoutes = (route) => {
    const routeName = route.name;
    // console.log(4, cachedRouteNames, routeName)
    // 判断缓存是否存在路由name，若存在就删除
    if (cachedRouteNames.includes(routeName)) {
        store.commit('cache/update_cachedroutenames', {
            action: 'delete',
            route: routeName
        });
    }
};

// 配置路由keepAlive状态
const setRouterBeforeEach = (router) => {
    // 浏览器返回事件
    window.onpopstate=function(){
        router.currentRoute.params.isCache=true;
    };
    router.beforeEach((to, from, next) => {
        // console.log(to, from);
        setTimeout(() => {
            const isCache = from.meta.isCache||to.params.isCache||from.params.isCache;
            console.log('to',from.meta.isCache,to.params.isCache,from.params.isCache);
            //进入该路由，判断当前路由keepAlive和isCache缓存条件是否成立
            if (to.meta.keepAlive && isCache) {
                addRoutes(to);
            } else {
                deleteRoutes(to);
            }
            // console.log('beforeEach', cachedRouteNames.length);
            next();
        }, 0);
    });
    // 全局混入。在浏览器第一次进来该路由,判断路由的keepAlive是否需要被缓存，若为true则将路由添加到缓存配置中，进行缓存。
    Vue.mixin({
        beforeRouteEnter(to, from, next) {
            next(() => {
                if (to.meta.keepAlive) {
                    addRoutes(to);
                }
                console.log('beforeRouteEnter', cachedRouteNames);
            });
        }
    });
};
export default {
    setRouterBeforeEach
};
