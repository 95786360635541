import request from '@/api/request'

/*********************后端模块应用*****************************/
//后端模块应用查询列表接口
export function getBackendModuleList(params) {
  return request({
    url: '/backendModule/list',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//查询全部有效的后端模块
export function getBackendModuleAllList(params) {
  return request({
    url: '/backendModule/listAll',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询后端模块应用接口
export function getBackendModuleById(params) {
  return request({
    url: '/backendModule/getById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询后端模块发布包接口
export function getModuleFileById(params) {
  return request({
    url: '/backendModule/getModuleFileById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//新增后端模块应用
export function saveBackendModule(params) {
  return request({
    url: '/backendModule/add',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//修改后端模块应用
export function editBackendModule(params) {
  return request({
    url: '/backendModule/edit',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除后端模块应用
export function delBackendModuleById(params) {
  return request({
    url: '/backendModule/deleteById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除后端模块发布包
export function deleteModuleTempFile(params) {
  return request({
    url: '/backendModule/deleteModuleTempFile',
    method: 'get',
    params: { fileName: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//获取所有后端模块代码
export function getModuleCodes() {
  return request({
    url: '/backendModule/getModuleCodes',
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  })
}
//复制后端模块应用
export function copyBackendModule(params) {
  return request({
    url: '/backendModule/copy',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

/*********************后端模块配置*****************************/
//后端模块配置查询列表接口
export function getBackendConfigList(params) {
  return request({
    url: '/backendConfig/list',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询后端模块配置接口
export function getBackendConfigById(params) {
  return request({
    url: '/backendConfig/getById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//新增后端模块配置
export function saveBackendConfig(params) {
  return request({
    url: '/backendConfig/add',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//修改后端模块配置
export function editBackendConfig(params) {
  return request({
    url: '/backendConfig/edit',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除后端模块配置
export function delBackendConfigById(params) {
  return request({
    url: '/backendConfig/deleteById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}


/*********************实施任务*****************************/
//实施任务查询列表接口
export function getImplementTaskList(params) {
  return request({
    url: '/implementTask/list',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询实施任务接口
export function getImplementTaskById(params) {
  return request({
    url: '/implementTask/getById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//新增实施任务
export function saveImplementTask(params) {
  return request({
    url: '/implementTask/add',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//修改实施任务
export function editImplementTask(params) {
  return request({
    url: '/implementTask/edit',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除实施任务
export function delImplementTaskById(params) {
  return request({
    url: '/implementTask/deleteById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//部署后端模块
export function deployModuleFile(params) {
  return request({
    url: '/implementTask/deployModuleFile',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//启动后端模块服务
export function startModuleService(params) {
  return request({
    url: '/implementTask/startModuleService',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//停止后端模块服务
export function stopModuleService(params) {
  return request({
    url: '/implementTask/stopModuleService',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//重启后端模块服务
export function restartModuleService(params) {
  return request({
    url: '/implementTask/restartModuleService',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}


//获取服务器的部署目录文件清单
export function getServerDeployFileList(params) {
  return request({
    url: '/implementTask/getServerDeployFileList',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//读取后端模块服务运行日志
export function readRunLog(params) {
  return request({
    url: '/implementTask/readRunLog',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//检测后端模块服务运行状态
export function checkTaskRunStatus(params) {
  return request({
    url: '/implementTask/checkTaskRunStatus',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

