import {
	getDivisionByCodeFromStore
} from '@/api/baseobject'
import config from '@/utils/config.js'
import Cookies from 'js-cookie'
import {load_configs} from "@/api/moduleLoader";
import systemManageModule from "@/module.js";
import {
	getCurrentLoginUser,
	getCasCurrentLoginUser,
	getRefreshToken,
	thirdLogin,
	userLogout,
	getLoginValidity,
	getInfoByAccountId
} from "@/api/auth";
import { getZwwxloginCallbackUrl } from "@/utils/gdZwwxLoginUtil.js";

export async function getCurrentUser(store) {
	let userRes = await getCurrentLoginUser();
	//用户部分信息获取
	let sensitiveInfo = await getInfoByAccountId(userRes.userAccount.id)
	userRes.userAccount.account = sensitiveInfo.data.sensitiveInfoDTO.account
	userRes.userInfo.mobile = sensitiveInfo.data.sensitiveInfoDTO.mobile
	userRes.userInfo.phone = sensitiveInfo.data.sensitiveInfoDTO.phone
	userRes.userInfo.email = sensitiveInfo.data.sensitiveInfoDTO.email
	userRes.userInfo.identityNumber = sensitiveInfo.data.sensitiveInfoDTO.identityNumber
	//更新行政区划名称
	if(userRes.userAccount.divisionCode){
		await getDivisionByCodeFromStore(userRes.userAccount.divisionCode).then((division) => {
			userRes.userAccount.divisionName = division && division.name
			store.dispatch('set_userdata', userRes)
			store.dispatch('set_userdata_version')
		})
	}
	
	excludeGlobalLogout(store);//将当前用户从已退出的账号清单中排除
}

export async function reset_token(store, token, vm) {
	if (token.length) {
		store.dispatch('set_token', token)
		//获取用户信息前先设置获取用户信息的URL基路径
		await load_configs(vm,systemManageModule,null);
		let check_token = await refresh_token(store, vm,false)
		if (check_token) {
			try{
				await getCurrentUser(store);
			}catch{
				return false
			}			
			return true
		}else{
			console.error("reset_token check_token fail，重置token失败，token无效");
			store.dispatch('set_token', '')
		}
	}
	return false;
}

export async function reuse_token(store, vm) {
	checkIfGlobalLogout(store);
	let temp_token = null
	if(window.APP_CONFIG.IS_GET_COOKIE=='true'){
		temp_token = Cookies.get('TEMP_TOKEN')
	}else{
		temp_token = localStorage.getItem('TEMP_TOKEN')
	}
	//let temp_token = localStorage.getItem('TEMP_TOKEN')
	if (temp_token) {
		let token_obj = {}
		try {
			token_obj = JSON.parse(temp_token)
		} catch {
			token_obj = null
		}
		if (token_obj && token_obj.token && token_obj.token.length > 0) {
			let reset_result = await reset_token(store, token_obj.token, vm);
			if(!reset_result){//如果TEMP_TOKEN是无效的，则要清除
				if(window.APP_CONFIG.IS_GET_COOKIE=='true'){
					Cookies.remove('TEMP_TOKEN');
				}else{
					localStorage.removeItem('TEMP_TOKEN')
				}
			}
			return reset_result;
		}
	} else {
		console.log('no temp token')
	}
	return false
}

//检查是否已全局退出，如果应退出未退出则强制退出
export async function checkIfGlobalLogout(store) {
	if (store.getters.has_token){
		let t_account = store.state.user && store.state.user.userdata && store.state.user.userdata.userAccount && store.state.user.userdata.userAccount.account
		let cache_logout_accounts = localStorage.getItem('logout_accounts');
		if(cache_logout_accounts){
			let t_logout_account = cache_logout_accounts.split(',');
			if(t_logout_account.some(p_account => p_account == t_account)){//已单点退出，则本地也要退出
				cleanLoginData(store);
			}
		}
	}
}

//将当前用户从已退出的账号清单中排除
export async function excludeGlobalLogout(store) {
	if (store.getters.has_token){
		let t_account = store.state.user && store.state.user.userdata && store.state.user.userdata.userAccount && store.state.user.userdata.userAccount.account
		let cache_logout_accounts = localStorage.getItem('logout_accounts');
		if(cache_logout_accounts){
			let t_logout_account = cache_logout_accounts.split(',');
			var had_logout = t_logout_account.filter(p_account => p_account != t_account);//排除已登录的账号
			localStorage.setItem('logout_accounts',had_logout);//将已退出的账号清单加入缓存
		}
	}
}

//checkLogout:校验是否已全局
//从请求参数传入的token，属于新登录，checkLogout要设置为false
export async function refresh_token(store, vm,checkLogout) {
	
	if(checkLogout){
		checkIfGlobalLogout(store);
	}

	if (store.getters.has_token) {
		try {
			let old_token = store.state.user.token;
			let res = await getRefreshToken();
			if (res.meta && res.meta.success) {
				let token = res.meta.token;
				store.dispatch('set_token', token);
				if(window.APP_CONFIG.IS_GET_COOKIE == 'true'){
					let cookie_temp_token = Cookies.get('TEMP_TOKEN');
					let cookie_token = null;
					if(cookie_temp_token){
						try{
							let cookie_token_obj = JSON.parse(cookie_temp_token);
							if (cookie_token_obj && cookie_token_obj.token && cookie_token_obj.token.length > 0) {
								cookie_token = cookie_token_obj.token;
							}
						}catch{
							console.log("parse cookies TEMP_TOKEN error !!!");
						}
					}
					if(old_token == cookie_token || !cookie_token){//cookies存储的token与store刷新前token一致才更新cookies的token，避免多用户登录token错乱的问题
						Cookies.set('TEMP_TOKEN', JSON.stringify({ tabId: '', token }));
						console.log("refresh_token set TEMP_TOKEN : " + token);
					}
				}
				
				return true
			}
		} catch (err) {
			if (err && err.response.status && err.response.status == 401) {
				toLogin(store, vm.$router)
			}
		}
	}
	return false
}

export async function check_token(store, vm) {
	if (!store.getters.has_token) {
		// 尝试从localStorage读取
		let hasToken = await reuse_token(store, vm)

		if (!hasToken && window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === 'true') {
			//政务微信登录 
			if (window.APP_CONFIG.VUE_APP_THIRD_AUTH_HOST == window.location
				.host) { //如果当前是使用政务微信的域名，则直接使用后端登录判断是否已登录
				let res = await thirdLogin('zwwx');
				if (res.meta && res.meta.success) {
					store.dispatch('set_token', res.meta.token)
					await getCurrentUser(store);
					hasToken = true;
				} else {
					hasToken = false;
				}
			}
		}
		if (!hasToken) {
			toLogin(store, vm.$router, undefined, vm.$router.currentRoute.query.loginType)
		}
	}
}


//thirdLogoutEnabled表示是否启动退出第三方登录状态
export function toLogin(store, router,thirdLogoutEnabled, loginType) {
	store.dispatch('set_token', '')
	store.dispatch('set_userdata', '')
	
	if (window.APP_CONFIG.VUE_APP_CAS_LOGIN_ENABLED === 'true') {
		casLogin(store)
	//}else if ( window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === 'true' && window.APP_CONFIG.VUE_APP_THIRD_AUTH_DEFAULT === 'true' && store.getters.tokenSource == 'zwwx') { //跳转到政务微信认证服务
	}else if ( window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === 'true' && window.APP_CONFIG.VUE_APP_THIRD_AUTH_DEFAULT === 'true' && loginType == 'zwwx') { //跳转到政务微信认证服务
		//window.location.href = window.APP_CONFIG.VUE_APP_ZWWX_AUTH_CALL_BACK_URL;
		window.location.href = getZwwxloginCallbackUrl();
	}else if ( window.APP_CONFIG.VUE_APP_WELINK_AUTH_ENABLED === 'true' && window.APP_CONFIG.VUE_APP_THIRD_AUTH_DEFAULT === 'true' && store.getters.tokenSource == 'weLink') { //跳转到weLink认证服务
		window.location.href = window.APP_CONFIG.VUE_APP_WELINK_AUTH_CALL_BACK_URL;
	}else {
		let appId = store.getters.appId
		appId == null ?
			router.push({
				name: 'login',
				params: {
					thirdLogoutEnabled:thirdLogoutEnabled,
				}
			}) :
			router.push({
				name: 'dynamicLogin',
				params: {
					appId: appId,
					thirdLogoutEnabled:thirdLogoutEnabled,
				}
			})
	}
}

function getCasTicket() {
	let url = window.location.href
	let t_url = url.split('#')[0] //获取#/之前的字符串
	let t_cs = t_url.split('?')[1]
	if (t_cs) {
		let t_cs_arr = t_cs.split('&')
		let csMap = {}
		for (var i = 0; i < t_cs_arr.length; i++) {
			csMap[t_cs_arr[i].split('=')[0]] = t_cs_arr[i].split('=')[1]
		}
		return csMap.ticket
	}
}

function hasCasTicket() {
	let ticket = getCasTicket()
	if (ticket) {
		return typeof ticket === 'string' && ticket.length > 0
	} else {
		return false;
	}

}

function casLogin(store) {
	if (hasCasTicket()) {
		getCurrentUserForCas(getCasTicket(), sessionStorage.getItem('clientServiceUrl'), store)
	} else {
		sessionStorage.setItem('clientServiceUrl', window.location.href)
		window.location.href = window.APP_CONFIG.VUE_APP_CAS_URL + '/login?service=' + encodeURIComponent(window
			.location.href)
	}
}

function getCurrentUserForCas(casTicket, clientServiceUrl, store) {
	getCasCurrentLoginUser(casTicket, clientServiceUrl).then((res) => {
		getDivisionByCodeFromStore(res.userAccount.divisionCode).then((division) => {
			res.userAccount.divisionName = division.name
			store.dispatch('set_userdata', res)
			store.dispatch('set_userdata_version')
			excludeGlobalLogout(store);
		})
		let that = this
		if (store.getters.modules) {
			store.getters.modules.forEach((module) => {
				if (typeof module.initModuleGlobalData === 'function') {
					module.initModuleGlobalData(that)
				}
			})
		}
	})
}

export function cleanLoginData(store) {
	let t_account = store.state.user && store.state.user.userdata && store.state.user.userdata.userAccount && store.state.user.userdata.userAccount.account
	store.dispatch('set_token', '')
	store.dispatch('set_userdata', '')
	localStorage.removeItem('TEMP_TOKEN')
	Cookies.remove('TEMP_TOKEN');
	console.log("cleanLoginData is remove token and TEMP_TOKEN");
	//将已退出的账号放到缓存中，为了解决全局退出，跨tab退出系统
	let cache_logout_accounts = localStorage.getItem('logout_accounts');
	if(cache_logout_accounts){
		let t_logout_account = cache_logout_accounts.split(',');
		if(!t_logout_account.some(p_account => p_account == t_account)){
			t_logout_account.push(t_account);
			localStorage.setItem('logout_accounts',t_logout_account.join(','));
		}
	}else{
		localStorage.setItem('logout_accounts',t_account);
	}
	clearAllCookie()
}

export function toLogout(store, vm,thirdLogoutEnabled) {
	userLogout()
		.then(() => {
			cleanLoginData(store);
			if (window.APP_CONFIG.VUE_APP_CAS_LOGIN_ENABLED === 'true') {
				toCasLogout();
			} else if(config.VUE_APP_CUSTOM_LOGOUT_URL){
				toCustomLogout();
			} else {
				toLogin(store, vm.$router,thirdLogoutEnabled);
			}
		})
		.catch(() => {
			cleanLoginData(store);
			if (window.APP_CONFIG.VUE_APP_CAS_LOGIN_ENABLED === 'true') {
				toCasLogout();
			} else if(config.VUE_APP_CUSTOM_LOGOUT_URL){
				toCustomLogout();
			} else {
				toLogin(store, vm.$router);
			}
		});
}

//跳转到自定义退出页
function toCustomLogout(){
	if(!config.VUE_APP_CUSTOM_LOGOUT_URL) return
	window.location.href = config.VUE_APP_CUSTOM_LOGOUT_URL
}

//跳转到cas退出页
function toCasLogout() {
	let clientServiceUrl = window.APP_CONFIG.VUE_APP_CAS_URL+"/login";
	if(window.APP_CONFIG.VUE_APP_THIRD_AUTH_SUCCESS_HOME_URL){
		clientServiceUrl = window.APP_CONFIG.VUE_APP_THIRD_AUTH_SUCCESS_HOME_URL;
	}
	window.location.href = window.APP_CONFIG.VUE_APP_CAS_URL + "/logout?service=" + encodeURIComponent(
	clientServiceUrl);
}

function clearAllCookie() { //清除所有cookie
	var keys = document.cookie.match(/[^ =;]+(?==)/g)
	if (keys) {
		for (let i = 0; i < keys.length; i++) {
			document.cookie = keys[i] + '=0;expires=0'
		}
	}
}

export function checkLoginTimeout(store, vm) {
	//用户无操作的超时时间
	let loginTimeOut = null;
	if (store.state.app.config.loginTimeOut) {
		loginTimeOut = store.state.app.config.loginTimeOut;
	}
	if (loginTimeOut) {
		//更新当前时间
		let currentTime = new Date().getTime();
		let lastActiveTime = localStorage.getItem("lastActiveTime");
		//判断是否超时
		if(lastActiveTime){
			if (currentTime - lastActiveTime > loginTimeOut) {
				store.dispatch('set_token', '')
				store.dispatch('set_userdata', '')
				localStorage.removeItem("lastActiveTime")	
				if (vm.$route.meta.roles || vm.$route.meta.rolePermissions) {
					vm.$router.push({
						name: 'logout',
					});
				} else if(window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED&&window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === 'true'){
					//判断开启了第三方登录，请求第三方退出登录请求lgs20220302
					requestThirdLoginOutUrl();
				}
			}
		}
		
	}

}

//第一次打开系统时处理认证相关信息
export async function processAuthOnAppInit(vm,p_store,p_route) {
	// 20210106, lbj
	// 接收从url直接带token
	if (p_route.query.jwt) {
		p_store.dispatch('set_tokenSource', "external")
		await reset_token(p_store, p_route.query.jwt,vm)
	}else if(p_route.query.jtoken){
		p_store.dispatch('set_tokenSource', p_route.query.authType)
		await reset_token(p_store, p_route.query.jtoken,vm)
	}else{
		await reuse_token(p_store,vm);
	}

}

//第一次模块完以后检查登录状态
export async function checkAuthOnAppInited(vm,p_store,p_route) {
	//第一次打开系统未登录时跳转到登录页前记录当前路由地址，登录后跳转到第一次打开系统的地址
	if(p_route && !p_store.getters.has_token && p_route.name != "login" && p_route.name != "dynamicLogin" ){
		sessionStorage.setItem("loginAfterRedirect", JSON.stringify({
			path: p_route.path,
			query: p_route.query
		}));
		//放入localStorage是为了解决从第三方回跳时sessionStorage被清空，然后从localStorage找回来
		localStorage.setItem("loginAfterRedirect", JSON.stringify({
			path: p_route.path,
			query: p_route.query
		}));
	}else if(p_route.name == "login" || p_route.name == "dynamicLogin"){
		sessionStorage.removeItem("loginAfterRedirect");
		localStorage.removeItem("loginAfterRedirect");
	}
	if (await isNeedCheckToken(p_store,p_route)) {
		await check_token(p_store, vm)
	}
	//为了刷新能执行initModuleGlobalData，获取登录用户数据
	if(p_store.getters.has_token){
		await getCurrentUser(p_store)
	}
}

//第一次打开系统当前路由是否需要登录
function isNeedCheckToken(p_store,p_route){
	let currentAppid = p_store.getters.appId
	let currentRouteName = p_route.name
	let currentRoutePath = p_route.path
	let neetCheck = true
	
	
	let appIds = window.APP_CONFIG.VUE_APP_IDS;
	let routeNames = window.APP_CONFIG.VUE_ROUTE_NAMES;
	let routePaths = window.APP_CONFIG.VUE_ROUTE_PATHS;

	//【0.1.16后已废弃】如果设置了VUE_APP_DONOT_JUMP_LOGIN参数为true，即使没有登录也不会跳转到登录页
	let donotJumpLogin = window.APP_CONFIG.VUE_APP_DONOT_JUMP_LOGIN === 'true';
	//配置的routeName存在与当前routeName相同的，则不需要check_token
	if(routeNames){
		routeNames.split(',').forEach(routeName =>{
			if(routeName==currentRouteName){
				neetCheck = false
			}
		})
	}
	
	//【0.1.16后已废弃】配置的routePath存在与当前routePath相同的，则不需要check_token
	if(routePaths){
		routePaths.split(',').forEach(routePath =>{
			if(routePath==currentRoutePath){
				neetCheck = false
			}
		})
	}
	//【0.1.16后已废弃】
	if(neetCheck && appIds){
		//配置的appid存在与当前appid相同的，则不需要check_token
		appIds.split(',').forEach(appid => {
			if(appid==currentAppid){
				neetCheck = false
			}
		});
	}
	//【0.1.16后已废弃】配置的donotJumpLogin为true，则不需要check_token		
	if(neetCheck && donotJumpLogin){
		neetCheck = false
	}
	//0.1.16后已这个为准，通过路由配置来判断是否需要登录
	if(neetCheck){
		if(p_route.meta.roles || p_route.meta.rolePermissions){
			neetCheck = true;
		}else{
			neetCheck = false;
		}
	}
	
	return neetCheck;

}

//请求第三方系统清掉第三方登录信息
function requestThirdLoginOutUrl(){
	var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", window.APP_CONFIG.VUE_APP_ZWWX_AUTH_LOGOUT_URL);
	document.body.appendChild(ifrm);
}

export function checkLoginValidity(store, vm) {
	let check_time_rate = store.state.app.config.check_time_rate
	//若配置了用户单客户端登录限制机制配置项(若配置的是空字符串，则默认值为30秒)，则执行定时器方法
	if(check_time_rate||check_time_rate==''){
		setInterval(() => {
			//若当前会话有token，定时检测用户会话有效性
			if(store.getters.has_token){
				getLoginValidity(store.state.user.token).then(
					(response) => {
						//当前用户会话无效
						if (response&&response.meta.code==200&&response.data.loginValidity==false) {
							//跳转到登录页
							toLogin(store,vm.$router)		
						}
					})
			}								
		},check_time_rate==''?30000:check_time_rate-0);
	}		
	return false;
}