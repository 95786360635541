import Vue from 'vue';
import echarts from 'echarts';
import moment from 'moment';
import handsontable from 'handsontable';
import jquery from 'jquery';
import vuex from 'vuex';
import axios from 'axios';
import xlsx from 'xlsx';

//平台管理的cdn引入
export default {
  'Vue': Vue,//"^2.6.11"
  'echarts': echarts,//"^4.7.0"
  'moment': moment,//"^2.27.0"
  'handsontable': handsontable,// "6.2.2"
  'jquery': jquery,//^3.4.1
  'vuex': vuex,//"^3.1.2"
  'axios': axios,//0.19.2"
  'xlsx': xlsx,//"^0.15.5"
};