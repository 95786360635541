<template>
  <el-select v-model="svalue" @change="sChange" :clearable="true" :disabled="sdisabled" placeholder="请选择">
    <el-option
      v-for="item in enabled_options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "backendModuleEnabled",
  props: {
    value: {
    },
    disabled:{
      type:Boolean,
    }
  },
  data() {
    return {
      svalue: this.value,
      sdisabled: this.disabled,
      enabled_options: [
        {
          value: true,
          label: "在用"
        },
        {
          value: false,
          label: "备案"
        }
      ]
    };
  },
  methods: {
    sChange() {
      this.$emit("enabledChange");
    }
  },
  watch: {
    value(newVal) {
      this.svalue=newVal;
    },
    disabled(newVal) {
      this.sdisabled=newVal;
    },
    svalue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>