<template>
  <div>
    <el-dialog
      id="shareLinkDialog"
      title="将当前链接生成短链接并分享"
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="shareDialogVisible"
      @close="closeShareDialog"
    >
      <el-main style="background: #fff">
        <el-form ref="shareLinkForm" :model="shareLinkForm" status-icon :rules="rules" label-width="120px">
          <el-row>
            <el-col>
              <el-form-item label="当前链接：" prop="longLink">
                <el-input v-model="shareLinkForm.longLink"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="是否自动登录：" prop="useCurrentAccount">
                <el-radio-group v-model="shareLinkForm.useCurrentAccount">
                  <el-radio :label="true">短链接使用当前账号自动登录</el-radio>
                  <el-radio :label="false">短链接不使用当前账号登录</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="shareLinkForm.useCurrentAccount">
            <el-col :span="12">
              <el-form-item label="生效时间：" prop="startTime">
                <el-date-picker v-model="shareLinkForm.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="失效时间：" prop="endTime">
                <el-date-picker v-model="shareLinkForm.endTime" type="datetime" default-value="2021-03-18 12:00:00" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item align="center">
            <el-button type="primary" icon="el-icon-bottom" @click="buildShortLink">生成短链接</el-button>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item label="短链接：" prop="shortLink">
                <el-input v-model="shareLinkForm.shortLink"
                  ><el-button style="padding-right:10px" slot="suffix" type="text" v-clipboard:copy="shareLinkForm.shortLink">复制</el-button></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeShareDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createShortLink } from "@/api/shortLink.js";
import moment from "moment";

export default {
  name: "shareLinkDailog",
  props: {
    value: {},
    currentLink: {
      type: String,
    },
    dialogVisible: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      shareDialogVisible: this.dialogVisible,
      shareLinkForm: { longLink: this.currentLink },
      rules: {
        longLink: [
          { required: true, message: "请输入当前链接", trigger: "blur" },
          { max: 2000, message: "请输入2000位以内字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  methods: {
    buildShortLink() {
      this.$refs["shareLinkForm"].validate((valid) => {
        if (valid) {
          createShortLink(JSON.stringify(this.shareLinkForm)).then(
            (response) => {
              this.$set(this.shareLinkForm, "shortLink", response.shortLink);
            },
            (err) => {
              this.$confirm(err.response.data.data, "生成短链接异常", {});
            }
          );
        } else {
          return false;
        }
      });
    },
    closeShareDialog() {
      this.shareDialogVisible = false;
      this.shareLinkForm = {};
    },
  },
  mounted() {
    this.shareLinkForm.longLink = window.location.href;
  },
  watch: {
    "shareLinkForm.useCurrentAccount"(newVal) {
      if (newVal) {
        let currentTime = new Date();
        this.$set(this.shareLinkForm, "startTime", moment(currentTime).format("YYYY-MM-DD HH:mm:ss"));
        this.$set(this.shareLinkForm, "endTime", moment(currentTime.setDate(currentTime.getDate() + 1)).format("YYYY-MM-DD HH:mm:ss"));
      }else{
        this.shareLinkForm.startTime = null;
        this.shareLinkForm.endTime = null;
      }
    },
    currentLink(newVal) {
      this.shareLinkForm.longLink = newVal;
    },
    dialogVisible(newVal) {
      this.shareDialogVisible = newVal;
    },
    shareDialogVisible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("visibleChange", newVal);
      }
    },
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
<style>
#shareLinkDialog .el-dialog .el-dialog__body {
  padding: 0px;
}
</style>
