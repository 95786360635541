<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="backendModuleForm"
          :model="backendModuleForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：" prop="backendModule.moduleCode">
                <el-input v-model="backendModuleForm.backendModule.moduleCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：" prop="backendModule.moduleName">
                <el-input v-model="backendModuleForm.backendModule.moduleName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：" prop="backendModule.version">
                <el-input v-model="backendModuleForm.backendModule.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：" prop="backendModule.versionID">
                <el-input v-model="backendModuleForm.backendModule.versionID" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块类型：" prop="backendModule.type">
                <typeSelector v-model="backendModuleForm.backendModule.type"></typeSelector>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否在用：" prop="backendModule.enabled">
                <enabledSelector v-model="backendModuleForm.backendModule.enabled"></enabledSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：" prop="backendModule.description">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendModuleForm.backendModule.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col>
              <el-form-item label="原应用包：">
                <el-button type="text" @click="downloadModuleFile">{{moduleFile.fileName}}</el-button>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col>
              <el-form-item label="上传新应用包：">
                <el-upload
                  :limit="1"
                  :action="uploadModuleJarFileUrl"
                  :headers="headers"
                  :multiple="false"
                  :before-upload="beforeUpload"
                  :auto-upload="true"
                  :show-file-list="true"
                  :on-exceed="handleExceed"
                  :on-success="onUploadSuccess"
                  :on-error="onError"
                  :on-remove="removeFile"
                  accept=".jar"
                  ref="uploadModuleFile"
                >
                  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                  <div slot="tip" class="el-upload_tip">只能上传jar文件，且不超过10MB</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import axios from "@/api/request";
import {
  getBackendModuleById,
  copyBackendModule,
  deleteModuleTempFile,
  getModuleFileById,
} from "@/api/backend.js";
import typeSelector from "../components/typeSelector";
import enabledSelector from "../components/enabledSelector";
import config from "@/config";
export default {
  components: { typeSelector, enabledSelector },
  data() {
    return {
      dataId: "",
      moduleTab: "",
      titleName: "复制后端模块配置",
      fileList: [], //预览列表
      uploadModuleJarFileUrl:
        config.VUE_APP_API_URL + "/backendModule/uploadModuleFile",
      backendModuleForm: {
        backendModule: {},
        moduleFileName: "",
        moduleTempFileName: "",
      },
      moduleFile: {},
      rules: {
        "backendModule.moduleCode": [
          { required: true, message: "请输入模块代码", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-20位字符", trigger: "blur" },
        ],
        "backendModule.moduleName": [
          { required: true, message: "请输入模块名称", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "请输入2-500位字符",
            trigger: "blur",
          },
        ],
        "backendModule.version": [
          { required: true, message: "请输入配置版本号", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
        "backendModule.versionID": [
          { required: true, message: "请输入配置版本标识", trigger: "blur" },
        ],
        "backendModule.type": [
          { required: true, message: "请选择模块类型", trigger: "blur" },
        ],
        "backendModule.enabled": [
          { required: true, message: "请选择是否在用", trigger: "blur" },
        ],
        "backendModule.description": [
          { max: 4000, message: "请输入4000位以内字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    headers() {
      let token = this.$store.getters.token;
      return { "JWT-AUTHORIZATION": token };
    },
  },
  created() {
    this.dataId = this.$route.params.id;
    this.moduleTab = this.$route.params.moduleTab;
    getBackendModuleById(this.dataId).then((response) => {
      this.backendModuleForm.backendModule = response;
      this.backendModuleForm.backendModule.versionID = "" + Date.now();
    });
    getModuleFileById(this.dataId).then((response) => {
      this.moduleFile = response;
    });
  },
  methods: {
    commit() {     
      this.$refs["backendModuleForm"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "内容较大，正在提交，请稍等......",
            spinner: "el-icon-loading",
          });
          copyBackendModule(JSON.stringify(this.backendModuleForm)).then(
            (response) => {
              loading.close();
              this.toInfo(response.id);
            },
            (err) => {
              loading.close();
              if (err.response.status == 500) {
                this.$confirm(
                  err.response.data.data,
                  "复制后端模块配置异常",
                  {}
                );
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: param, moduleTab: this.moduleTab },
      });
    },
    backList() {
      this.$router.push({
        name: "sys_admin_backend_module",
        params: { moduleTab: this.moduleTab },
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jar";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension) {
        this.$message({
          message: "上传文件只能是jar格式!",
          type: "warning",
        });
      }
      if (!isLt10M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      if (!extension || !isLt10M) {
        return false;
      } 
      this.backendModuleForm.moduleFileName = file.name;
      let fileNamePart = file.name.substring(0, file.name.indexOf("."));
      let moduleCode = fileNamePart.substring(0, fileNamePart.lastIndexOf("-"));
      let version = file.name.substring(
        fileNamePart.lastIndexOf("-") + 1,
        file.name.lastIndexOf(".")
      );
      this.backendModuleForm.backendModule.moduleCode = moduleCode;
      this.backendModuleForm.backendModule.version = version;
    },
    onUploadSuccess(res) {
      if (res.meta.code == "1000") {
        this.backendModuleForm.moduleTempFileName = res.data.tempFileName;
      } else {
        this.$alert(
          `请确保上传文件是jar并且小于10M，如无法解决，请联系管理员！`,
          "上传文件失败",
          {
            type: "warning",
          }
        );
      }
    },
    onError() {
      this.$alert(
        `请确保上传文件是jar并且小于10M，如无法解决，请联系管理员！`,
        "上传文件失败",
        {
          type: "warning",
        }
      );
    },
    removeFile() {
      deleteModuleTempFile(this.backendModuleForm.moduleTempFileName).then();
    },
    downloadModuleFile() {
      axios({
        url: "/backendModule/downloadModuleFileByModuleId",
        method: "get",
        params: { id: this.moduleFile.id },
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
      }).then((response) => {
        if (!response) {
          this.$message.error("下载文件失败");
          return false;
        }
        // 获取文件名
        let objectUrl = URL.createObjectURL(
          new Blob([response], { type: "application/jar" })
        );
        // 文件地址
        const link = document.createElement("a");
        link.download = this.moduleFile.fileName;
        link.href = objectUrl;
        link.click();
        URL.revokeObjectURL(link); // 释放内存
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>