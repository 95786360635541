<template>
  <div>
    <!-- <ul class="nav navbar-nav"> -->
      <!-- <li class="dropdown user user-menu"> -->
        <!-- <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
          <icon-svg icon-class="user" />
        </a> -->
        <ul class="ul-menu">
          <li class="user-body">
            <div class="row">
              <div class="text-right" style="width:100px">姓名：</div>
              <div class="text-blue">{{ name }}</div>
            </div>
            <div class="row">
              <div class="text-right" style="width:100px">账号：</div>
              <div class="text-blue">{{ account }}</div>
            </div>
            <div class="row">
              <div class="text-right" style="width:100px">部门：</div>
              <div class="text-blue">{{ dept }}</div>
            </div>
          </li>
          <li class="divider" style="margin: 0;"></li>
          <div class="row" v-if="tokenSource != 'external' && showShortLink">
            <div class="col-xs-12 text-center">
              <el-button
                class="text-blue"
                type="text"
                icon="el-icon-share"
                @click="showShareDialog"
              >分享当前链接</el-button>
              <el-button class="text-blue" type="text" icon="el-icon-s-shop" @click="myshare">我分享的链接</el-button>
            </div>
          </div>
          <li class="divider" style="margin: 0;"></li>
          <div class="row">
            <div class="col-xs-12 text-center">
              <el-button class="text-blue" type="text" icon="el-icon-key" @click="editPassword">修改密码</el-button>
              <el-button class="text-blue" type="text" icon="el-icon-edit" @click="editInfo">用户信息</el-button>
            </div>
          </div>
          <li class="divider" style="margin: 0;"></li>
          <div v-if="showLogoutButton" class="row">
            <div class="col-xs-12 text-center">
              <el-button class="text-blue" type="text" icon="el-icon-right" @click="logout">退出登录</el-button>
            </div>
          </div>
        <!-- </ul> -->
      <!-- </li> -->
    </ul>
    <shareLinkDialog
      v-bind:dialogVisible="shareDialogVisible"
      v-bind:currentLink="currentLink"
      @visibleChange="shareDialogChange"
    ></shareLinkDialog>
    <myShareLinkList
      v-bind:dialogVisible="myShareLinkListVisible"
      @visibleChange="myShareLinkDialogChange"
    ></myShareLinkList>
  </div>
</template>
<script>
import { mapState } from "vuex";
import shareLinkDialog from "@/views/sys_admin/shortLink/shareLinkDialog.vue";
import myShareLinkList from "@/views/sys_admin/shortLink/mySharLinkList.vue";
export default {
  components: {
    shareLinkDialog,
    myShareLinkList
  },
  data: function() {
    return {
      //个人信息框
      currentLink: "",
      shareDialogVisible: false,
      myShareLinkListVisible: false
    };
  },
  computed: {
    ...mapState({
      name: state =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userAccount &&
        state.user.userdata.userAccount.name,
      dept: state =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userInfo &&
        state.user.userdata.userInfo.orgName,
      account: state =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userAccount &&
        state.user.userdata.userAccount.account,
      tokenSource: state => state.user && state.user.tokenSource
    }),
    showShortLink() {
      return this.$store.state.app.showShortLink;
    },
    showLogoutButton() {
      return !this.$store.state.app.config.logoutDisabled;
    }
  },
  methods: {
    myShareLinkDialogChange(visible) {
      this.myShareLinkListVisible = visible;
    },
    shareDialogChange(visible) {
      if (visible == false) {
        this.currentLink = "";
      }
      this.shareDialogVisible = visible;
    },
    showShareDialog() {
      this.currentLink = window.location.href;
      this.shareDialogVisible = true;
    },
    myshare() {
      this.myShareLinkListVisible = true;
    },
    editPassword() {
      this.$router.push({ name: "admin_web_self_pwd" });
    },
    editInfo() {
      this.$router.push({ name: "admin_web_self_info" });
    },
    logout() {
      this.$router.push({
        name: "logout"
      });
     sessionStorage.clear(); 
    }
  }
};
</script>
<style scoped lang="scss">
.user-menu li {
  margin: 0 !important;
}
.ul-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.user-body,
.user-body li {
  background: #f8f8f8;
  padding: 14px;
}
.user-body:hover {
  background-color: #fff;
}
.user-body .row {
  background: #f8f8f8;
  font-size: 12pt;
  line-height: 35px;
  display: flex;
  margin-right: none;
  margin-left: none;
}
.divider {
  border-bottom: 1px solid #f8f8f8;
  border-top: 1px solid #f8f8f8;
}
</style>