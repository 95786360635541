
// 获取政务微信（粤政易）登录回调地址
export function getZwwxloginCallbackUrl(){
    let zwwxCallBackUrl = window.APP_CONFIG.VUE_APP_ZWWX_AUTH_CALL_BACK_URL
    if(zwwxCallBackUrl.indexOf("{host}") != -1){
        let host = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
        let url = zwwxCallBackUrl.replace(/{host}/g,host);
        return url;
    }
    return zwwxCallBackUrl;
}