<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：">
                <el-input v-model="backendConfig.moduleCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：">
                <el-input v-model="backendConfig.moduleName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="配置文件代码：">
                <el-input v-model="backendConfig.fileCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配置文件名称：">
                <el-input v-model="backendConfig.fileName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：">
                <el-input v-model="backendConfig.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：">
                <el-input v-model="backendConfig.versionID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态：" prop="backendConfig.status">
                <statusSelector v-model="backendConfig.status"></statusSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfig.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
          <el-button type="danger" icon="el-icon-upload2" @click="commit()">删除</el-button>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getBackendConfigById, delBackendConfigById } from "@/api/backend.js";
import statusSelector from "../../components/statusSelector";
export default {
  components: { statusSelector },
  data() {
    return {
      moduleID:"",
      dataId: "",
      titleName: "删除后端模块配置项",
      backendConfig: {},
    };
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.dataId = this.$route.params.id;
    getBackendConfigById(this.dataId).then((response) => {
      this.backendConfig = response;
    });
  },
  methods: {
    commit() {
      this.$confirm(
        "删除后端模块" +
          this.backendConfig.moduleCode +
          "的配置项：" +
          this.backendConfig.fileCode,
        "确认是否删除后端模块配置项",
        {}
      ).then(() => {
        delBackendConfigById(this.dataId).then(
          (response) => {
            if (response) {
              this.toModule();
            }
          },
          (err) => {
            if (err) {
              this.$confirm("", "删除后端模块配置项异常", {});
            }
          }
        );
      });
    },
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID,tabName:"configInfo" }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_info",
        params: { id: param,moduleID:this.moduleID }
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>