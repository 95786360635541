<template>
  <header class="main-header">
    <nav
      class="navbar-static-top"
      style="margin-left: 0px"
      :style="{ backgroundColor: bgcolor }"
      :class="[menu_style ? 'menu_style_navbar' : 'navbar']"
    >
      <!-- data-toggle="push-menu" -->
      <div
        class="data-toggle"
        :class="[hasMore && menu_style ? 'data-toggle-more' : 'data-toggle']"
      >
        <a
          :disabled="hide_nav"
          href="#"
          class="sidebar-toggle"
          role="button"
          data-toggle="push-menu"
          @click="clickMenu"
          v-if="!menu_style"
        >
          <span class="sr-only">Toggle navigation</span>
        </a>
        <a
          :disabled="hide_nav"
          href="#"
          class="sidebar-toggle"
          role="button"
          v-if="menu_style"
          @click="clickMenu"
          style="padding-left: 40px"
        >
          <span class="sr-only">Toggle navigation</span>
        </a>
        <!-- //第二种菜单 -->
        <router-link :to="{ name: app_name_url }" v-if="!menu_style">
          <span class="app-logo pull-left">{{ title }}</span>
        </router-link>
        <div class="popover_Popout" v-if="menu_style">
          <el-popover
            placement="right-end"
            width="300"
            trigger="hover"
            popper-class="leftBoxPopover"
          >
            <loginUser></loginUser>
            <div slot="reference" class="user-pane">
              <icon-svg icon-class="user" />
              <span class="username"></span>
            </div>
          </el-popover>
        </div>
        <!-- //第一种菜单 -->
        <div class="navbar-custom-menu" v-if="!menu_style">
          <ul class="nav navbar-nav">
            <li class="dropdown user user-menu">
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >
                <icon-svg icon-class="user" />
              </a>
              <ul class="dropdown-menu">
                <li class="user-body">
                  <div class="row">
                    <div class="text-right" style="width: 100px">姓名：</div>
                    <div class="text-blue">{{ name }}</div>
                  </div>
                  <div class="row">
                    <div class="text-right" style="width: 100px">账号：</div>
                    <div class="text-blue">{{ account }}</div>
                  </div>
                  <div class="row">
                    <div class="text-right" style="width: 100px">部门：</div>
                    <div class="text-blue">{{ dept }}</div>
                  </div>
                </li>

                <li class="divider" style="margin: 0"></li>
                <div
                  class="row"
                  v-if="tokenSource != 'external' && showShortLink"
                >
                  <div class="col-xs-12 text-center">
                    <el-button
                      class="text-blue"
                      type="text"
                      icon="el-icon-share"
                      @click="showShareDialog"
                      >分享当前链接</el-button
                    >
                    <el-button
                      class="text-blue"
                      type="text"
                      icon="el-icon-s-shop"
                      @click="myshare"
                      >我分享的链接</el-button
                    >
                  </div>
                </div>
                <li class="divider" style="margin: 0"></li>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <el-button
                      class="text-blue"
                      type="text"
                      icon="el-icon-key"
                      @click="editPassword"
                      >修改密码</el-button
                    >
                    <el-button
                      class="text-blue"
                      type="text"
                      icon="el-icon-edit"
                      @click="editInfo"
                      >用户信息</el-button
                    >
                  </div>
                </div>
                <li class="divider" style="margin: 0"></li>
                <div v-if="showLogoutButton" class="row">
                  <div class="col-xs-12 text-center">
                    <el-button
                      class="text-blue"
                      type="text"
                      icon="el-icon-right"
                      @click="logout"
                      >退出登录</el-button
                    >
                  </div>
                </div>
              </ul>
            </li>
          </ul>
        </div>
        <!-- //第一种菜单 -->
        <div class="navbar-custom-menu" v-if="hasMore && !menu_style">
          <ul class="nav navbar-nav">
            <li class="dropdown more more-menu">
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >
                <icon-svg icon-class="more" />
              </a>
              <ul class="dropdown-menu">
                <li
                  :class="{ active: isActived(m) }"
                  class="menu"
                  :style="hoverStyle"
                  v-for="(m, mk) in moreMenus"
                  v-bind:key="mk"
                  @click="clickMenuLi"
                >
                  <router-link
                    v-if="m.route"
                    :to="{ name: m.route }"
                    :target="m.target"
                  >
                    <el-row>
                      <el-col :span="6">
                        <i :class="m.icon" v-if="!m.svg_icon" />
                        <icon-svg
                          class="icon"
                          :icon-class="m.svg_icon"
                          v-else
                        />
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{ m.name }}</span>
                      </el-col>
                    </el-row>
                  </router-link>
                  <a
                    v-else
                    @click="openLink(m.url, m.target, m.fn)"
                    style="cursor: pointer"
                  >
                    <i :class="m.icon" v-if="!m.svg_icon" />
                    <span
                      :class="
                        m.name.length == 4 ? 'top-menu-text4' : 'top-menu-text2'
                      "
                      >{{ m.name }}</span
                    >
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- //第二种菜单 -->
        <div class="navbar-custom-menu" v-if="hasMore && menu_style">
          <el-popover
            placement="right-end"
            width="200"
            trigger="hover"
            popper-class="leftBoxPopover-more"
          >
            <div class="more-content">
              <ul>
                <li
                  :class="{ active: isActived(m) }"
                  class="menu"
                  :style="hoverStyle"
                  v-for="(m, mk) in moreMenus"
                  v-bind:key="mk"
                  @click="clickMenuLi"
                >
                  <router-link
                    v-if="m.route"
                    :to="{ name: m.route }"
                    :target="m.target"
                  >
                    <el-row>
                      <el-col :span="6">
                        <i :class="m.icon" v-if="!m.svg_icon" />
                        <icon-svg
                          class="icon"
                          :icon-class="m.svg_icon"
                          v-else
                        />
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{ m.name }}</span>
                      </el-col>
                    </el-row>
                  </router-link>
                  <a
                    v-else
                    @click="openLink(m.url, m.target, m.fn)"
                    style="cursor: pointer"
                  >
                    <i :class="m.icon" v-if="!m.svg_icon" />
                    <span
                      :class="
                        m.name.length == 4 ? 'top-menu-text4' : 'top-menu-text2'
                      "
                      >{{ m.name }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>

            <div slot="reference" class="more-pane">
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >
                <icon-svg icon-class="more" />
              </a>
            </div>
          </el-popover>
        </div>
        <div class="navbar-custom-menu" v-if="inited">
          <ul class="nav navbar-nav top-menu">
            <li
              :class="{ active: homeActived }"
              :style="hoverStyle"
              v-if="!notUseDefaultHomePage"
              @click="clickMenuHome"
              class="home-page-svg"
            >
              <router-link :to="{ name: 'home' }">
                <icon-svg icon-class="home" />
                <span class="top-menu-text2">首页</span>
              </router-link>
            </li>
            <li
              :class="{ active: isActived(m) }"
              :style="hoverStyle"
              v-for="(m, mk) in topMenus"
              v-bind:key="mk"
              @click="clickMenuLi"
            >
              <router-link
                v-if="m.route"
                :to="{ name: m.route }"
                :target="m.target"
              >
                <i :class="m.icon" v-if="!m.svg_icon" />
                <icon-svg :icon-class="m.svg_icon" v-else />
                <span
                  :class="
                    m.name.length == 4 ? 'top-menu-text4' : 'top-menu-text2'
                  "
                  >{{ m.name }}</span
                >
              </router-link>
              <a
                v-else
                @click="openLink(m.url, m.target, m.fn)"
                style="cursor: pointer"
              >
                <i :class="m.icon" v-if="!m.svg_icon" />
                <span
                  :class="
                    m.name.length == 4 ? 'top-menu-text4' : 'top-menu-text2'
                  "
                  >{{ m.name }}</span
                >
              </a>
            </li>
          </ul>
        </div>
        <shareLinkDialog
          v-bind:dialogVisible="shareDialogVisible"
          v-bind:currentLink="currentLink"
          @visibleChange="shareDialogChange"
        ></shareLinkDialog>
        <myShareLinkList
          v-bind:dialogVisible="myShareLinkListVisible"
          @visibleChange="myShareLinkDialogChange"
        ></myShareLinkList>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapState, mapActions } from "vuex";
import "@/icons";
import shareLinkDialog from "@/views/sys_admin/shortLink/shareLinkDialog.vue";
import myShareLinkList from "@/views/sys_admin/shortLink/mySharLinkList.vue";
import loginUser from "./loginUser.vue";
import { getConfig } from "@/api/desktop.js";
//import config from "@/utils/config.js";

export default {
  components: {
    shareLinkDialog,
    myShareLinkList,
    loginUser,
  },
  name: "MainHeader",
  data() {
    return {
      topMenus: [],
      moreMenus: [],
      shareDialogVisible: false,
      myShareLinkListVisible: false,
      currentLink: "",
      //折叠菜单刷新保存
      activeIcon: true,
      hover: "#0a54c5",
      //showShortLink: window.APP_CONFIG.VUE_SHOW_SHORT_LINK === "true",
    };
  },
  computed: {
    hide_nav() {
      return this.$route.meta && this.$route.meta.nav === false;
    },
    activedStyle() {
      return {
        backgroundColor: this.bgcolor_active,
      };
    },
    hoverStyle() {
      return {
        // "--backgroundColor-hover": this.bgcolor_hover,
        // "--backgroundColor-active": this.bgcolor_active,
        "--backgroundColor-hover": this.menu_style
          ? this.hover
          : this.bgcolor_hover,
        "--backgroundColor-active": this.menu_style
          ? this.hover
          : this.bgcolor_active,
      };
    },
    homeActived() {
      return this.$route.name === "home";
    },
    hasMore() {
      return this.moreMenus.length > 0;
    },
    ...mapState({
      inited: (state) => state.app.inited,
      menus: (state) => state.app.menus,
      bgcolor: (state) =>
        (state.app.config && state.app.config.top_menu_bg) || "",
      bgcolor_active: (state) =>
        (state.app.config && state.app.config.top_menu_bg_active) || "",
      bgcolor_hover: (state) =>
        (state.app.config && state.app.config.top_menu_bg_hover) || "",
      sort: (state) => state.app.top_menu_sort,
      max_main_menus: (state) =>
        (state.app.config && state.app.config.max_main_menus) || "999",
      title: (state) => state.app.title,
      version: (state) => state.app.version,
      appId: (state) => state.appModules.appId,
      account: (state) =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userAccount &&
        state.user.userdata.userAccount.account,
      tokenSource: (state) => state.user && state.user.tokenSource,
      roles: (state) =>
        state.user && state.user.userdata && state.user.userdata.roles,
      rolePermissions: (state) =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.rolePermissionVos,
      name: (state) =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userAccount &&
        state.user.userdata.userAccount.name,
      dept: (state) =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.userInfo &&
        state.user.userdata.userInfo.orgName,
      menu_style: (state) => state.app.config && state.app.config.menu_style, //第二种菜单展现方式
      app_name_url: (state) =>
        state.app.config && state.app.config.app_name_url,
    }),
    showShortLink() {
      return this.$store.state.app.showShortLink;
    },
    showLogoutButton() {
      return !this.$store.state.app.config.logoutDisabled;
    },
    notUseDefaultHomePage() {
      return this.$store.state.app.notUseDefaultHomePage;
    },
  },
  methods: {
    // 支持打开外链
    openLink(url, target = "_blank", fn) {
      // 判断是否存在回调函数,若存在、自定义执行逻辑处理，若不存在、点击跳转页面
      if (fn && typeof fn == "function") {
        fn();
      }else{
        window.open(url, target);
      }
    },
    loaddata() {
      let allMenus = Object.values(this.menus)
        .filter((x) => this.isVisible(x))
        .sort((x, y) => x.index - y.index);
      let max = parseInt(this.max_main_menus || allMenus.length);
      this.topMenus = allMenus.filter((x, index) => index < max);
      this.moreMenus = allMenus.filter((x, index) => index >= max);
    },
    isVisible(menu) {
      if (!menu.roles && !menu.rolePermissions) {
        return true;
      }
      // 在本级节点设置了权限
      if (menu.roles || menu.rolePermissions) {
        if (
          menu.roles &&
          this.roles &&
          menu.roles.some((x) => new Set(this.roles.map((y) => y.code)).has(x))
        ) {
          return true;
        }
        if (
          menu.rolePermissions &&
          this.rolePermissions &&
          menu.rolePermissions.some((x) =>
            new Set(
              this.rolePermissions.map((y) => y.target + ":" + y.action)
            ).has(x)
          )
        ) {
          return true;
        }
      }
      // 含有子菜单，判断至少有一个可见的子菜单
      if (menu.children) {
        if (Object.values(menu.children).some((x) => this.isVisible(x))) {
          return true;
        }
      }

      return false;
    },
    isActived(menu) {
      if (menu.route === this.$route.name) {
        return true;
      }
      if (
        this.$route.meta &&
        this.$route.meta.nav_key &&
        this.$route.meta.nav_key == menu.route
      ) {
        return true;
      }
      if (menu.children) {
        for (let s in menu.children) {
          if (this.isActived(menu.children[s])) {
            return true;
          }
        }
      }
      return false;
    },
    editPassword() {
      this.$router.push({ name: "admin_web_self_pwd" });
    },
    editInfo() {
      this.$router.push({ name: "admin_web_self_info" });
    },
    shareDialogChange(visible) {
      if (visible == false) {
        this.currentLink = "";
      }
      this.shareDialogVisible = visible;
    },
    showShareDialog() {
      this.currentLink = window.location.href;
      this.shareDialogVisible = true;
    },
    myShareLinkDialogChange(visible) {
      this.myShareLinkListVisible = visible;
    },
    myshare() {
      this.myShareLinkListVisible = true;
    },
    logout() {
      this.$router.push({
        name: "logout",
      });
      sessionStorage.clear();
    },
    clickMenu() {
      if (this.$route.name !== "home") {
        this.activeIcon = !this.activeIcon;
        sessionStorage.setItem("activeIcon", JSON.stringify(this.activeIcon));
        this.$emit("updateActiveIcon", this.activeIcon);
      }
    },
    clickMenuHome() {
      this.loaddataMenus();
    },
    clickMenuLi() {
      if (this.menu_style) {
        this.activeIcon = true;
        sessionStorage.setItem("activeIcon", JSON.stringify(this.activeIcon));
        this.$emit("updateActiveIcon", this.activeIcon);
      } else {
        this.$emit("updateActiveIcon", this.activeIcon);
      }
    },
    async loaddataMenus() {
      let configs = (await getConfig(this.appId, "desktop_menus")) || [];
      let devConfig = configs.find((x) => x.level === "DEV");
      if (devConfig) {
        let configValue = devConfig.value;
        let obj = JSON.parse(configValue);
        delete obj.level;
        var menusJson = JSON.stringify(obj, null, 4);
        this.$store.commit("set_menus", {
          type: true,
          menusJson: JSON.parse(menusJson),
        });
      }
    },
    ...mapActions(["set_token", "set_userdata"]),
  },
  mounted() {
    this.loaddata();
    this.loaddataMenus();
    this.activeIcon =
      JSON.parse(sessionStorage.getItem("activeIcon")) || this.activeIcon;
  },
  watch: {
    "$store.state.app.menus_version": {
      handler() {
        this.loaddata();
      },
    },
    "$store.state.app.top_menu_sort": {
      handler() {
        this.loaddata();
      },
    },
    inited() {
      this.loaddata();
    },
    "$store.state.user.userdata_version": function () {
      this.loaddata();
    },
  },
};
</script>
<style lang="scss" scoped>
// 第一种菜单的样式
.main-header .app-logo {
  font-weight: 700;
  text-align: left;
  font-size: 18pt;
  color: #fff;
  line-height: 48px;
  padding-left: 10px;
}

.user-menu a {
  display: block;
  height: 50px;
  width: 50px;
  padding: 10px 10px 10px 10px;
}
.navbar-custom-menu li:hover {
  background-color: var(--backgroundColor-hover) !important;
}

.navbar-custom-menu .active {
  background-color: var(--backgroundColor-active) !important;
}

.top-menu a {
  display: block;
  padding: 0;
}

.top-menu li:hover {
  background-color: #367fa9;
}

.top-menu a i {
  width: 35px;
  font-size: 28px;
  float: left;
  padding: 10px;
}

.top-menu a .svg-icon {
  width: 43px;
  height: 48px;
  float: left;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0 !important;
  margin-right: -8px !important;
}

.top-menu a span {
  text-align: center;
  width: 50px;
  display: block;
  float: left;
}

.top-menu-text2 {
  padding: 15px 5px !important;
}

.top-menu-text4 {
  padding: 5px 5px !important;
}

.user-menu li {
  margin: 0 !important;
}

.user-body,
.user-body li {
  background: #f8f8f8;
  padding: 8px 8px;
  border-bottom: none !important;
  border-top: none !important;
}

.user-body .row {
  background: #f8f8f8;
  font-size: 12pt;
  line-height: 35px;
  display: flex;
}

.navbar-custom-menu svg {
  width: 35px;
  height: 35px;
  padding-top: 10px;
  padding-right: 0.2px;
  margin-top: 0.2em;
  margin-right: -0.65em;
  margin-left: 0.5em;
  float: left;
}

.more {
  width: 50px;
  height: 50px;

  & > ul {
    box-shadow: 0 2px 12px 0 #0000001a;
    border: 1px solid #ebeef5;
  }

  & > a {
    padding: 10px;
  }

  .menu {
    width: 200px;
    color: #fff;

    a {
      text-decoration: none;
      line-height: 40px;
      padding: 0px 15px;
      display: block;

      i {
        padding-left: 10px;
        padding-top: 15px;
        width: 80px;
        height: 40px;
        font-size: 20pt;
      }

      .icon {
        width: 40px;
        height: 40px;
        padding-left: 10px;
        padding-top: 10px;
      }

      .title {
        margin-left: 20px;
        font-size: 14px;
        font-weight: normal;
        border: none;
        color: #666;
      }
    }

    :hover {
      color: #fff !important;
      background-color: var(--backgroundColor-hover) !important;

      .title {
        color: #fff;
      }
    }

    &.active,
    &.active > a {
      color: #fff !important;
      background-color: var(--backgroundColor-hover) !important;

      .title {
        color: #fff;
      }
    }
  }
}
</style>

<style  lang="scss" scoped>
/* 第二种菜单的样式 */
.menu_style_navbar {
  background-color: #4293f4 !important;
  width: 90px;
  height: 5000px;
  position: relative;
  overflow: hidden;
  .popover_Popout {
    position: fixed;
    bottom: 0;
  }
  .svg-icon {
    color: #fff;
    z-index: 88;
    padding-right: 0 !important;
  }
  .top-menu-text2 {
    color: #fff;
  }
  .top-menu-text4 {
    color: #fff;
  }
  .el-icon-s-tools {
    color: #fff;
  }
  .sidebar-toggle {
    color: #fff;
    position: fixed;
    background: #4293f4;
    z-index: 88;
    box-shadow: 0px 0px 5px 0px rgb(255 255 255 / 7%);
    padding: 18px 37.4px;
  }
  .user-pane {
    position: fixed;
    bottom: 10px;
    height: 40px;
    color: #fff;
    width: inherit;
    padding: 0px 25px;
    text-align: center;
    // background: #4293f4;//正式
    z-index: 999;
    opacity: 1;
  }
}
.menu_style_navbar {
  .top-menu a {
    display: block;
  }
}
.menu_style_navbar {
  .top-menu a i {
    width: 100%;
    font-size: 28px;
    float: left;
    padding: 10px;
    color: #fff;
  }
}
.menu_style_navbar {
  .nav > li > a:hover,
  .nav > li > a:active,
  .nav > li > a:focus {
    background-color: #4293f4 !important;
  }
  nav > li > a:hover,
  .nav > li > a:focus {
    background-color: #4293f4 !important;
  }
  /* .navbar-custom-menu li[data-v-3057c06a]:hover{
  background-color: #367fa9!important;
} */
  .navbar-custom-menu .active[data-v-3057c06a] {
    width: 100%;
  }
  .nav > li > a {
    padding: 0px 0px;
  }
  .nav > li {
    width: 100%;
    /* float: left; */
    display: block;
    height: 100px;
    text-align: center;
    font-size: 16px;
  }
  .data-toggle-more {
    height: 80vh !important;
  }
  .data-toggle {
    overflow: hidden;
    height: 90vh;
    overflow-y: scroll;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: -25%;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll !important;
    padding-bottom: 20px;
  }
  .navbar-nav {
    margin-top: 150px !important;
  }
  .navbar-custom-menu {
    .top-menu {
      .home-page-svg {
        position: fixed;
        top: 49px;
        z-index: 88;
        background-color: #4293f4;
        width: inherit !important; //正式
        padding: 0 10.3px;
      }
    }
  }
}
.menu_style_navbar {
  .top-menu a .svg-icon {
    width: 100%;
    height: 48px;
    float: left;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0 !important; //正式
    margin-right: -8px !important;
  }
}
/* 第二种菜单的样式 */
.menu_style_navbar {
  .top-menu a span {
    text-align: center;
    width: 100%;
    display: block;
    float: left;
  }
}
/* //第二种菜单的更多图标 */
.menu_style_navbar .more-pane {
  position: fixed;
  bottom: 57px;
  height: 40px;
  color: #fff;
  width: inherit;
  padding: 0px 25px;
  text-align: center;
  z-index: 999;
  opacity: 1;
  left: 1px;
}
.more-content {
  ul {
    list-style-type: none;
    margin: 5px auto;
    li {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: none;
      a {
        line-height: 20px;
        i {
          width: 80px;
          height: 40px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
<style>
/* 第一种菜单的更多图标 */
.el-popover .leftBoxPopover {
  padding: 0 !important;
}
/* 第二种菜单的更多图标 */
.el-popover .leftBoxPopover-more {
  padding: 10px 5px 5px 5px !important;
  overflow: auto !important;
}
</style>