<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：">
                <el-input v-model="backendModule.moduleCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：">
                <el-input v-model="backendModule.moduleName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：">
                <el-input v-model="backendModule.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：">
                <el-input v-model="backendModule.versionID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块类型：">
                <typeSelector v-model="backendModule.type"></typeSelector>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否在用：">
                <enabledSelector v-model="backendModule.enabled"></enabledSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendModule.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="应用程序包：">
                <el-button type="text" @click="downloadModuleFile">{{moduleFile.fileName}}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
          <el-button type="danger" icon="el-icon-upload2" @click="commit()">删除</el-button>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import {
  delBackendModuleById,
  getBackendModuleById,
  getModuleFileById,
} from "@/api/backend.js";
import typeSelector from "../components/typeSelector";
import enabledSelector from "../components/enabledSelector";

export default {
  components: { typeSelector, enabledSelector },
  data() {
    return {
      dataId: "",
      moduleTab: "",
      titleName: "删除后端模块",
      backendModule: {},
      moduleFile: {},
    };
  },
  methods: {
    commit() {
      this.$confirm(
        "删除后端模块" +
          this.backendModule.moduleCode +
          "，版本号为：" +
          this.backendModule.version,
        "确认是否删除后端模块配",
        {}
      ).then(() => {
        delBackendModuleById(this.dataId).then(
          (response) => {
            if (response) {
              this.moduleTab = null;
              this.backList();
            }
          },
          (err) => {
            if (err) {
              this.$confirm("", "删除后端模块异常", {});
            }
          }
        );
      });
    },
    backList() {
      this.$router.push({
        name: "sys_admin_backend_module",
        params: { moduleTab: this.moduleTab },
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: param, moduleTab: this.moduleTab },
      });
    },
  },
  created() {
    this.dataId = this.$route.params.id;
    this.moduleTab = this.$route.params.moduleTab;
    getBackendModuleById(this.dataId).then((response) => {
      this.backendModule = response;
    });
    getModuleFileById(this.dataId).then((response) => {
      this.moduleFile = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>