<template>
  <el-main>
    <el-row>
      <el-input v-model="menusJson" :rows="10" type="textarea"></el-input>
    </el-row>
    <div style="padding-top:10px;">
      <el-alert type="info">
        <template slot="title">
          <div class="iconSize">需要注意的事项:</div>
          <div
            class="iconSize"
          >1、单个模块菜单或多模块菜单修改格式：必须添加"id": "part"，例如：{"id": "part", "platform_admin": {}}</div>
          <div class="iconSize">2、整个应用菜单修改格式：例如：{ "platform_admin": {}}</div>
          <div class="iconSize">3、重置原本菜单：{}</div>
          <div
            class="iconSize"
          >4、应用进行菜单动态配置后，会以配置的菜单为准，当应用中有模块进行功能菜单的调整或添加版本升级变更时，则需要手动进行配置调整(由于版本更新或者升级，要手动配置调整，请慎重使用菜单动态配置功能)</div>
        </template>
      </el-alert>
    </div>
    <el-row style="padding-top:20px">
      <el-button type="primary" @click="menusSave">保存</el-button>
      <el-button @click="menusrReload('load')">重新加载</el-button>
    </el-row>
  </el-main>
</template>
<script>
import { saveConfig, getConfig } from "@/api/desktop.js";
import { mapState } from "vuex";
export default {
  name: "menusConfig",
  data() {
    return {
      menusJson: "",
      type: true
    };
  },
  props: {
    appId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({ currentAppId: state => state.appModules.appId })
  },
  methods: {
    async menusrReload(par) {
      let configs = (await getConfig(this.appId, "desktop_menus")) || [];
      let devConfig = configs.find(x => x.level === "DEV") || { value: "{}" };
      if(devConfig){
        let configValue = devConfig.value;
        let obj = JSON.parse(configValue);
        delete obj.level;
        this.menusJson = JSON.stringify(obj, null, 4);
      }
      
      if (par === "load") {
        window.location.reload();
      }
    },
    async menusSave() {
      if (this.isJSON(this.menusJson)) {
        let config;
        if (this.menusJson !== "{}") {
          config = JSON.parse(this.menusJson);
          config.level = "DEV";
        } else {
          config = JSON.parse(this.menusJson);
        }
        await saveConfig(this.appId, "desktop_menus", "dev", config);
        if (this.menusJson === "{}") {
          this.menusrReload("load");
        } else {
          this.menusrReload();
        }
        this.$message({
          message: "保存成功！",
          type: "success"
        });
      } else {
        this.$message.error("JSON格式有误，请调整！");
      }
      if (this.currentAppId === this.appId) {
        this.$store.commit("set_menus", {
          type: this.type,
          menusJson: JSON.parse(this.menusJson)
        });
      }
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
    }
  },
  mounted() {
    this.menusrReload();
  }
};
</script>

<style lang="scss" scoped>
.iconSize {
  font-size: 10px;
  font-weight: bold;
}
</style>

