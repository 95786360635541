<template>
	<div class="content-wrapper">
		<section class="content-header">
			<h1>
				测试页				
			</h1>
		</section>
		<!-- Main content -->
		<section class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12" style="padding:0">							
						<div class="callout callout-default">							
							<p>本页为测试页，目标在于测试没有配置菜单的页面能设置指定左侧菜单显示一个高亮菜单</p>
						</div>
						<div class="callout callout-default">							
							<p>涉及两个组件需要修改，请从desktop_web项目复制。</p>
							<p><code>/components/Layout/components/MainHeader.vue</code></p>
							<p><code>/components/Layout/components/Sidebar.vue</code></p>
						</div>
						<div class="callout callout-default">							
							<p>在vue-router路由表中，添加以下代码，其中nav_key指向一个已经配置好的菜单的路由名称</p>
							<p><code>meta: {</code></p>
							<p><code>  nav_key: '指向要显示为高亮的页面的路由名称'</code></p>
							<p><code>}</code></p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import axios from "@/api/request";
import JsZip from "jszip";

export default {
	name: "ModuleList",
	data() {
		return {
			list: [],
			loading: false,
			loadfailed: false,
			uploadUrl: process.env.VUE_APP_API_URL + "/plugin/add",
			uploadData: {}
		};
	},
	computed: {
		headers() {
			let token = this.$store.getters.token;
			return { "JWT-AUTHORIZATION": token };
		}
	},
	methods: {
		search() {
			this.$refs.upload.clearFiles();
			axios({
				url: "/plugin/list",
				vue: this
			}).then(res => {
				this.list = res;
			});
		},
		del(entity) {
			this.$confirm("确定删除?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				axios({
					url: "/plugin/delete",
					params: {
						id: entity.id
					}
				})
					.then(res => {
						if (res == "ok") {
							this.$message({
								type: "success",
								message: "删除成功!"
							});
							this.$router.go(0);
						} else {
							this.search();
							this.$message.error("删除失败!");
						}
					})
					.catch(() => {
						this.search();
					});
			});
		},
		guid() {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
				c
			) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},
		get_module_info(file) {
			return new Promise((resolve, reject) => {
				let new_zip = new JsZip();
				new_zip
					.loadAsync(file.raw)
					.then(f => {
						let _array = Object.values(f.files);
						let common = _array.find(x => {
							return x.name.endsWith("common.js");
						});
						if (typeof common !== "undefined") {
							common.async("string").then(content => {
								console.log(content);
								let Fn = Function;
								let root = new Fn(
									`let module = {}; ${content}; return module.exports.default;`
								)();
								let { name, version, description } = root.package;
								console.log(name, version, description);
								resolve({ name, version, description });
							});
							//resolve(common);
						} else {
							console.log("error");
							reject();
						}
					})
					.catch(() => {
						console.log("not a zip file");
						reject();
					});
			});
		},
		onChanged(file) {
			let that = this;
			if (!file.response) {
				let p = new Promise(resolve => {
					this.get_module_info(file).then(info => {
						info.id = this.guid();
						info.endabled = true;
						info.type = "应用模块";					
						that.uploadData = info;
						resolve();
					});
				});

				p.then(() => {
					that.$refs.upload.submit();
				});
			} else {
				this.$router.go(0);
			}
		},
		handleCreate() {}
	},
	mounted() {
		this.search();
	}
};
</script>

<style>
.el-upload__input {
	display: none !important;
}
.callout.callout-default {
	margin-top: 15px;
	color: #888 !important;
	background-color: #fff !important;
	border-left-color:#0097bc;
}
</style>
