var utils = {}

utils.install = function (Vue) {
    Vue.prototype.getQueryString = function (name) {
        var reg = new RegExp("(^|&|\\?)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null)
            return decodeURI(r[2]);
        r = window.location.hash.substr(1).match(reg);
        if (r != null)
            return decodeURI(r[2]);
        return null;
    }

    Vue.prototype.guid = function() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
            c
        ) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    Vue.prototype.modules = [];
}


export default utils