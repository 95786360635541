<template>
	<el-col :xl="8 * span" :lg="12 * span" :md="12 * span" :sm="24">
		<slot></slot>
	</el-col>
</template>

<script>
export default {
	name: "searchField",
	props: {
		span: {
			type: Number,
			default: 1
		}
	}
};
</script>

<style>
</style>