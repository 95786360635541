<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>

      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="toModule" icon="el-icon-back">返回</el-button>
        <el-button type="primary" plain @click="toEdit(dataId)" icon="el-icon-edit">修改</el-button>
        <el-button type="danger" plain @click="toDel(dataId)" icon="el-icon-delete">删除</el-button>
      </span>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：">
                <el-input v-model="backendConfig.moduleCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：">
                <el-input v-model="backendConfig.moduleName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="配置文件代码：">
                <el-input v-model="backendConfig.fileCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配置文件名称：">
                <el-input v-model="backendConfig.fileName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：">
                <el-input v-model="backendConfig.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：">
                <el-input v-model="backendConfig.versionID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态：">
                <statusSelector v-model="backendConfig.status"></statusSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfig.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="内容：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfig.fileContent"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getBackendConfigById } from "@/api/backend.js";
import statusSelector from "../../components/statusSelector";
export default {
  components: { statusSelector },
  data() {
    return {
      moduleID:"",
      dataId: "",
      titleName: "后端模块配置详情信息",
      backendConfig: {}
    };
  },
  methods: {
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID,tabName:"configInfo" }
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_edit",
        params: { id: param,moduleID:this.moduleID }
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_del",
        params: { id: param,moduleID:this.moduleID }
      });
    }
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.dataId = this.$route.params.id;
    getBackendConfigById(this.dataId).then(response => {
      this.backendConfig = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {}
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>