<template>
	<el-card class="box-card" style="margin-bottom:20px">
		<div slot="header" class="clearfix">
			<span>常用功能</span>
		</div>
		<span v-for="btn in buttons" :key="btn.name">
			<router-link
				:to="{name: btn.view}"
				:params="btn.params"
				v-if="!btn.href"
				:target="btn.target || '_blank'"
			>
				<el-button type="text" style="width:50%;displey:block">{{btn.name}}</el-button>
			</router-link>
			<a :href="btn.href" v-if="btn.href" :target="btn.target || '_blank'">
				<el-button type="text" style="width:50%;displey:block">{{btn.name}}</el-button>
			</a>
		</span>
	</el-card>
</template>

<script>
export default {
	name: "desktop_bookmark",
	data() {
		return {
			buttons: []
		};
	},
	methods: {
		visiable(btn) {
			if (!btn.roles) return true;
			let roles = this.$store.getters.userdata.roles || [];
			return btn.roles.some(x => roles.some(r => r.code === x));
		},
		createBookmarks(list, bookmarks) {
			bookmarks.forEach(b => {
				if (this.visiable(b)) {
					if (!list.some(y => y.name === b.name)) {
						list.push(b);
					}
				}
			});
		},
		loaddata() {
			let list = [];
			let modules = this.modules || [];
			modules.forEach(x => {
				if (typeof x.bookmark === "function") {
					let bookmarkObject = x.bookmark();
					let bookmarks = bookmarkObject;
					if (bookmarks instanceof Promise) {
						bookmarks.then(r => {
							this.createBookmarks(list, r);
						});
					} else {
						this.createBookmarks(list, bookmarks);
					}
				}
			});
			this.buttons = list;
		}
	},
	mounted() {
		this.loaddata();
	}
};
</script>

<style>
</style>