import sys_admin_module_list from '@/views/sys_admin/fontend/list.vue'
// import sys_admin_module_list from '@/views/sys_admin/module_list.vue'
import sys_admin_module_config from '@/views/sys_admin/module_config.vue'
import sys_admin_module_test from '@/views/sys_admin/module_test.vue'
import sys_admin_home from '@/views/sys_admin/home.vue'

import sys_admin_backend_module from '@/views/sys_admin/backend/module/list/index.vue'
import sys_admin_backend_module_info from '@/views/sys_admin/backend/module/info/index.vue'
import sys_admin_backend_module_add from '@/views/sys_admin/backend/module/add/index.vue'
import sys_admin_backend_module_edit from '@/views/sys_admin/backend/module/edit/index.vue'
import sys_admin_backend_module_del from '@/views/sys_admin/backend/module/del/index.vue'
import sys_admin_backend_module_copy from '@/views/sys_admin/backend/module/copy/index.vue'
import sys_admin_backend_module_config_info from '@/views/sys_admin/backend/module/config/info/index.vue'
import sys_admin_backend_module_config_add from '@/views/sys_admin/backend/module/config/add/index.vue'
import sys_admin_backend_module_config_edit from '@/views/sys_admin/backend/module/config/edit/index.vue'
import sys_admin_backend_module_config_del from '@/views/sys_admin/backend/module/config/del/index.vue'
import sys_admin_backend_module_deploy_info from '@/views/sys_admin/backend/module/deploy/info/index.vue'
import sys_admin_backend_module_deploy_add from '@/views/sys_admin/backend/module/deploy/add/index.vue'
import sys_admin_backend_module_deploy_edit from '@/views/sys_admin/backend/module/deploy/edit/index.vue'
import sys_admin_backend_module_deploy_del from '@/views/sys_admin/backend/module/deploy/del/index.vue'
import sys_admin_backend_module_deploy_impl from '@/views/sys_admin/backend/module/deploy/impl/index.vue'
import sys_admin_backend_module_deploy_start from '@/views/sys_admin/backend/module/deploy/start/index.vue'
import sys_admin_backend_module_deploy_stop from '@/views/sys_admin/backend/module/deploy/stop/index.vue'

import sys_admin_serverMachine from '@/views/sys_admin/server/list/index.vue'
import sys_admin_serverMachine_info from '@/views/sys_admin/server/info/index.vue'
import sys_admin_serverMachine_add from '@/views/sys_admin/server/add/index.vue'
import sys_admin_serverMachine_edit from '@/views/sys_admin/server/edit/index.vue'
import sys_admin_serverMachine_del from '@/views/sys_admin/server/del/index.vue'

import sys_admin_app from '@/views/sys_admin/app/list/index.vue'
import sys_admin_app_info from '@/views/sys_admin/app/info/index.vue'
import sys_admin_app_add from '@/views/sys_admin/app/add/index.vue'
import sys_admin_app_edit from '@/views/sys_admin/app/edit/index.vue'
import sys_admin_app_del from '@/views/sys_admin/app/del/index.vue'
import sys_admin_appModule_list from '@/views/sys_admin/app/appModule/list/index.vue'
import sys_admin_appModule_editList from '@/views/sys_admin/app/appModule/editList/index.vue'
import sys_admin_appModule_del from '@/views/sys_admin/app/appModule/del/index.vue'

import sys_admin_deppackage from '@/views/sys_admin/deppackage/list/index.vue'
import sys_admin_deppackage_info from '@/views/sys_admin/deppackage/info/index.vue'
import sys_admin_deppackage_add from '@/views/sys_admin/deppackage/add/index.vue'
import sys_admin_deppackage_del from '@/views/sys_admin/deppackage/del/index.vue'

const SystemManageRoutes = [  
  {
    path: 'platform_admin/home',
    name: 'platform_admin_home',
    component: sys_admin_home,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: 'sys_admin/module_list',
    name: 'sys_admin-module_list',
    component: sys_admin_module_list,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: 'sys_admin/module_config',
    name: 'sys_admin-module_config',
    component: sys_admin_module_config,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: 'sys_admin/module_test',
    name: 'sys_admin-module_test',
    component: sys_admin_module_test,
    meta: {
      nav_key: 'sys_admin-module_list',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },

  /*****************
     * 
     * 后端模块管理
     * 
     ****************/
  {
    path: '/sys_admin/backend/module',
    name: 'sys_admin_backend_module',
    component: sys_admin_backend_module,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/info/index',
    name: 'sys_admin_backend_module_info',
    component: sys_admin_backend_module_info,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/add/index',
    name: 'sys_admin_backend_module_add',
    component: sys_admin_backend_module_add,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/edit/index',
    name: 'sys_admin_backend_module_edit',
    component: sys_admin_backend_module_edit,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/del/index',
    name: 'sys_admin_backend_module_del',
    component: sys_admin_backend_module_del,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/copy/index',
    name: 'sys_admin_backend_module_copy',
    component: sys_admin_backend_module_copy,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  //-------------------
  {
    path: '/sys_admin/backend/module/config/info/index',
    name: 'sys_admin_backend_module_config_info',
    component: sys_admin_backend_module_config_info,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/config/add/index',
    name: 'sys_admin_backend_module_config_add',
    component: sys_admin_backend_module_config_add,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/config/edit/index',
    name: 'sys_admin_backend_module_config_edit',
    component: sys_admin_backend_module_config_edit,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/config/del/index',
    name: 'sys_admin_backend_module_config_del',
    component: sys_admin_backend_module_config_del,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  //-------------------
  {
    path: '/sys_admin/backend/module/deploy/info/index',
    name: 'sys_admin_backend_module_deploy_info',
    component: sys_admin_backend_module_deploy_info,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/add/index',
    name: 'sys_admin_backend_module_deploy_add',
    component: sys_admin_backend_module_deploy_add,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/edit/index',
    name: 'sys_admin_backend_module_deploy_edit',
    component: sys_admin_backend_module_deploy_edit,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/del/index',
    name: 'sys_admin_backend_module_deploy_del',
    component: sys_admin_backend_module_deploy_del,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/impl/index',
    name: 'sys_admin_backend_module_deploy_impl',
    component: sys_admin_backend_module_deploy_impl,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/start/index',
    name: 'sys_admin_backend_module_deploy_start',
    component: sys_admin_backend_module_deploy_start,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/backend/module/deploy/stop/index',
    name: 'sys_admin_backend_module_deploy_stop',
    component: sys_admin_backend_module_deploy_stop,
    meta: {
      nav_key: 'sys_admin_backend_module',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },

  /*****************
   * 
   * 服务器管理
   * 
   ****************/
  {
    path: '/sys_admin/server',
    name: 'sys_admin_serverMachine',
    component: sys_admin_serverMachine,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/server/info/index',
    name: 'sys_admin_serverMachine_info',
    component: sys_admin_serverMachine_info,
    meta: {
      nav_key: 'sys_admin_serverMachine',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/server/add/index',
    name: 'sys_admin_serverMachine_add',
    component: sys_admin_serverMachine_add,
    meta: {
      nav_key: 'sys_admin_serverMachine',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/server/edit/index',
    name: 'sys_admin_serverMachine_edit',
    component: sys_admin_serverMachine_edit,
    meta: {
      nav_key: 'sys_admin_serverMachine',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/server/del/index',
    name: 'sys_admin_serverMachine_del',
    component: sys_admin_serverMachine_del,
    meta: {
      nav_key: 'sys_admin_serverMachine',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },

  /*****************
   * 
   * app管理
   * 
   ****************/
  {
    path: '/sys_admin/app',
    name: 'sys_admin_app',
    component: sys_admin_app,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/info/index',
    name: 'sys_admin_app_info',
    component: sys_admin_app_info,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/add/index',
    name: 'sys_admin_app_add',
    component: sys_admin_app_add,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/edit/index',
    name: 'sys_admin_app_edit',
    component: sys_admin_app_edit,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/del/index',
    name: 'sys_admin_app_del',
    component: sys_admin_app_del,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  //-------------------
  {
    path: '/sys_admin/app/appModule/list/index',
    name: 'sys_admin_appModule_list',
    component: sys_admin_appModule_list,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/appModule/editList/index',
    name: 'sys_admin_appModule_editList',
    component: sys_admin_appModule_editList,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/app/appModule/del/index',
    name: 'sys_admin_appModule_del',
    component: sys_admin_appModule_del,
    meta: {
      nav_key: 'sys_admin_app',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  /*****************
   * 
   * 依赖包管理
   * 
   ****************/
  {
    path: '/sys_admin/deppackage',
    name: 'sys_admin_deppackage',
    component: sys_admin_deppackage,
    meta: {
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/deppackage/info/index',
    name: 'sys_admin_deppackage_info',
    component: sys_admin_deppackage_info,
    meta: {
      nav_key: 'sys_admin_deppackage',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/deppackage/add/index',
    name: 'sys_admin_deppackage_add',
    component: sys_admin_deppackage_add,
    meta: {
      nav_key: 'sys_admin_deppackage',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
  {
    path: '/sys_admin/deppackage/del/index',
    name: 'sys_admin_deppackage_del',
    component: sys_admin_deppackage_del,
    meta: {
      nav_key: 'sys_admin_deppackage',
      roles: ['XI_TONG_GUAN_LI_YUAN'],
    }
  },
]

export default SystemManageRoutes;
