<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="backendConfigForm"
          :model="backendConfigForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：" prop="backendConfig.moduleCode">
                <el-input v-model="backendConfigForm.backendConfig.moduleCode" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：" prop="backendConfig.moduleName">
                <el-input v-model="backendConfigForm.backendConfig.moduleName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="上传文件：">
                <el-upload
                  action="#"
                  :limit="1"
                  :multiple="false"
                  :auto-upload="true"
                  :show-file-list="true"
                  :beforeUpload="beforeUpload"
                  accept=".properties"
                  ref="uploadConfigFile"
                >
                  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                  <div
                    slot="tip"
                    class="el-upload_tip"
                  >上传.properties文件会替换下面的文件代码、文件名称和文件内容，目的减轻手工输入工作量，其他文件类型无法解析。</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="配置文件代码：" prop="backendConfig.fileCode">
                <el-input v-model="backendConfigForm.backendConfig.fileCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配置文件名称：" prop="backendConfig.fileName">
                <el-input v-model="backendConfigForm.backendConfig.fileName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：" prop="backendConfig.version">
                <el-input v-model="backendConfigForm.backendConfig.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：" prop="backendConfig.versionID">
                <el-input v-model="backendConfigForm.backendConfig.versionID" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态：" prop="backendConfig.status">
                <statusSelector v-model="backendConfigForm.backendConfig.status"></statusSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfigForm.backendConfig.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="文件内容：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfigForm.backendConfig.fileContent"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toModule">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { saveBackendConfig, getBackendModuleById } from "@/api/backend.js";
import statusSelector from "../../components/statusSelector";
export default {
  components: { statusSelector },
  data() {
    return {
      titleName: "新建后端模块配置",
      moduleID: "",
      backendConfigForm: {
        backendConfig: {
          moduleCode: "",
          moduleName: "",
          version: "",
          versionID: "",
          fileCode: "",
          fileName: "",
          fileContent: "",
        },
      },
      rules: {
        "backendConfig.moduleCode": [
          { required: true, message: "请输入模块代码", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-20位字符", trigger: "blur" },
        ],
        "backendConfig.moduleName": [
          { required: true, message: "请输入模块名称", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "请输入2-500位字符",
            trigger: "blur",
          },
        ],
        "backendConfig.fileCode": [
          { required: true, message: "请输入配置文件代码", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "请输入2-50位字符",
            trigger: "blur",
          },
        ],
        "backendConfig.fileName": [
          { required: true, message: "请输入配置文件名称", trigger: "blur" },
          { min: 2, max: 100, message: "请输入2-100位字符", trigger: "blur" },
        ],
        "backendConfig.version": [
          { required: true, message: "请输入配置版本号", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
        "backendConfig.versionID": [
          { required: true, message: "请输入配置版本标识", trigger: "blur" },
        ],
        "backendConfig.status": [
          { required: true, message: "请选择状态", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.backendConfigForm.backendConfig.status = "NORMAL";
    this.backendConfigForm.backendConfig.configLevel = "BUILTIN";
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendConfigForm.backendConfig.moduleCode = response.moduleCode;
      this.backendConfigForm.backendConfig.moduleName = response.moduleName;
      this.backendConfigForm.backendConfig.version = response.version;
      this.backendConfigForm.backendConfig.versionID = response.versionID;
    });
  },
  methods: {
    commit() {
      this.$refs["backendConfigForm"].validate((valid) => {
        if (valid) {
          saveBackendConfig(JSON.stringify(this.backendConfigForm)).then(
            (response) => {
              this.toInfo(response.id);
            },
            (err) => {
              if (err.response.status == 500) {
                this.$confirm(
                  err.response.data.data,
                  "新增后端模块配置异常",
                  {}
                );
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_info",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID, tabName: "configInfo" },
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "properties";
      if (!extension) {
        this.$message({
          message: "上传文件只能是properties格式!",
          type: "warning",
        });
      }
      if (!extension) {
        return false;
      } 
      let fileCode = file.name.substring(0, file.name.lastIndexOf("."));
      this.backendConfigForm.backendConfig.fileCode = fileCode;
      this.backendConfigForm.backendConfig.fileName = file.name;
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        // 读取文件内容
        this.backendConfigForm.backendConfig.fileContent = e.target.result;
      };
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>