<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="depPackageVo"
          :model="depPackageVo"
          status-icon
          label-width="120px"
        >
        <el-row>
            <el-col>
              <el-form-item label="上传依赖包：">
                <el-upload
                  :limit="1"
                  :action="uploadDepPackageFileUrl"
                  :headers="headers"
                  :multiple="false"
                  :before-upload="beforeUpload"
                  :auto-upload="true"
                  :show-file-list="true"
                  :on-exceed="handleExceed"
                  :on-success="onUploadSuccess"
                  :on-error="onError"
                  :on-remove="removeFile"
                  accept=".jar"
                  ref="uploadModuleFile"
                >
                  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                  <div slot="tip" class="el-upload_tip">只能上传jar文件，且不超过5MB。</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="依赖包名称：" prop="packageName">
                <el-input v-model="depPackageVo.packageName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="依赖包版本：" prop="version">
                <el-input v-model="depPackageVo.packageVersion"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import config from "@/config";
export default {
  components: {},
  data() {
    return {
      titleName: "新增依赖包",
      moduleTab: "",
      fileList: [], //预览列表
      uploadDepPackageFileUrl:
        config.VUE_APP_API_URL + "/depPackage/uploadDepPackage",
      depPackageVo:{
        packageName: "",
        packageVersion: "",
      },
    };
  },
  computed: {
    headers() {
      let token = this.$store.getters.token;
      return { "JWT-AUTHORIZATION": token };
    },
  },
  created() {
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jar";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!extension) {
        this.$message({
          message: "上传文件只能是jar格式!",
          type: "warning",
        });
      }
      if (!isLt5M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      if (!extension || !isLt5M) {
        return false;
      }
      this.depPackageVo.packageName = file.name;
      let fileNamePart = file.name.substring(0, file.name.indexOf("."));
      let packageVersion = file.name.substring(
        fileNamePart.lastIndexOf("-") + 1,
        file.name.lastIndexOf(".")
      );
      this.depPackageVo.packageVersion = packageVersion;
    },
    onUploadSuccess(res) {
      if (res.meta.code == "200") {
        this.backList()
        this.$message({
              type: "success",
              message: "新增依赖包成功!",
            });

      } else {
        this.$alert(
          `请确保上传文件是jar并且小于5M，如无法解决，请联系管理员！`,
          "上传文件失败",
          {
            type: "warning",
          }
        );
      }
    },
    onError() {
      this.$alert(
        `请确保上传文件是jar并且小于5M，如无法解决，请联系管理员！`,
        "上传文件失败",
        {
          type: "warning",
        }
      );
    },
    backList() {
      this.$router.push({
        name: "sys_admin_deppackage",
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>