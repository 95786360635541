<template>
  <el-select
    v-model="svalue"
    @change="sChange"
    :clearable="true"
    :disabled="disabled"
    placeholder="请选择"
  >
    <el-option
      v-for="item in server_options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import { getServerMachineListAll } from "@/api/server.js";
export default {
  name: "serverMachineSelector",
  props: {
    value: {
      default: "",
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      svalue: this.value,
      sdisabled: this.disabled,
      server_options: [{
        }],
    };
  },
  methods: {
    sChange() {
      this.$emit("serverChange");
    },
  },
  created() {
    getServerMachineListAll({}).then((response) => {
      let backendModule = response;
      backendModule.forEach((item) => {
        this.server_options.push({ value: item.id, label: item.serverName });
      });
    });
  },
  watch: {
    value(newVal) {
      this.svalue = newVal;
    },
    disabled(newVal) {
      this.sdisabled = newVal;
    },
    svalue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>