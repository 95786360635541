import request from '@/api/request'

/**********************
 * 
 * 前端模块接口服务
 * 
 **********************/
// 前端模块分页查询接口
export function getPluginModuleList2(params) {
    return request({
        url: '/plugin/list2',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//查询未删除的前端模块列表接口
export function getPluginModuleListNoDel(params) {
    return request({
        url: '/plugin/list',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//在用的前端模块，包括在用状态的app引用已废置模块
export function getPluginModuleListInUse(params) {
    return request({
        url: '/plugin/listInUse',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//已删除(已废置)的前端模块查询列表接口
export function getPluginModuleListArchives(params) {
    return request({
        url: '/plugin/listArchives',
        method: 'get',
        params: { name: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//查询所有前端模块查询列表接口
export function getPluginModuleListAll(params) {
    return request({
        url: '/plugin/listAll',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//恢复前端模块
export function rollbackPluginModuleLById(params) {
    return request({
        url: '/plugin/rollback',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//归档(废置)前端模块
export function archivePluginModuleLById(params) {
    return request({
        url: '/plugin/archive',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//删除前端模块
export function delPluginModuleLById(params) {
    return request({
        url: '/plugin/delete',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//下载前端模块
export function downloadPluginModuleLById(params) {
    return request({
        url: '/plugin/downloadById',
        method: 'get',
        params: { moduleId: params },
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
    })
}
