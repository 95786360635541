<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}{{backendModule.moduleCode}}</h1>
    </section>

    <section class="content">
      <el-main style="background: #fff;">
        <p class="bg-info" style="padding:15px">
          1、目前仅支持启动linux服务器的应用系统的服务 。
          <br />
          2、请确保服务器{{serverMachine.serverIP}}能正常远程连接，点击
          <el-button
            style="padding:0px"
            type="text"
            @click="testConnect()"
            :loading="testButton.loading"
          >【{{testButton.test}}】</el-button>服务器连接情况。
          <br />
          3、应用系统使用的是服务器{{serverMachine.serverIP}}的{{implementTask.deployPort}}端口。应用系统当前的运行状态是：<span style="color:red">【{{runStatus}}】</span>
          <br />
          4、点击启动按钮，实际上是远程执行服务器{{serverMachine.serverIP}}的{{implementTask.deployPath}} / {{backendModule.moduleCode}}/{{this.implementTask.deployPort}}_{{this.backendModule.moduleCode}}_{{this.backendModule.version}}路径下restart.sh文件。
          <br />
          5、服务器{{serverMachine.serverIP}}的{{implementTask.deployPath}} / {{backendModule.moduleCode}}/{{this.implementTask.deployPort}}_{{this.backendModule.moduleCode}}_{{this.backendModule.version}}路径部署目录的文件清单如下：
          <span
            v-html="serverDeployFileList"
          ></span>
        </p>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">返回</el-button>
          <el-button type="primary" icon="el-icon-upload2" @click="commit()">启动</el-button>
        </div>
        <div id="runLog" style="padding-top:10px" v-if="isShowLog">
          <div class="bg-warning" style="padding:15px">
             <el-switch
              style="display: block"
              v-model="readLogSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="启动刷新"
              inactive-text="关闭刷新"
              @change="readLogSwitchChange"
            ></el-switch>
            每5秒自动刷新一次最新的100行运行日志，日志内容如下：
            <div v-html="runLog"></div>
          </div>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import {
  restartModuleService,
  getImplementTaskById,
  getBackendModuleById,
  getServerDeployFileList,
  readRunLog,
  checkTaskRunStatus,
} from "@/api/backend.js";
import { getServerMachineById, testConnect } from "@/api/server.js";
export default {
  data() {
    return {
      titleName: "启动服务",
      dataId: "",
      moduleID: "",
      runStatus: "",
      implementTask: {},
      backendModule: {},
      serverMachine: {},
      serverPort: {},
      testButton: {
        test: "测试",
        loading: false,
      },
      successContent: "",
      serverDeployFileList: "",
      isShowLog:false,
      readLogSwitch: true,
      runLog: "",
      logTimer: {},
    };
  },
  created() {
    this.dataId = this.$route.params.id;
    this.moduleID = this.$route.params.moduleID;
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendModule = response;
    });
    let taskPromise = new Promise((resolve) => {
      getImplementTaskById(this.dataId).then((response) => {
        this.implementTask = response;
        resolve(this.implementTask.serverMachineID);
      });
    });
    taskPromise.then((serverMachineID) => {
      getServerMachineById(serverMachineID).then((response) => {
        this.serverMachine = response;
      });
    });
    this.checkTaskRunStatus();
    getServerDeployFileList(this.dataId).then((response) => {
      this.serverDeployFileList = response.data.serverDeployFileList.replace(
        /\n/g,
        "<br />"
      );
    });
  },
  methods: {
    commit() {
      const loading = this.$loading({
        lock: true,
        text: "正在启动服务，请稍等1分钟......",
        spinner: "el-icon-loading",
      });
      let taskPromise = new Promise((resolve) => {
        restartModuleService(this.dataId).then(
          (response) => {
            loading.close();
            if (response.meta.code == 1000) {
              this.$message.success({
                message: "恭喜，启动服务成功！",
              });
            }
          },
          (err) => {
            loading.close();
            if (err.response.status == 500) {
              this.$message.error("启动服务失败了，请查看启动日志检查问题！");
            }
          }
        ).finally(()=>{
          resolve();
        });
      });
      taskPromise.then(() => {
        this.checkTaskRunStatus();
        this.startReadRunLog();
      });
    },
    startReadRunLog() {
      this.readRunLog();
      this.isShowLog=true;
      this.logTimer = setInterval(() => {
        this.readRunLog();
      }, 5000);
    },
    stopReadRunlog() {
      clearInterval(this.logTimer);
    },
    readRunLog() {
      readRunLog(this.dataId).then((response) => {
        this.runLog = response.data.runLog.replace(/\n/g, "<br />");
      });
    },
    readLogSwitchChange(){
      this.readLogSwitch?this.startReadRunLog():this.stopReadRunlog();
    },
    testConnect() {
      this.testButton.test = "测试中......";
      this.testButton.loading = true;
      testConnect(this.serverMachine.id)
        .then(
          (response) => {
            this.$alert(response.data.data, "服务器连接正常", { center: true });
          },
          (err) => {
            if (err) {
              this.$alert(
                " 确保服务器的IP、账号、密码和网络正常。",
                "服务器连接异常",
                {
                  type: "warning",
                  center: true,
                }
              );
            }
          }
        )
        .finally(() => {
          this.testButton.test = "测试";
          this.testButton.loading = false;
        });
    },
    checkTaskRunStatus() {
      checkTaskRunStatus(this.dataId).then((response) => {
        this.runStatus =
          response.data.runStatus == "started" ? "运行中" : "已停止";
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_info",
        params: { id: param, moduleID: this.moduleID },
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
  beforeDestroy() {
    this.stopReadRunlog();
  },
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>