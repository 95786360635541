<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{app.appName}}<small>{{app.appId}}</small></h1>
      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="backList" icon="el-icon-back">返回</el-button>
        <el-button type="primary" plain @click="toEdit(dataId)" icon="el-icon-edit">修改</el-button>
        <el-button type="primary" plain @click="toEditAppModuleList(dataId)" icon="el-icon-edit">修改模块清单</el-button>
        <el-button type="primary" plain @click="toEditConfig(dataId)" icon="el-icon-edit">修改配置</el-button>
        <el-button type="danger" plain @click="toDel(dataId)" icon="el-icon-delete">删除</el-button>
      </span>
    </section>
    <section class="content">
      <el-tabs v-model="tabName" type="border-card" tab-position="top">
        <el-tab-pane label="应用信息" name="appInfo">
          <appInfo v-if="tabName == 'appInfo'" :pdataId="this.dataId">
          </appInfo>
        </el-tab-pane>
        <el-tab-pane label="模块清单" name="appModuleList">
          <appModuleList v-if="tabName == 'appModuleList'" :pdataId="this.dataId"></appModuleList>
        </el-tab-pane>
        <el-tab-pane label="系统图标" name="appIconList">
          <icon-list v-if="tabName == 'appIconList'" :appId="this.app.appId"></icon-list>
        </el-tab-pane>
        <el-tab-pane label="首页布局" name="homePageConfig">
          <home-page-config v-if="tabName == 'homePageConfig'" :appId="this.app.appId"></home-page-config>
        </el-tab-pane>
         <el-tab-pane label="菜单动态配置" name="menusConfig">
          <menus-config v-if="tabName == 'menusConfig'" :appId="this.app.appId"></menus-config>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import { getAppById } from '@/api/appModule.js'
import appInfo from './appInfo'
import appModuleList from '../appModule/list/index'
import iconList from './iconList'
import homePageConfig from './homePageConfig'
import menusConfig from './menusConfig'
export default {
  components: {
    appInfo,
    appModuleList,
    iconList,
    homePageConfig,
    menusConfig
  },
  data() {
    return {
      dataId: '',
      moduleTab: '',
      titleName: '应用详情',
      app: {},
      tabName: 'appInfo',
    }
  },
  methods: {
    backList() {
      this.$router.push({
        name: 'sys_admin_app',
      })
    },
    toEdit(param) {
      this.$router.push({
        name: 'sys_admin_app_edit',
        params: { id: param },
        query: { id: param },
      })
    },
    toEditConfig(param) {
      this.$router.push({
        name: 'sys_admin-module_config',
        params: { id: param },
        query: { id: param },
      })
    },
    toDel(param) {
      this.$router.push({
        name: 'sys_admin_app_del',
        params: { id: param },
        query: { id: param },
      })
    },
    toEditAppModuleList(param) {
      this.$router.push({
        name: 'sys_admin_appModule_editList',
        params: { id: param },
        query: { id: param },
      })
    },
  },
  async created() {
    this.dataId = this.$route.query.id
    this.app = await getAppById(this.dataId)
    if (this.$route.params.tabName) {
      this.tabName = this.$route.params.tabName
    }
  },
  mounted() {},
  filters: {},
  watch: {},
}
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>