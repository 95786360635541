<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>应用查询</h1>
      <span class="breadcrumb" align="right">
        <el-button type="primary" size="medium" plain @click="toAdd" icon="el-icon-plus">新增</el-button>
      </span>
    </section>
    <section class="content">
      <search-panel @search="search()">
        <search-field>
          <el-form-item label="名称：">
            <el-input v-model="params.appName" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="应用标识：">
            <el-input v-model="params.appId" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="状态：">
            <enabledSelector v-model="params.enabled" placeholder="请选择"></enabledSelector>
          </el-form-item>
        </search-field>
      </search-panel>
      <span style="color: #888">
        共
        <span class="text-red">{{result.total}}</span> 条符合查询条件
      </span>

      <el-table v-loading="loading" :data="result.tableData" element-loading-text="Loading" border fit highlight-current-row>
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">{{result.pageNumber*result.pageSize+scope.$index+1}}</template>
        </el-table-column>
        <el-table-column prop="appName" label="名称"></el-table-column>
        <el-table-column prop="appId" label="应用标识"></el-table-column>
        <el-table-column prop="enabled" label="状态" :formatter="enabledFormat" width="80"></el-table-column>
        <el-table-column prop="lastModifiedDate" label="更新日期" align="center" :formatter="dateFormat" width="160"></el-table-column>
        <el-table-column label="操作" align="center" width="270">
          <template slot-scope="scope">
            <el-button type="text" @click="toInfo(scope.row.id)">详情</el-button>
            <el-button type="text" @click="toEdit(scope.row.id)">修改</el-button>
            <el-button type="text" @click="toEditModules(scope.row.id)">修改模块</el-button>
            <el-button type="text" @click="toEditConfig(scope.row.id)">修改配置</el-button>
            <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <list-pagination :total="result.total" @change="handleCurrentChange" />
    </section>
  </div>
</template>

<script>
import { getAppList } from '@/api/appModule.js'
import moment from 'moment'
import searchPanel from '@/components/list/SearchPanel.vue'
import searchField from '@/components/list/SearchField.vue'
import listPagination from '@/components/list/ListPagination.vue'
import enabledSelector from '../components/enabledSelector'

export default {
  components: { searchPanel, searchField, listPagination, enabledSelector },
  data() {
    return {
      params: {
        pageNumber: 0,
        pageSize: 10,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    }
  },
  methods: {
    search() {
      getAppList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content
        this.result.total = response.totalElements
        this.result.pageNumber = response.number
        this.result.pageSize = response.size
      })
    },
    handleCurrentChange: function (val) {
      this.params.pageNumber = val - 1
      this.search()
    },
    toAdd() {
      this.$router.push({ name: 'sys_admin_app_add' })
    },
    toInfo(param) {
      this.$router.push({
        name: 'sys_admin_app_info',
        query: { id: param },
      })
    },
    toEdit(param) {
      this.$router.push({
        name: 'sys_admin_app_edit',
        params: { id: param },
        query: { id: param },
      })
    },
    toEditConfig(param) {
      this.$router.push({
        name: 'sys_admin-module_config',
        params: { id: param },
        query: { id: param },
      })
    },
    toEditModules(param) {
      this.$router.push({
        name: "sys_admin_appModule_editList",
        params: { id: param },
        query: { id: param },
      });
    },
    toDel(param) {
      this.$router.push({
        name: 'sys_admin_app_del',
        params: { id: param },
        query: { id: param },
      })
    },
    dateFormat: function (row, column) {
      const date = row[column.property]
      if (date === undefined) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    enabledFormat: function (row, column, value) {
      let enableds = { true: '在用', false: '废置' }
      return enableds[value]
    },
  },
  created() {
    this.search()
  },
  mounted() {},
}
</script>

<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>