<template>
  <el-main style="background: #fff;">
    <el-row>
      <el-col :span="4">
        <span class="sample-name">{{sample}}</span>
        <icon-svg :icon-class="sample" :appId="appId" class="icon sample-icon text-center" :key="`${sample}-${key}`" />
        <el-button @click="deleteBtnClick" v-if="sample" class="tool-btn">删除</el-button>
        <br />
        <br />
        <el-button @click="addBtnClick" class="tool-btn">添加</el-button>
        <br />
        <el-button @click="exportBtnClick" class="tool-btn">导出</el-button>
        <br />
        <el-upload action="#" :show-file-list="false" :on-change="handleImport" :auto-upload="false">
          <el-button class="tool-btn">导入</el-button>
        </el-upload>
      </el-col>
      <el-col :span="20">
        <el-row class="right-panel">
          <el-col :span="6" v-for="(icon, index) in icons_keys" :key="`${icon}-${index}`">
            <div class="text-center icon-cell" :class="{active: icon===sample}" @click="selectIcon(icon)">
              <icon-svg :icon-class="icon" :appId="appId" class="icon text-center" />
              <span class="icon-name">{{icon}}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-dialog title="添加/修改图标" :visible.sync="dialogVisible" width="30%" :append-to-body="true"
      :destory-on-close="true">
      <el-upload class="sample-mask" action="#" :show-file-list="false" :on-change="handlePreview" :auto-upload="false">
        <div v-if="preview" class="sample-icon" v-html="preview" ref="preview">
        </div>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="text-center">
        <span>{{name}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSVG">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import defaultIcons from '@/components/IconSvg/defaultIcons'
import { get_svg, save_svg } from '@/api/iconApi'
import FileSaver from 'file-saver'

export default {
  name: 'IconList',
  data() {
    return {
      dialogVisible: false,
      preview: '',
      name: '',
      icons: {},
      key: 1,
      sample: ''
    }
  },
  props: {
    appId: {
      type: String,
      required: true
    },
  },
  computed: {
    icons_keys() {
      return Object.keys(this.icons).sort()
    }
  },
  methods: {
    exportBtnClick() {
      let json = JSON.stringify(this.icons)
      let blob = new Blob([json], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, 'icons.json')
    },
    selectIcon(name) {
      this.sample = name
    },
    addBtnClick() {
      this.preview = ''
      this.name = ''
      this.dialogVisible = true
    },
    deleteBtnClick() {
      let name = this.sample
      this.$confirm('此操作将永久删除该图标, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let n = Object.assign({}, this.icons)
          delete n[name]
          await save_svg(this.appId, n)
          await this.reload()
          this.sample = ''
        })
        .catch(() => {})
    },
    handleImport(file) {
      const reader = new FileReader()
      reader.readAsText(file.raw, 'UTF-8')
      const $this = this
      reader.onload = async function (e) {
        let fileString = e.target.result
        let icons = JSON.parse(fileString)
        await save_svg($this.appId, icons)
        $this.$nextTick(() => {
          $this.reload()
          $this.sample = this.name
        })
      }
    },
    handlePreview(file) {
      const isSVG = file.raw.type === 'image/svg+xml'
      if (!isSVG) {
        this.$message.error('只能上传是 SVG 格式!')
      } else {
        const reader = new FileReader()
        reader.readAsText(file.raw, 'UTF-8')
        const $this = this
        reader.onload = function (e) {
          let fileString = e.target.result
          const now = +new Date()
          // 动态改变样式名称，避免多个svg在同一个页面时样式冲突
          fileString = fileString.replace(/cls-/g, `cls-${now}-`)
          $this.preview = fileString
          $this.name = (file.name || '').replace('.svg', '')
        }
      }
    },
    async saveSVG() {
      this.dialogVisible = false
      let n = {}
      n[this.name] = this.preview
      await save_svg(this.appId, Object.assign({}, this.icons, n))
      this.$nextTick(() => {
        this.reload()
        this.sample = this.name
      })
    },
    async reload() {
      let icons = await get_svg(this.appId)
      this.icons = Object.assign({}, defaultIcons, icons)
      if (!this.icons[this.sample]) {
        this.sample = ''
      }
      this.key = +new Date()
    },
  },
  mounted() {
    this.reload()
  },
}
</script>

<style lang="scss" scoped>
.sample-name {
  width: 150px;
  height: 20px;
  display: block;
  text-align: center;
  color: #5cb6ff;
}
.sample-icon {
  height: 150px;
  width: 150px;
  border: 1px dashed #5cb6ff;
  padding: 10px;
  color: #5cb6ff;
}
.right-panel {
  height: 300px;
  display: block;
  overflow: auto;
  cursor: pointer;
  :hover {
    color: #5cb6ff;
  }
}
.icon-cell {
  padding: 10px;
  display: block;
  border: 1px solid transparent;
  height: 50px;
  .icon {
    float: left;
    width: 30px;
    height: 30px;
  }
  .icon-name {
    float: left;
    line-height: 30px;
    font-size: 12pt;
    padding-left: 10px;
  }
  &.active {
   color: #5cb6ff;
   border: 1px dashed #5cb6ff;
  }
}

.tool-btn {
  width: 150px;
}
</style>

