<template>
	<div class="search-panel">
		<!--查询-->
		<el-form label-width="120px" size="medium">
			<div class="search-panel-right">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-button type="primary" @click="search" size="medium">查询</el-button>
					</el-col>
					<el-col :span="12">
						<el-button @click="advenceSearch = !advenceSearch" size="medium">{{advenceSearch?'收起':'展开'}}</el-button>
					</el-col>
				</el-row>
			</div>
			<div class="search-panel-left">
				<el-row :gutter="20">
					<slot></slot>
				</el-row>
				<el-row v-show="advenceSearch" :gutter="20">
					<slot name="more"></slot>
				</el-row>
			</div>
			<div class="search-panel-bottom">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-button type="primary" @click="search" size="medium">查询</el-button>
					</el-col>
					<el-col :span="12">
						<el-button @click="advenceSearch = !advenceSearch" size="medium">{{advenceSearch?'收起':'展开'}}</el-button>
					</el-col>
				</el-row>
			</div>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "SearchPanel",
	data() {
		return {
			advenceSearch: false
		};
	},
	methods: {
		search() {
			this.$emit("search");
		}
	}
};
</script>

<style>
@media only screen and (min-width: 992px) {
	.search-panel-left {
		margin-right: 160px;
		padding-bottom: 0;
	}

	.search-panel-right {
		float: right;
		width: 140px;
	}

	.search-panel-bottom {
		display: none;
	}
}

@media only screen and (max-width: 992px) {
	.search-panel-left {
		width: 100%;
		padding-bottom: 40px;
	}

	.search-panel-right {
		width: 140px;
		float: right;
		display: none;
	}

	.search-panel-bottom {
		width: 140px;
		float: right;
		margin-top: -40px;
	}
}

.search-panel {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 18px 18px 0px 18px;
  margin-bottom: 18px;
}
</style>