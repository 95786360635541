<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{ titleName }}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff">
        <el-form
          ref="serverMachineForm"
          :model="serverMachineForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="服务器名称："
                prop="serverMachine.serverName"
              >
                <el-input
                  v-model="serverMachineForm.serverMachine.serverName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务器IP：" prop="serverMachine.serverIP">
                <el-input
                  v-model="serverMachineForm.serverMachine.serverIP"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="登录账户：" prop="serverMachine.account">
                <el-input
                  v-model="serverMachineForm.serverMachine.account"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="登录密码：" prop="serverMachine.pwd">
                <el-input
                  v-model="serverMachineForm.serverMachine.pwd"
                  show-password
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="用途：" prop="serverMachine.useType">
                <useTypeCheckbox
                  v-model="serverMachineForm.serverMachineUseTypeList"
                ></useTypeCheckbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：" prop="serverMachine.description">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4 }"
                  placeholder="请输入内容"
                  v-model="serverMachineForm.serverMachine.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="backList"
              >取消</el-button
            >
            <el-button type="primary" icon="el-icon-upload2" @click="commit()"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { saveServerMachine } from "@/api/server.js";
import useTypeCheckbox from "../components/useTypeCheckbox.vue";

export default {
  components: { useTypeCheckbox },
  data() {
    return {
      titleName: "新建服务器",
      serverMachineForm: {
        serverMachine: {},
        serverMachineUseTypeList: [],
      },
      rules: {
        "serverMachine.serverName": [
          { required: true, message: "请输入服务器名称", trigger: "blur" },
          { min: 2, max: 200, message: "请输入2-200位字符", trigger: "blur" },
        ],
        "serverMachine.serverIP": [
          { required: true, message: "请输入服务器IP", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "请输入2-50位字符",
            trigger: "blur",
          },
        ],
        "serverMachine.account": [
          { required: true, message: "请输入登录账户", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
        "serverMachine.pwd": [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    commit() {
      this.$refs["serverMachineForm"].validate((valid) => {
        if (valid) {
          saveServerMachine(JSON.stringify(this.serverMachineForm)).then(
            (response) => {
              this.moduleTab = response.serverName;
              this.toInfo(response.id);
            },
            (err) => {
              if (err.response.status == 500) {
                this.$confirm(err.response.data.data, "新增服务器异常", {});
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_info",
        params: { id: param },
      });
    },
    backList() {
      this.$router.push({
        name: "sys_admin_serverMachine",
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>