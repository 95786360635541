<template>
  <el-select v-model="svalue" @change="sChange" :clearable="true" placeholder="请选择">
    <el-option
      v-for="item in status_options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "backendConfigStatus",
  props: {
    value: {
      default: ""
    }
  },
  data() {
    return {
      svalue: this.value,
      status_options: [
        {
          value: "NORMAL",
          label: "正常"
        },
        {
          value: "DELETED",
          label: "废置"
        }
      ]
    };
  },
  methods: {
    sChange() {
      this.$emit("statusChange");
    }
  },
  watch: {
    value(newVal) {
      this.svalue=newVal;
    },
    svalue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>