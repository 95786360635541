<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{ titleName }}</h1>

      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="backList" icon="el-icon-back"
          >返回</el-button
        >
        <el-button
          type="primary"
          plain
          @click="toEdit(dataId)"
          icon="el-icon-edit"
          >修改</el-button
        >
        <el-button
          type="danger"
          plain
          @click="toDel(dataId)"
          icon="el-icon-delete"
          >删除</el-button
        >
      </span>
    </section>
    <section class="content">
      <el-main style="background: #fff">
        <el-form label-width="120px" :disabled="true">
          <el-row>
            <el-col :span="12">
              <el-form-item label="服务器名称：">
                <el-input
                  v-model="serverMachineForm.serverMachine.serverName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务器IP：">
                <el-input
                  v-model="serverMachineForm.serverMachine.serverIP"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="登录账户：">
                <el-input
                  v-model="serverMachineForm.serverMachine.account"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="登录密码：">
                <el-input
                  v-model="serverMachineForm.serverMachine.pwd"
                  show-password
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="用途：">
                <useTypeCheckbox
                  v-model="serverMachineForm.serverMachineUseTypeList"
                ></useTypeCheckbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4 }"
                  placeholder="请输入内容"
                  v-model="serverMachineForm.serverMachine.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getServerMachineById, getUseTypeByMachineId } from "@/api/server.js";
import useTypeCheckbox from "../components/useTypeCheckbox.vue";

export default {
  components: { useTypeCheckbox },
  data() {
    return {
      dataId: "",
      titleName: "服务器详情信息",
      serverMachineForm: { serverMachine: {}, serverMachineUseTypeList: [] },
    };
  },
  methods: {
    backList() {
      this.$router.push({
        name: "sys_admin_serverMachine",
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_edit",
        params: { id: param },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_serverMachine_del",
        params: { id: param },
      });
    },
    handleCreate() {},
  },
  created() {
    this.dataId = this.$route.params.id;
    let serverMachinePromise = new Promise((resolve) => {
      getServerMachineById(this.dataId).then((response) => {
        this.serverMachineForm.serverMachine = response;
        resolve();
      });
    });

    serverMachinePromise.then(() => {
      getUseTypeByMachineId(this.serverMachineForm.serverMachine.id).then(
        (response) => {
          let useTypeList = response;
          useTypeList.forEach((item) => {
            this.serverMachineForm.serverMachineUseTypeList.push(item.useType);
          });
        }
      );
    });
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>