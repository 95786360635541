import request from '@/api/request'

/**********************
 * 
 * app接口服务
 * 
 **********************/
 //查询公开的app列表接口
 export function getPubAppList(params) {
    return request({
        url: '/pub/desktopAnon/listApp',
        method: 'get',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}
//app查询列表接口
export function getAppList(params) {
    return request({
        url: '/app/list',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//全部app查询列表接口
export function getAppListAll(params) {
    return request({
        url: '/app/listAll',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//根据id查询app接口
export function getAppById(params) {
    return request({
        url: '/app/getById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//根据id查询app接口
export function getModuleFileById(params) {
    return request({
        url: '/app/getModuleFileById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//新增app
export function saveApp(params) {
    return request({
        url: '/app/add',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//修改app
export function editApp(params) {
    return request({
        url: '/app/edit',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//删除app
export function delAppById(params) {
    return request({
        url: '/app/deleteById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

/**********************
 * 
 * 应用与模块关系接口服务
 * 
 **********************/
//应用与模块关系查询列表接口
export function getAppModuleList(params) {
    return request({
        url: '/appModule/list',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//公开的应用与模块关系查询列表接口(必须传入appId)
export function getAppModuleListPublic(params) {
    return request({
        url: '/pub/desktopAnon/listAppModulePublic',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//根据id查询应用与模块关系接口
export function getAppModuleById(params) {
    return request({
        url: '/appModule/getById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}

//新增应用与模块关系
export function saveAppModule(params) {
    return request({
        url: '/appModule/add',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//修改应用与模块关系
export function editAppModule(params) {
    return request({
        url: '/appModule/edit',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//修改应用与模块关系列表
export function editAppModuleList(params) {
    return request({
        url: '/appModule/editList',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//删除应用与模块关系
export function delAppModuleById(params) {
    return request({
        url: '/appModule/deleteById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
}
