const SystemManageMenus = {
  platform_admin: {
    code: 'platform_admin',
    name: '平台',
    icon: 'el-icon-s-tools',
    route: 'platform_admin_home',
    roles: ['XI_TONG_GUAN_LI_YUAN'],
    children: {
      sys_admin_app: {        
        name: "应用",
        icon: "fa fa-th",
        roles: ['XI_TONG_GUAN_LI_YUAN'],
        code: "sys_admin_app",
        children: {
          app_list: {
            name: "应用管理",
            icon: "fa el-icon-magic-stick",
            roles: ['XI_TONG_GUAN_LI_YUAN'],
            route: 'sys_admin_app'
          }
        }
      },
      sys_admin_module: {
        name: "模块",
        icon: "fa fa-th",
        roles: ['XI_TONG_GUAN_LI_YUAN'],
        code: "sys_admin_module",
        children: {           
          module_list: {
            name: "前端模块管理",
            icon: "fa el-icon-magic-stick",
            roles: ['XI_TONG_GUAN_LI_YUAN'],
            route: 'sys_admin-module_list'
          },
          backend_module: {
            name: "后端模块管理",
            icon: "fa el-icon-magic-stick",
            roles: ['XI_TONG_GUAN_LI_YUAN'],
            route: 'sys_admin_backend_module'
          }
        }
      },
      sys_admin_server: {
        name: "服务器",
        icon: "fa fa-th",
        roles: ['XI_TONG_GUAN_LI_YUAN'],
        code: "sys_admin_server",
        children: {                     
          server_list: {
            name: "服务器管理",
            icon: "fa el-icon-magic-stick",
            roles: ['XI_TONG_GUAN_LI_YUAN'],
            route: 'sys_admin_serverMachine'
          },
        }
      },
      sys_admin_deppackage: {
        name: "依赖包",
        icon: "fa fa-th",
        roles: ['XI_TONG_GUAN_LI_YUAN'],
        code: "sys_admin_deppackage",
        children: {                     
          deppackage: {
            name: "依赖包管理",
            icon: "fa el-icon-magic-stick",
            roles: ['XI_TONG_GUAN_LI_YUAN'],
            route: 'sys_admin_deppackage'
          },
        }
      }
    }
  }
}

export default SystemManageMenus;
