import request from '@/api/request'


/*********************服务器*****************************/
//服务器查询接口
export function getServerMachineListAll(params) {
  return request({
    url: '/serverMachine/listAll',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//服务器查询列表接口
export function getServerMachineList(params) {
  return request({
    url: '/serverMachine/list',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询服务器接口
export function getServerMachineById(params) {
  return request({
    url: '/serverMachine/getById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询后端模块发布包接口
export function getModuleFileById(params) {
  return request({
    url: '/serverMachine/getModuleFileById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//新增服务器
export function saveServerMachine(params) {
  return request({
    url: '/serverMachine/add',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//修改服务器
export function editServerMachine(params) {
  return request({
    url: '/serverMachine/edit',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除服务器
export function delServerMachineById(params) {
  return request({
    url: '/serverMachine/deleteById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//测试服务器连接
export function testConnect(params) {
  return request({
    url: '/serverMachine/testConnect',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//根据id查询服务器的用途清单接口
export function getUseTypeByMachineId(params) {
  return request({
    url: '/serverMachine/getUseTypeByMachineId',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}

//删除服务器与用途的关系
export function deleteUseTypeById(params) {
  return request({
    url: '/serverMachine/deleteUseTypeById',
    method: 'get',
    params: { id: params },
    headers: { 'Content-Type': 'application/json' },
  })
}
