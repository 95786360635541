<template>
  <div>
    <el-table
      v-loading="loading"
      :data="result.tableData"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" align="center" width="60">
        <template slot-scope="scope">{{result.pageNumber*result.pageSize+scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="appId" label="应用标识"></el-table-column>
      <el-table-column prop="moduleCode" label="模块名称"></el-table-column>
      <el-table-column prop="moduleVersion" label="模块版本"></el-table-column>
      <el-table-column prop="moduleId" label="模块标识"></el-table-column>
      <el-table-column prop="moduleType" label="模块类型" :formatter="typeFormatter"></el-table-column>
      <el-table-column
        prop="lastModifiedDate"
        label="更新日期"
        align="center"
        :formatter="dateFormat"
        width="160"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scope">
          <el-button v-if="scope.row.moduleType=='BackendModule'" type="text" @click="containerOrder(scope.row)">容器指令</el-button>
          <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="容器指令" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <div>
        <i>export JWT={{containerOrderObj.JWT}}</i><br/>
        <i>export APP_ID={{containerOrderObj.APP_ID}}</i><br/>
        <i>export DESKTOP_API={{containerOrderObj.DESKTOP_API}}</i><br/>
        <i>export MODULE_CODE={{containerOrderObj.MODULE_CODE}}</i><br/>
        <i></i><br/>
        <i>docker run -d -p 8080:8080 -e JWT=$JWT -e APP_ID=$APP_ID -e DESKTOP_API=$DESKTOP_API -e MODULE_CODE=$MODULE_CODE --name {{containerOrderObj.MODULE_CODE}} zsoft/api:1.0</i><br/>
      </div>
		</el-dialog>

  </div>
</template>

<script>
import { getAppById } from "@/api/appModule.js";
import { getAppModuleList } from "@/api/appModule.js";
import { getConfig } from '@/api/desktop.js'
import moment from "moment";

export default {
  name: "appModuleList",
  components: {},
  props: {
    pdataId: {},
  },
  data() {
    return {
      dataId: this.pdataId,
      params: {
        appId: "",
        pageNumber: 0,
        pageSize: 999,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
      dialogVisible:false,
      containerOrderObj: {}
    };
  },
  methods: {
    search() {
      getAppModuleList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_appModule_del",
        params: { id: param,appId:this.dataId },
        query: { id: param,appId:this.dataId },
      });
    },
    dateFormat: function (row, column,value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeFormatter(row, column,value){
      let moduleTypes = {"PluginModule":"前端模块","BackendModule":"后端模块"};
      return moduleTypes[value];
    },
    async containerOrder(row){
      this.containerOrderObj.JWT= this.$store.getters.token
      this.containerOrderObj.APP_ID = row.appId
      this.containerOrderObj.MODULE_CODE = row.moduleCode
      let configs = await getConfig(row.appId, 'desktop-web')
      let deployConfig = configs.find((x) => x.level === 'DEPLOY')
      if (deployConfig) {
        let deployValue = deployConfig.value
        this.containerOrderObj.DESKTOP_API = JSON.parse(deployValue).desktop_inner_url
      } else {
        this.containerOrderObj.DESKTOP_API = "请输入工作台内网接口地址";
      }
      this.dialogVisible = true
    }
  },
  created() {
    let appPromise = new Promise((resolve) => {
      getAppById(this.dataId).then((response) => {
        this.params.appId = response.appId;
        resolve(null);
      });
    });
    appPromise.then(() => {
      this.search();
    });
  },
  mounted() {},
  watch: {
    pappId(newVal) {
      this.params.appId = newVal;
    },
  },
};
</script>

