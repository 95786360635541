import Cookies from 'js-cookie'
import store from "../store";
import { checkIfGlobalLogout } from '@/utils/loginManager'
let showDebug = false
function debug(msg) {
	if (showDebug) {
		console.log(msg)
	}
}

// 安装监听器
export function initStorageListener() {
	if (document.hidden !== undefined) {
		document.addEventListener('visibilitychange', function () {
			checkIfGlobalLogout(store);//检查是否全局退出

			if (document.hidden) {
				debug('页面被隐藏')
				const t_appId = store.state && store.state.appModules && store.state.appModules.appId
				localStorage.setItem('TEMP_APPID', t_appId);//打开新窗口的时候在localStorage设置临时的appId
				if (store.getters.has_token) {
					let token = store.state && store.state.user && store.state.user.token;
					saveTempToken(token,true)
					setTimeout(() => {
						const temp_token = localStorage.getItem('TEMP_TOKEN');
						if (temp_token) {
							try {
								const token_obj = JSON.parse(temp_token)
								if (
									token_obj &&
									token_obj.tabId === getTabId() &&
									document.hidden
								) {
									localStorage.removeItem('TEMP_TOKEN')
									debug('清理缓存')
								}
							} catch {
								debug('无法解释的TOKEN', temp_token)
							}
						}
					}, 10000)
				}
			} else {
				let token = store.state && store.state.user && store.state.user.token;
				if(window.APP_CONFIG.IS_GET_COOKIE == 'true' && token){
					Cookies.set('TEMP_TOKEN', JSON.stringify({ tabId: '', token }));
					console.log("passTab.js set TEMP_TOKEN to cookies : " + token);
				}
				debug('页面被激活')
			}
			
		})
	}
	//监听关闭窗口事件
	document.addEventListener('beforeunload', function () {
		//localStorage.removeItem('TEMP_APPID');//关闭窗口要清理临时的appId
	})
}

// 当窗口活动时，刷新token后同步临时Token到localStorage
// 当打开新窗口时强制保持TEMP_TOKEN
export function saveTempToken(token,forceSave) {
	if (!document.hidden||forceSave) {
		const t = JSON.stringify({ tabId: getTabId(), token })
		localStorage.setItem('TEMP_TOKEN', t)
		localStorage.setItem('TEMP_TOKEN_TIME', new Date().getTime())
		debug(`写入临时TOKEN [${t}]`)
	}
}

function getTabId() {
	const tabId = sessionStorage.getItem('tabId') || +new Date()
	sessionStorage.setItem('tabId', tabId)
	return tabId
}
