<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}{{appForm.app.appName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="名称：" prop="app.appName">
                <el-input v-model="appForm.app.appName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应用标识：" prop="app.appId">
                <el-input v-model="appForm.app.appId"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="状态：" prop="app.enabled">
                <enabledSelector v-model="appForm.app.enabled"></enabledSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：" prop="app.description">
                <el-input v-model="appForm.app.description" type="textarea" :rows="2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
          <el-button type="danger" icon="el-icon-upload2" @click="commit()">删除</el-button>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getAppById, delAppById } from "@/api/appModule.js";
import enabledSelector from "../components/enabledSelector";
export default {
  components: {
    enabledSelector,
  },
  data() {
    return {
      dataId: "",
      titleName: "删除应用",
      appForm: { app: {} },
    };
  },
  created() {
    this.dataId = this.$route.params.id;
  },
  methods: {
    commit() {
      this.$confirm(
        "删除应用：" + this.appForm.app.appName,
        "确认是否删除应用",
        {}
      ).then(() => {
        delAppById(this.dataId).then(
          (response) => {
            if (response) {
              this.backList();
            }
          },
          (err) => {
            if (err) {
              this.$confirm("", "删除应用异常", {});
            }
          }
        );
      });
    },
    backList() {
      this.$router.push({ name: "sys_admin_app" });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_app_info",
        params: { id: param },
        query: { id: param },
      });
    },
  },
  mounted() {
    getAppById(this.dataId).then((response) => {
      this.appForm.app = response;
    });
  },
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>