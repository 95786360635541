<template>
  <el-select v-model="svalue" @change="sChange" :clearable="true" :disabled="disabled" placeholder="请选择">
    <el-option
      v-for="item in type_options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "backendModuleType",
  props: {
    value: {
      default: ""
    },
    disabled:{
      type:Boolean,
    }
  },
  data() {
    return {
      svalue: this.value,
      sdisabled: this.disabled,
      type_options: [
        {
          value: "内置模块",
          label: "内置模块"
        },
        {
          value: "应用模块",
          label: "应用模块"
        }
      ]
    };
  },
  methods: {
    sChange() {
      this.$emit("typeChange");
    }
  },
  watch: {
    value(newVal) {
      this.svalue=newVal;
    },
    disabled(newVal) {
      this.sdisabled=newVal;
    },
    svalue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>