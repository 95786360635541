<template>
  <div class="login-gb">
    <!-- 登录页可新增组件头部title_login -->
    <titleLogin
      ref="titleLogin"
      v-if="$root.$options.components.titleLogin"
    ></titleLogin>
    <div class="login-form">
        <!-- 登录页可新增组件头部title_login -->
      <div class="login-title" v-if="$root.$options.components.titleLogin">
        系统登录
      </div>
      <div class="login-title" v-else>{{ title }}</div>
      <div class="form-item">
        <i class="el-icon-user"></i>
        <input
          v-model="username"
          type="text"
          autocomplete="off"
          placeholder="用户"
        />
      </div>
      <div class="form-item">
        <i class="el-icon-lock"></i>
        <input
          v-model="password"
          type="password"
          autocomplete="off"
          placeholder="密码"
        />
      </div>
      <div class="form-item" v-if="verifyCodeEnabled">
        <div class="verify-code">
          <i class="el-icon-aim"></i>
          <input
            id="verifyCode"
            v-model="verifyCode"
            type="text"
            autocomplete="off"
            placeholder="验证码"
          />
          <img
            id="verifyCodeImg"
            style="cursor: pointer"
            alt="点击刷新验证码"
            @click="refreshVerifyCode"
            :src="verifyCodeSrc"
          />
        </div>
      </div>
      <div class="form-item">
        <el-button
          type="primary"
          @click.stop="login"
          :disabled="button_name != '登录'"
          >{{ button_name }}</el-button
        >
        <!-- <button id="submit" :disabled="button_name != '登录'" @click.stop="login">{{button_name}}</button> -->
      </div>
      <!-- 登录页可新增组件formLogin -->
      <formLogin v-if="$root.$options.components.formLogin"></formLogin>
      <div class="form-item" v-if="zwwxAuthEnabled">
        <el-link :href="toZwwxCallBackUrl()" :underline="false">粤政易登录</el-link>
      </div>
      <div class="form-item" v-if="weLinkAuthEnabled">
        <el-link :href="weLinkCallBackUrl" :underline="false"
          >weLink登录</el-link
        >
      </div>
      <div class="form-item" v-if="showRegist">
        <router-link
          :to="{ name: 'userRegist', query: { localRegist: true } }"
          style="color: #fff"
          >注册</router-link
        >
      </div>

      <div class="form-item" v-if="error">
        <span class="error">{{ error }}</span>
      </div>
    </div>
    <!-- 登录页可新增组件底部footer_login -->
    <footerLogin v-if="$root.$options.components.footerLogin"></footerLogin>
     <div class="footer" v-else>
    <p>
      <span v-if="client_name">主办单位：{{ client_name }}</span>
      <span v-if="support_name">技术支持：{{ support_name }}</span>
    </p>
     <p v-if="bottom_login_browserTips">
     {{bottom_login_browserTips}}
    </p>
    <p v-else>
      为了获得更好的支持体验，建议您使用谷歌、火狐、IE9及以上版本、360极速模式等高级版本的浏览器。
    </p>
    <span
      ><a
        target="_blank"
        style="color: #fff"
        href="http://beian.miit.gov.cn/"
        rel="nofollow"
        >{{ icp_num }}</a
      >
    </span>
  </div>
    <!-- 登录页可新增组件底部footer_login -->
    <span v-if="zwwxAuthEnabled && zwwxLogoutEnabled"
      ><iframe
        :src="zwwxAuthLogoutUrl"
        style="width: 0px; height: 0px; display: none"
      ></iframe
    ></span>
    <nologin-notice />
    <el-dialog
            title="请输入二次验证码"
            :visible.sync="secondVerifyCodeDialog"
            width="30%">
      <span><el-input v-model="secondVerifyCode" placeholder="请输入二次验证码"></el-input></span>
      <span slot="footer" class="dialog-footer">
      <el-button @click="closeSecondVerifyDialog()">取 消</el-button>
      <el-button type="primary" @click="submitSecondVerifyCode()">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userLogin,
  getCurrentLoginUser,
  getVerifyCode,
  getPublicKey,
  getInfoByAccountId
} from "@/api/auth";
import { getDivisionByCodeFromStore } from "@/api/baseobject";
import { mapState } from "vuex";
import { JSEncrypt } from "jsencrypt";
import nologinNotice from "@/components/NologinNotice";
import { excludeGlobalLogout } from "@/utils/loginManager";
import { getZwwxloginCallbackUrl } from "@/utils/gdZwwxLoginUtil.js";

import Cookies from "js-cookie";

// import { load_modules } from "@/api/moduleLoader";
export default {
  components: { nologinNotice},
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      verifyCodeEnabled:
        window.APP_CONFIG.VUE_APP_VERIFY_CODE_ENABLED !== "false",
      pwdEncryptEnabled:
        window.APP_CONFIG.VUE_APP_PWD_ENCRYPT_ENABLED !== "false",
      zwwxAuthEnabled: false,
      zwwxLogoutEnabled: true,
      zwwxCallBackUrl: window.APP_CONFIG.VUE_APP_ZWWX_AUTH_CALL_BACK_URL,
      zwwxAuthLogoutUrl: window.APP_CONFIG.VUE_APP_ZWWX_AUTH_LOGOUT_URL,
      weLinkAuthEnabled: false,
      weLinkCallBackUrl: window.APP_CONFIG.VUE_APP_WELINK_AUTH_CALL_BACK_URL,
      verifyCode: "",
      verifyCodeSrc: "",
      button_name: "登录",
      error: false,
      icp_num: "",
      bottom_login_browserTips:window.APP_CONFIG.BOTTOM_LOGIN_BROWSERTIPS,
      secondVerifyCodeEnabled :window.APP_CONFIG.SECOND_VERIFY_CODE_ENABLED === "true",
      secondVerifyCodeDialog: false,
      secondVerifyCode:''
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.app.title,
      client_name: (state) => state.app.config.client_name,
      support_name: (state) => state.app.config.support_name,
    }),
    showRegist() {
      return this.$store.state.app.showRegist;
    },
    homePageRoule() {
      return this.$store.state.app.homePageRoule;
    },
    notUseDefaultHomePage() {
      return this.$store.state.app.notUseDefaultHomePage;
    },
  },
  methods: {
    login: function () {
      sessionStorage.removeItem("marginLeft");
      // sessionStorage.removeItem("marginLeftHome");
      // sessionStorage.removeItem("SidebarStyle");
      sessionStorage.removeItem("activeIcon");
      sessionStorage.removeItem("isCollapse");
      this.$store.dispatch("set_token", "");
      this.button_name = "正在登录";
      this.error = false;
      if(this.secondVerifyCodeEnabled && this.secondVerifyCode === ''){
        this.secondVerifyCodeDialog = true;
        return;
      }
      if (this.pwdEncryptEnabled) {
        this.password = this.rsaEncrypt(this.password);
      }
      userLogin(
        this.username,
        this.password,
        this.verifyCode,
        this.verifyCodeEnabled,
        sessionStorage.getItem("verifyCodeToken"),
        this.secondVerifyCode
      ).then(
        (res) => {
          sessionStorage.removeItem("verifyCodeToken");
          if (res.meta.code == 1000) {
            let token = res.meta.token;
            this.$store.dispatch("set_token", token);
            console.log(
              "long.vue logined set token to session store : " + token
            );
            this.$store.dispatch("set_tokenSource", "");
            if (window.APP_CONFIG.IS_GET_COOKIE == "true") {
              Cookies.set("TEMP_TOKEN", JSON.stringify({ tabId: "", token }));
              console.log("long.vue logined set token to cookies : " + token);
            }
            // sessionStorage.setItem( "marginLeftHome",JSON.stringify("230")

            // );
            //  sessionStorage.setItem("marginLeft", JSON.stringify("91px !important"));
            this.loginAfter();
          }
        },
        (err) => {
          if (err.response.data.meta.code == 7000) {
            this.error = "验证码不正确!";
          } else {
            this.error = err.response.data.meta.msg;
          }
          this.button_name = "登录";
          this.refreshVerifyCode();
          this.secondVerifyCode = '';
        }
      );
    },
    loginAfter: async function () {
      let userRes = await getCurrentLoginUser();
      let that = this;
      //this.$store.dispatch("set_userdata", res);
      //用户部分信息获取
      let sensitiveInfo = await getInfoByAccountId(userRes.userAccount.id)
      userRes.userAccount.account = sensitiveInfo.data.sensitiveInfoDTO.account
      userRes.userInfo.mobile = sensitiveInfo.data.sensitiveInfoDTO.mobile
      userRes.userInfo.phone = sensitiveInfo.data.sensitiveInfoDTO.phone
      userRes.userInfo.email = sensitiveInfo.data.sensitiveInfoDTO.email
      userRes.userInfo.identityNumber = sensitiveInfo.data.sensitiveInfoDTO.identityNumber
      //更新行政区划名称
      if (null != userRes.userAccount.divisionCode) {
        let t_division = await getDivisionByCodeFromStore(
          userRes.userAccount.divisionCode
        );
        if (null != t_division) {
          userRes.userAccount.divisionName = t_division.name;
        }
      }
      //userRes.userAccount.divisionName = t_division.name;
      this.$store.dispatch("set_userdata", userRes);
      excludeGlobalLogout(this.$store);

      let appModules = this.$store.getters.appModules;
      appModules
        .filter((s_appModule) => s_appModule.moduleType == "PluginModule")
        .forEach((appModule) => {
          let t_module =
            this.$store.getters.actualModules()[appModule.moduleCode];
          if (
            t_module &&
            t_module.initModuleGlobalData &&
            typeof t_module.initModuleGlobalData === "function"
          ) {
            t_module.initModuleGlobalData(that); //各模块初始化以后执行initModuleGlobalData
          }
        });

      let defaultRedirect = { name: "home" };
      //增加从localStorage找loginAfterRedirect是为了解决从第三方回跳时sessionStorage被清空的问题。
      let loginAfterRedirect =
        sessionStorage.getItem("loginAfterRedirect") ||
        localStorage.getItem("loginAfterRedirect");
      if (loginAfterRedirect && JSON.parse(loginAfterRedirect).path) {
        defaultRedirect = JSON.parse(loginAfterRedirect);
        sessionStorage.removeItem("loginAfterRedirect");
        localStorage.removeItem("loginAfterRedirect");
      } else {
        //个性化首页
        if (this.homePageRoule) {
          defaultRedirect = { name: this.homePageRoule };
        }
        //配置不展示系统默认首页且没配自定义首页路由
        if (this.notUseDefaultHomePage && !this.homePageRoule) {
          defaultRedirect = { name: "error404" };
        }
      }
      this.$router.push(defaultRedirect);
    },
    refreshVerifyCode: function () {
      if (this.verifyCodeEnabled) {
        getVerifyCode().then((res) => {
          sessionStorage.setItem("verifyCodeToken", res.data.verifyCodeToken);
          this.verifyCodeSrc =
            "data:image/jpeg;base64," + res.data.verifyCodeImg;
        });
      }
    },
    getPublicKey: function () {
      if (this.pwdEncryptEnabled) {
        getPublicKey().then((res) => {
          sessionStorage.setItem("rsaPublicKey", res.data.rsaPublicKey);
        });
      }
    },
    rsaEncrypt: function (param) {
      if (param.length >= 50) {
        return param;
      }
      let pubKey =
        "-----BEGIN PUBLIC KEY-----" +
        sessionStorage.getItem("rsaPublicKey") +
        "-----END PUBLIC KEY-----";
      let encryptStr = new JSEncrypt();
      encryptStr.setPublicKey(pubKey);
      let data = encryptStr.encrypt(param.toString());
      return data;
    },
    keyDown(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    closeSecondVerifyDialog(){
      this.secondVerifyCodeDialog = false;
      this.secondVerifyCode = '';
      this.button_name = "登录";
    },
    submitSecondVerifyCode(){
      this.secondVerifyCodeDialog = false;
      this.login();
    },
    toZwwxCallBackUrl(){
      return getZwwxloginCallbackUrl();
    }
  },
  mounted: function () {
    this.refreshVerifyCode();
    this.getPublicKey();
    window.addEventListener("keydown", this.keyDown);
    this.icp_num = this.$store.getters.config.icp_num;
    if (this.$store.state.app.inited == true) {
      this.zwwxAuthEnabled =
        window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === "true";
    }
    if (this.$store.state.app.inited == true) {
      this.weLinkAuthEnabled =
        window.APP_CONFIG.VUE_APP_WELINK_AUTH_ENABLED === "true";
    }
  },
  created: function () {
    if (this.$route.params.thirdLogoutEnabled == false) {
      this.zwwxLogoutEnabled = this.$route.params.thirdLogoutEnabled;
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  watch: {
    "$store.getters.config.icp_num": {
      deep: true,
      handler() {
        this.icp_num = this.$store.getters.config.icp_num;
      },
    },
    "$store.getters.config.authBaseURL": function (p_newUrl) {
      if (p_newUrl) {
        this.refreshVerifyCode();
      }
    },
    "$store.state.app.inited": function () {
      this.zwwxAuthEnabled =
        window.APP_CONFIG.VUE_APP_ZWWX_AUTH_ENABLED === "true";
      this.weLinkAuthEnabled =
        window.APP_CONFIG.VUE_APP_WELINK_AUTH_ENABLED === "true";
    },
  },
};
</script>
<style scoped>
.login-gb {
  background: url("~@/assets/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
}
  .login-form {
    margin-top: 100px;
  }
  .login-form .login-title {
    width: 100%;
    height: 104px;
    margin-top: 150px;
    color: #fff;
    font-size: 32pt;
    text-align: center;
    font-weight: 600;
  }

  .login-form .form-item {
    position: relative;
    width: 380px;
    margin: 0 auto;
    padding-bottom: 16px;
  }

  .login-form .form-item i {
    color: #ececec;
    font-size: 16pt;
    padding: 13px 30px;
    position: absolute;
  }

  .login-form .form-item input {
    width: 308px;
    height: 48px;
    padding-left: 70px;
    border: 1px solid #ffffff4a;
    border-radius: 6px;
    font-size: 18px;
    color: #fff;
    background-color: #ffffff4f;
    outline: none;
  }

  .login-form .form-item .verify-code i {
    color: #ececec;
    font-size: 16pt;
    padding: 13px 30px;
    position: absolute;
  }

  .login-form .form-item .verify-code input {
    width: 168px;
  }

  .login-form .form-item .verify-code img {
    padding-left: 20px;
    width: 140px;
    height: 48px;
    vertical-align: top;
  }

  .login-form .form-item button {
    width: 308px;
    height: 48px;
    border: 0px solid #fff;
    border-radius: 6px;
    font-size: 18px;
    color: #ffffff;
    background-color: #184093;
    text-align: center;
  }

  .login-form .form-item .el-link {
    font-size: 16px;
    color: #ffffff;
    padding-left: 220px;
    text-align: right;
  }

  .login-form .form-item .error {
    color: red;
    font-size: 14pt;
  }

  .footer {
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .footer span {
    margin: 0 16px;
  }
</style>
