import config from '@/config'
import $ from 'jquery'

let title = config.name || '';

const app = {
  state: {
    title,
    menus: {},
    menusType: false,
    top_menu_sort: '',
    module_menus: [],
    menus_version: 0,//用来监控菜单变化
    version: 0,
    inited: false,
    modules: {},
    layout: {},
    noframe: false,
    config: {},
    showShortLink: false,
    showRegist: false,
    homePageRoule: '',
    notUseDefaultHomePage: false

  },
  mutations: {
    set_layout(state, param) {
      let { layout } = param;
      state.layout = layout;
    },
    set_top_menu_sort(state, param) {
      let { sort } = param;
      let index = 0;
      let s = (sort || "").split(',').map(x => {
        index++;
        let pair = x.split(':');
        let old_name = pair[0];
        let name = pair[1] || old_name;
        let svg_icon = pair[2] || false;
        return { old_name, name, svg_icon, index };
      });
      Object.values(state.menus).forEach(m => {
        let cm = s.find(x => x.old_name === m.name || x.name === m.name) || { index: 999 };
        m.name = cm.name || m.name;
        m.index = cm.index;
        m.svg_icon = cm.svg_icon;
      });
      state.top_menu_sort = sort;
    },
    add_module(state, param) {
      let { module } = param;
      let { name } = module.package;
      state.modules[name] = module;
    },
    init_start(state) {
      state.inited = false;
    },
    init_finished(state) {
      state.inited = true;
    },
    set_menus(state, param) {
      if (param.type && JSON.stringify(param.menusJson) != "{}" && param.menusJson) {
        state.menusType = param.type
      }
      if (state.menusType) {
        if(param.menus){
          return;
        }
        if (param.menusJson.id) {
        delete param.menusJson.id;
        } else {
        state.menus = param.menusJson;
        }
        Object.getOwnPropertyNames(param.menusJson).forEach(function (key) {
          state.menus[key] = param.menusJson[key];
        })
        state.menus_version++;
        state.menus = Object.assign({}, state.menus, param.menusJson);
      }
      else {   
        let { menus } = param;
        state.menus_version++;
        state.menus = $.fn.extend(true, state.menus, menus);
      }
      let index = 0;
      let s = (state.top_menu_sort || "").split(',').map(x => {
        index++;
        let pair = x.split(':');
        let old_name = pair[0];
        let name = pair[1] || old_name;
        let svg_icon = pair[2] || false;
        return { old_name, name, svg_icon, index };
      });
      Object.values(state.menus).forEach(m => {
        let cm = s.find(x => x.old_name === m.name || x.name === m.name) || { index: 999 };
        m.name = cm.name || m.name;
        m.index = cm.index;
        m.svg_icon = cm.svg_icon;
      });
    },
    set_title(state, param) {
      let { title } = param;
      state.title = title;
    },
    set_noframe(state, param) {
      let { noframe } = param;
      state.noframe = noframe;
    },
    set_config(state, param) {
      let { config } = param;
      state.config = config;
    },
    set_showShortLink(state, param) {
      let { showShortLink } = param;
      state.showShortLink = showShortLink;
    },
    set_showRegist(state, param) {
      let { showRegist } = param;
      state.showRegist = showRegist;
    },
    set_notUseDefaultHomePage(state, param) {
      let { notUseDefaultHomePage } = param;
      state.notUseDefaultHomePage = notUseDefaultHomePage;
    },
    set_homePageRoule(state, param) {
      let { homePageRoule } = param;
      state.homePageRoule = homePageRoule;
    },
  },
  actions: {
    set_menus(context, param) {
      context.commit('set_menus', param);
    },
    set_title(context, param) {
      context.commit('set_title', param);
    },
    set_top_menu_sort(context, param) {
      context.commit('set_top_menu_sort', param);
    },
  },
  getters: {
    menus: (state) => {
      return state.menus
    },
    title: (state) => {
      return state.title
    },
    appVersion: (state) => {
      return state.version;
    },
    modules: (state) => {
      return state.modules;
    },
    //linshaofeng 20210422增加一个实时获取模块对象的方法，不适用缓存，上面的modules是有缓存的
    actualModules: (state) => () => {
      return state.modules;
    },
    config: (state) => {
      return state.config;
    },
  }
}

export default app