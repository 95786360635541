<template>
  <div>
    <el-dialog
      id="mylinkDialog"
      title="我分享的链接"
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="myShareListVisible"
      @close="closeMyShareListDialog"
      width="90%"
    >
      <el-main style="background: #fff">
        <span style="color: #888">
          共
          <span class="text-red">{{ result.total }}</span> 条符合查询条件
        </span>

        <el-table v-loading="loading" :data="result.tableData" element-loading-text="Loading" border fit highlight-current-row>
          <el-table-column label="序号" align="center" width="50">
            <template slot-scope="scope">{{ result.pageNumber * result.pageSize + scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="shortLink" label="短链接" width="300"></el-table-column>
          <el-table-column prop="startTime" label="生效时间" width="100"></el-table-column>
          <el-table-column prop="endTime" label="失效时间" width="100"></el-table-column>
          <el-table-column prop="autoLogin" label="自动登录" width="80"></el-table-column>
          <el-table-column prop="longLink" label="原链接"></el-table-column>
          <el-table-column prop="createdDate" label="生成时间" :formatter="dateFormat" width="100"></el-table-column>
          <el-table-column label="操作" align="center" width="60">
            <template slot-scope="scope">
              <el-button type="text" @click="delMyShortLink(scope.row.id, scope.row.shortLink)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <list-pagination :total="result.total" :pageSize="params.pageSize" @change="handleCurrentChange" />
      </el-main>
    </el-dialog>
  </div>
</template>

<script>
import { listMyShortLink, deleteMyShortLinkById } from "@/api/shortLink.js";
import listPagination from "@/components/list/ListPagination.vue";
import moment from "moment";

export default {
  components: { listPagination },
  name: "myShareLinkList",
  props: {
    value: {},
    dialogVisible: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      myShareListVisible: this.dialogVisible,
      params: {
        pageNumber: 0,
        pageSize: 5,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    };
  },
  computed: {},
  methods: {
    search() {
      listMyShortLink(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
        this.result.tableData.forEach((item) => {
          if (item.loginAccount) {
            item.autoLogin = '是';
          }else{
            item.autoLogin = '否';
          }
        });
      });
    },
    handleCurrentChange: function(val) {
      this.params.pageNumber = val - 1;
      this.search();
    },
    delMyShortLink(dataId, p_shortLink) {
      this.$confirm("删除短链接：" + p_shortLink, "确认是否删除短链接", {}).then(() => {
        deleteMyShortLinkById(dataId).then(
          (response) => {
            if (response) {
              this.search();
            }
          },
          (err) => {
            if (err) {
              this.$confirm("", "删除短链接异常", {});
            }
          }
        );
      });
    },
    closeMyShareListDialog() {
      this.myShareListVisible = false;
      this.shareLinkForm = {};
    },
    dateFormat: function(row, column) {
      const date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {},
  watch: {
    dialogVisible(newVal) {
      this.myShareListVisible = newVal;
      if (newVal == true) {
        this.search();
      }
    },
    myShareListVisible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("visibleChange", newVal);
      }
    },
  },
};
</script>
<style>
#mylinkDialog .el-dialog .el-dialog__body {
  padding: 0px;
}
</style>
