import axios from 'axios'
import store from '@/store/index.js'
import app_config from '@/config'

axios.defaults.withCredentials = false

const service = axios.create({
  baseURL: app_config.VUE_APP_API_URL,
  timeout: 600000
});

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.vue) {
      config.vue.loading = true
      config.vue.loadfailed = false;
    }

    if (!config.cache) {
      config.params = {
        t: new Date().getTime(),
        ...config.params
      }
    }

    if (Object.keys(store.getters.token).length != 0) {
      config.headers['JWT-AUTHORIZATION'] = store.getters.token;
    }
    config.headers['PLATFORM-APPID'] = store.getters.appId || window.APP_CONFIG.VUE_APP_ID_DEFAULT;

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response && response.config && response.config.vue) {
      response.config.vue.loading = false;
      response.config.vue.loadfailed = false;
    }
    if(response.headers['jwt-authorization']){//特别注意：response自动把JWT-AUTHORIZATION转成小写jwt-authorization了
      store.dispatch("set_token", response.headers['jwt-authorization']);
    }
    const res = response.data
    return res
  },
  err => {
    if (err && err.response) {
      if(err.response.config){
        console.error("请求错误的URL是："+ err.response.config.baseURL+err.response.config.url);
      }
      switch (err.response.status) {
        case 400:
          err.message = '错误请求'
          break
        case 403:
          err.message = '拒绝访问'
          break
        case 404:
          err.message = '请求错误，未找到资源'
          break
        default:
          break
      }
    }
    if (err && err.config && err.config.vue) {
      err.config.vue.loading = false;
      err.config.vue.loadfailed = true;
    }
    return Promise.reject(err)
  }
)

export default service
