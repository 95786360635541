const division = {
    state: {
        divisionData: [],
        divisionCode:""
    },
    mutations: {
        set_divisionData(state, divisionData) {
            state.divisionData = divisionData
        },
        add_divisionData(state, data) {
            let exists = false;
            for (let i = 0; i < state.divisionData.length; i++) {
                if (state.divisionData[i].code == data.code) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
                state.divisionData.push(data);
            }
        },
        set_divisionCode(state, divisionCode) {
            state.divisionCode = divisionCode
        },
    },
    actions: {
        set_divisionData(state, divisionData) {
            state.commit('set_divisionData', divisionData)
        },
        add_divisionData(state, data) {
            state.commit('add_divisionData', data)
        },
        set_divisionCode(state, divisionCode) {
            state.commit('set_divisionCode', divisionCode)
        },
    },
    getters: {
        divisionData: (state) => {
            return state.divisionData;
        },
        divisionCode: (state) => {
            return state.divisionCode;
        },
            
    }
}

export default division