const UPDATE_CACHEDROUTENAMES = 'update_cachedroutenames';
const state = {
  // 缓存的路由列表
  cachedRouteNames: []
};
const mutations = {
  [UPDATE_CACHEDROUTENAMES](st, { action, route }) {
    const methods = {
      // 添加
      add: () => {
        st.cachedRouteNames.push(route);
      },
      // 删除
      delete: () => {
        st.cachedRouteNames.splice(st.cachedRouteNames.findIndex(e => e === route), 1);
      }
    };
    methods[action]();
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
