<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form ref="appModuleForm" :model="appModuleForm" status-icon label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="应用名称：">
                <el-input v-model="app.appName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应用标识：">
                <el-input v-model="appModuleForm.appId" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="前端模块清单：">
                <el-tree ref="pluginModuleTree" node-key="id" :data="pluginModuleData" :props="moduleProps" show-checkbox></el-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="后端模块清单：">
                <el-tree ref="backendModuleTree" node-key="id" :data="backendModuleData" :props="moduleProps" show-checkbox></el-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getAppById, getAppModuleList } from '@/api/appModule.js'
import { editAppModuleList } from '@/api/appModule.js'
import { getPluginModuleListAll } from '@/api/pluginModule.js'
import { getBackendModuleAllList } from '@/api/backend.js'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      titleName: '修改应用',
      dataId: '',
      app: {},
      pluginModuleData: [],
      backendModuleData: [],
      moduleProps: { label: 'label', children: 'children', isLeaf: 'leaf' },
      appModuleForm: {
        appId: '',
        pluginModuleIdList: [],
        backendModuleIdList: [],
      },
      params: {
        appId: '',
        pageNumber: 0,
        pageSize: 999,
      },
    }
  },
  methods: {
    commit() {
      if (!this.verifyData(this.$refs.pluginModuleTree)) {
        return
      }
      if (!this.verifyData(this.$refs.backendModuleTree)) {
        return
      }
      //提交前获取选中的节点
      this.appModuleForm.pluginModuleIdList = this.$refs.pluginModuleTree.getCheckedKeys(
        true
      )
      this.appModuleForm.backendModuleIdList = this.$refs.backendModuleTree.getCheckedKeys(
        true
      )
      this.$refs['appModuleForm'].validate((valid) => {
        if (valid) {
          editAppModuleList(JSON.stringify(this.appModuleForm)).then(
            (response) => {
              if (response) {
                this.toInfo(this.dataId)
              }
            },
            (err) => {
              if (err.response.status == 500) {
                this.$confirm(err.response.data.data, '修改应用异常', {})
              }
            }
          )
        } else {
          return false
        }
      })
    },
    verifyData(p_tree) {
      //提交数据前校验模块是否重复选中
      const leafNode = p_tree.getCheckedKeys(true) //选中的叶子节点
      let dupModules = []
      let existsModules = []
      leafNode.forEach((pluginKey) => {
        const moduleCode = p_tree.getNode(pluginKey).parent.parent.label
        let exists = existsModules.some((item) => {
          return item == moduleCode
        })
        if (exists) {
          const existsDup = dupModules.some((dup) => {
            return dup == moduleCode
          })
          if (!existsDup) {
            dupModules.push(moduleCode)
          }
        } else {
          existsModules.push(moduleCode)
        }
      })
      if (dupModules.length > 0) {
        this.$message.error(
          dupModules + '模块重复选择了，一个模块只能选一个版本！！！'
        )
      }
      //提交数据前校验模块是否重复选中
      return dupModules.length <= 0
    },
    toInfo(param) {
      this.$router.push({
        name: 'sys_admin_app_info',
        params: { id: param, tabName: 'appModuleList' },
        query: { id: param, tabName: 'appModuleList' },
      })
    },
    labelFormat: function (value, id) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss') + ' ' + id
    },
    initModuleList() {
      getPluginModuleListAll().then((response) => {
        let moduleList = response;
         //防止平台管理模块被加载，过滤出该模块
        moduleList = moduleList.filter(item => {
          return  item.name !== "desktop-web"
        })	
        moduleList.forEach((module) => {
          let existsModule = null
          this.pluginModuleData.forEach((plugin) => {
            if (plugin.label == module.name) {
              existsModule = plugin
              return
            }
          })
          if (existsModule) {
            //模块存在
            let existsVersion = null
            existsModule.children.forEach((version) => {
              if (version.label == module.version) {
                //模块的版本存在，树中此模块版本的某个id
                existsVersion = true
                version.children.push({
                  id: module.id,
                  label: this.labelFormat(module.lastModifiedDate, module.id),
                  leaf: true,
                })
                return
              }
            })
            if (!existsVersion) {
              //模块的版本不存在，树中此模块装载版本和id
              existsModule.children.push({
                label: module.version,
                children: [
                  {
                    id: module.id,
                    label: this.labelFormat(module.lastModifiedDate, module.id),
                    leaf: true,
                  },
                ],
              })
            }
          } else {
            //模块不存在，树装载整个模块
            this.pluginModuleData.push({
              label: module.name,
              children: [
                {
                  label: module.version,
                  children: [
                    {
                      id: module.id,
                      label: this.labelFormat(
                        module.lastModifiedDate,
                        module.id
                      ),
                      leaf: true,
                    },
                  ],
                },
              ],
            })
          }
        })
      })
      getBackendModuleAllList(JSON.stringify()).then((response) => {
        let moduleList = response
        moduleList.forEach((module) => {
          let existsModule = null
          this.backendModuleData.forEach((backend) => {
            if (backend.label == module.moduleCode) {
              existsModule = backend
              return
            }
          })
          if (existsModule) {
            let existsVersion = null
            existsModule.children.forEach((version) => {
              if (version.label == module.version) {
                existsVersion = true
                version.children.push({
                  id: module.id,
                  label: this.labelFormat(module.lastModifiedDate, module.id),
                  leaf: true,
                })
                return
              }
            })
            if (!existsVersion) {
              existsModule.children.push({
                label: module.version,
                children: [
                  {
                    id: module.id,
                    label: this.labelFormat(module.lastModifiedDate, module.id),
                    leaf: true,
                  },
                ],
              })
            }
          } else {
            this.backendModuleData.push({
              label: module.moduleCode,
              children: [
                {
                  label: module.version,
                  children: [
                    {
                      id: module.id,
                      label: this.labelFormat(
                        module.lastModifiedDate,
                        module.id
                      ),
                      leaf: true,
                    },
                  ],
                },
              ],
            })
          }
        })
      })
    },
  },
  created() {
    this.dataId = this.$route.query.id
    this.initModuleList()
    let appPromise = new Promise((resolve) => {
      getAppById(this.dataId).then((response) => {
        this.app = response
        this.appModuleForm.appId = this.app.appId
        this.params.appId = this.app.appId
        resolve(null)
      })
    })
    appPromise.then(() => {
      getAppModuleList(JSON.stringify(this.params)).then((response) => {
        let appModuleList = response.content
        appModuleList.forEach((appModule) => {
          appModule.moduleType == 'PluginModule'
            ? this.appModuleForm.pluginModuleIdList.push(appModule.moduleId)
            : this.appModuleForm.backendModuleIdList.push(appModule.moduleId)
        })
        //延迟加载初始化选中，解决更新或刷新模块选中时丢失已选中的模块问题
        setTimeout(() => {
        this.$nextTick(function() {
          this.$refs.pluginModuleTree.setCheckedKeys(
          this.appModuleForm.pluginModuleIdList,
          true
        )
        this.$refs.backendModuleTree.setCheckedKeys(
          this.appModuleForm.backendModuleIdList,
          true
        )
        })
      }, 300)

      })
    })
  },
  mounted() {},
  filters: {},
  watch: {},
}
</script>
<style scoped="scoped">
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>
<style>
.el-transfer-panel {
  width: 380px;
}
</style>