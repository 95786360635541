import request from '@/api/request'
import store from '@/store/index.js'

/**********************
 * 
 * 行政区划接口服务
 * 
 **********************/
//获取下级区划
export function getDivision(divCode) {
	return request({
		url: '/codeItem/divisions/' + divCode,
		method: 'get',
		headers: { 'Content-Type': 'application/json' }
	})
}

//获取当前区划
export function getDivisionByCode(divCode) {
	return request({
		url: '/codeItem/getDivisionByCode/' + divCode,
		method: 'get',
		headers: { 'Content-Type': 'application/json' }
	})
}

//从本地缓存里面获取行政区划信息
export function getDivisionByCodeFromStore(divCode) {
	return new Promise((reslove) => {
		let resultData = null;
		let divisionData = store.getters.divisionData;
		for (let i = 0; i < divisionData.length; i++) {
			if (divisionData[i].code == divCode) {
				resultData = divisionData[i];
				break;
			}
		}
		if (resultData) {
			reslove(resultData);
		} else {
			getDivisionByCode(divCode).then((response) => {
				if (response) {
					resultData = { "code": response.value, "name": response.name };
					store.dispatch("add_divisionData", resultData);
				}
				reslove(resultData);			
			}).catch(() => {
				reslove(null);
			});
		}

	});
}

//获取单位列表
export function getOrgByDivisionCode(divCode) {
	return request({
		url: '/organizations/getOrgByDivisionCode',
		method: 'get',
		params: { divisionCode: divCode },
		headers: { 'Content-Type': 'application/json' },
	})
}

/**********************
 * 
 * 数据元接口服务 已废弃
 * 
 **********************/
//数据元简单对象列表接口
export function getDataElementSimpleList(params) {
	return request({
		url: '/dataElement/listAllSimple',
		method: 'post',
		data: params,
		headers: { 'Content-Type': 'application/json' },
	})
}

//根据id查询数据元接口
export function getDataElementById(params) {
	return request({
		url: '/dataElement/getById',
		method: 'get',
		params: { id: params },
		headers: { 'Content-Type': 'application/json' },
	})
}

//根据identityNo查询数据元接口
export function getDataElementByIdentityNo(params) {
	return request({
		url: '/dataElement/getByIdentityNo',
		method: 'get',
		params: { account: params },
		headers: { 'Content-Type': 'application/json' },
	})
}


//根据多个identityNo查询数据元接口
export function getDataElementByIdentityNos(params) {
	return request({
		url: '/dataElement/getByIdentityNos', 
		method: 'get', params: { account: params }, 
		headers: { 'Content-Type': 'application/json' },
	})
}