<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>依赖包管理</h1>
      <span class="breadcrumb" align="right">
        <el-button
          type="primary"
          size="medium"
          plain
          @click.stop.prevent="downloadLib()"
          icon="el-icon-download"
          >下载</el-button
        >
        <el-button
          type="primary"
          size="medium"
          plain
          @click="toAdd"
          icon="el-icon-plus"
          >新增</el-button
        >
      </span>
    </section>
    <section class="content">
      <search-panel @search="search()">
        <search-field>
          <el-form-item label="依赖包名称：">
            <el-input v-model="params.packageName" />
          </el-form-item>
        </search-field>
      </search-panel>

      <span style="color: #888">
        共
        <span class="text-red">{{ result.total }}</span> 条符合查询条件
      </span>

      <el-table
        v-loading="loading"
        :data="result.tableData"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
      >
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">{{
            result.pageNumber * result.pageSize + scope.$index + 1
          }}</template>
        </el-table-column>
        <el-table-column prop="packageName" label="依赖包名称"></el-table-column>
        <el-table-column prop="packageVersion" label="依赖包版本"></el-table-column>
        <el-table-column prop="packageSize" label="依赖包大小" width="120">
          <template slot-scope="scope">
            <div>{{scope.row.packageSize}}K</div>
          </template>
        </el-table-column>
        <el-table-column prop="path" label="依赖包路径"></el-table-column>
        <el-table-column
          prop="lastModified"
          label="更新日期"
          align="center"
          width="160"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="singleDownload(scope.row.packageName)"
              >下载</el-button
            >
            <el-button type="text" @click="toInfo(scope.row.packageName)"
              >详情</el-button
            >
            <el-button type="text" @click="toDel(scope.row.packageName)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <list-pagination :total="result.total" @change="handleCurrentChange" />
    </section>
  </div>
</template>

<script>
import axios from "@/api/request";
import { getDepPackageList } from "@/api/deppackage.js";
import searchPanel from "@/components/list/SearchPanel.vue";
import searchField from "@/components/list/SearchField.vue";
import listPagination from "@/components/list/ListPagination.vue";

export default {
  components: {
    searchPanel,
    searchField,
    listPagination,
  },
  data() {
    return {
      params: {
        pageNumber: 0,
        pageSize: 10,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    };
  },
  methods: {
    search() {
      getDepPackageList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
      });
    },
    handleCurrentChange: function (val) {
      this.params.pageNumber = val - 1;
      this.search();
    },
    toAdd() {
      this.$router.push({
        name: "sys_admin_deppackage_add",
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_deppackage_info",
        params: { packageName: param },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_deppackage_del",
        params: { packageName: param },
      });
    },
    downloadLib() {
      axios({
        url: "/depPackage/downloadLib",
        method: "get",
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
      }).then(
        (response) => {
          if (!response) {
            this.$message.error("下载文件失败");
            return false;
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: "application/zip" })
          );
          // 文件地址
          const link = document.createElement("a");
          link.download = "lib.zip";
          link.href = objectUrl;
          link.click();
          URL.revokeObjectURL(link); // 释放内存
        },
        (err) => {
          if (err) this.$confirm("", "下载文件异常，请联系管理员！", {});
        }
      );
    },
    singleDownload(packageName){
      axios({
        url: "/depPackage/singleDownload",
        method: "get",
        headers: { "Content-Type": "application/octet-stream" },
        params: {jarName:packageName},
        responseType: "blob",
      }).then(
        (response) => {
          if (!response) {
            this.$message.error("下载文件失败");
            return false;
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: "application/jar" })
          );
          console.error("文件名："+JSON.stringify(objectUrl))
          // 文件地址
          const link = document.createElement("a");
          //文件名
          link.download = packageName+".jar";
          link.href = objectUrl;
          link.click();
          URL.revokeObjectURL(link); // 释放内存
        },
        (err) => {
          if (err) this.$confirm("", "下载文件异常，请联系管理员！", {});
        }
      );
    }
  },
  created() {
    this.search();
  },
  mounted() {},
};
</script>

<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>