<template>
  <div class="login-gb">
    <div class="login-form">
      <div class="login-title">抱歉，您的账号受限，请联系管理员处理。<br><router-link to="login">重新登录</router-link></div>
    </div>
    <div class="footer">
      <p>
        <span v-if="client_name">主办单位：{{client_name}}</span>
        <span v-if="support_name">技术支持：{{support_name}}</span>
      </p>
       <p v-if="bottom_login_browserTips">
          {{bottom_login_browserTips}}
    </p>
    <p v-else>
      为了获得更好的支持体验，建议您使用谷歌、火狐、IE9及以上版本、360极速模式等高级版本的浏览器。
    </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "thirdAuthFaild",
  data() {
    return {
       bottom_login_browserTips:window.APP_CONFIG.BOTTOM_LOGIN_BROWSERTIPS
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.app.title,
      client_name: (state) => state.app.config.client_name,
      support_name: (state) => state.app.config.support_name,
    }),
  },
  methods: {
  },
  mounted: function () {
  },
  destroyed() {
  },
};
</script>

<style scoped>
.login-gb {
  background: url("~@/assets/login-bg.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
}

.login-form {
  margin-top: 100px;
}

.login-form .login-title {
  width: 100%;
  height: 104px;
  margin-top: 150px;
  color: #fff;
  font-size: 32pt;
  text-align: center;
  font-weight: 600;
}

.login-form .form-item {
  position: relative;
  width: 380px;
  margin: 0 auto;
  padding-bottom: 16px;
}

.login-form .form-item i {
  color: #ececec;
  font-size: 16pt;
  padding: 13px 30px;
  position: absolute;
}

.login-form .form-item input {
  width: 308px;
  height: 48px;
  padding-left: 70px;
  border: 1px solid #ffffff4a;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  background-color: #ffffff4f;
  outline: none;
}

.login-form .form-item .verify-code i {
  color: #ececec;
  font-size: 16pt;
  padding: 13px 30px;
  position: absolute;
}

.login-form .form-item .verify-code input {
  width: 168px;
}

.login-form .form-item .verify-code img {
  padding-left: 20px;
  width: 140px;
  height: 48px;
  vertical-align: top;
}

.login-form .form-item button {
  width: 308px;
  height: 48px;
  border: 0px solid #fff;
  border-radius: 6px;
  font-size: 18px;
  color: #ffffff;
  background-color: #184093;
  text-align: center;
}

.login-gb .login-form a {
  color: #ffffff;
}

.login-form .form-item .error {
  color: red;
  font-size: 14pt;
}

.footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer span {
  margin: 0 16px;
}
</style>
