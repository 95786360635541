import request from '@/api/request'
import store from '@/store/index.js'

/**********************
 * 
 * shortLink接口服务
 * 
 **********************/
//创建短链接
export function createShortLink(params) {
    return request({
        baseURL:store.getters.config.shortLinkBaseURL,
        url: '/shortLinkBuilder/create',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//查询我的短链接
export function listMyShortLink(params) {
    return request({
        baseURL:store.getters.config.shortLinkBaseURL,
        url: '/shortLink/listMyShortLink',
        method: 'post',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
}

//删除短链接
export function deleteMyShortLinkById(params) {
    return request({
        baseURL:store.getters.config.shortLinkBaseURL,
        url: '/shortLink/deleteMyShortLinkById',
        method: 'get',
        params: { id: params },
        headers: { 'Content-Type': 'application/json' },
    })
  }
