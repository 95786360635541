<template>
  <div>
    <el-table
      v-loading="loading"
      :data="result.tableData"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" align="center" width="60">
        <template slot-scope="scope">{{result.pageNumber*result.pageSize+scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="taskName" label="任务名称"></el-table-column>
      <el-table-column prop="serverName" label="服务器名称"></el-table-column>
      <el-table-column prop="deployPath" label="部署基路径"></el-table-column>
      <el-table-column prop="runStatus" label="运行状态" width="80"></el-table-column>
      <el-table-column
        prop="lastModifiedDate"
        label="更新日期"
        align="center"
        :formatter="dateFormat"
        width="160"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="260">
        <template slot-scope="scope">
          <el-button type="text" @click="toInfo(scope.row.id)">详情</el-button>
          <el-button type="text" @click="toEdit(scope.row.id)">修改</el-button>
          <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
          <el-button type="text" @click="toImpl(scope.row.id)">部署</el-button>
          <el-button type="text" @click="toStart(scope.row.id)">启动</el-button>
          <el-button type="text" @click="toStop(scope.row.id)">停止</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue';
import { getImplementTaskList, checkTaskRunStatus } from "@/api/backend.js";
import { getServerMachineList } from "@/api/server.js";
import moment from "moment";

export default {
  name: "implementTaskList",
  components: {},
  props: {
    pmoduleID: {},
    pmoduleCode: {},
    pversionID: {},
  },
  data() {
    return {
      params: {
        backendModuleID: this.pmoduleID,
        pageNumber: 0,
        pageSize: 100,
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      serverMachines: {},
      loading: false,
      loadfailed: false,
    };
  },
  methods: {
    search() {
      getImplementTaskList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content;
        this.result.total = response.totalElements;
        this.result.pageNumber = response.number;
        this.result.pageSize = response.size;
        let machinesIds = new Set(this.result.tableData.map(task => task.serverMachineID));
        let machinesParams = Array.from(machinesIds);
        if(machinesParams.length > 0){
          getServerMachineList({idList:machinesParams}).then((response) => {
            this.serverMachines = response.content;
            this.result.tableData.forEach((task) => {
              this.serverMachines.forEach((server) => {
                if (server.id == task.serverMachineID) {
                  task.serverName = server.serverName;
                }
              });
            });
          });
        }
        
        this.result.tableData.forEach((task) => {
          checkTaskRunStatus(task.id).then((taskRunResponse) => {
            Vue.set(task,'runStatus',taskRunResponse.data.runStatus == "started"?"运行中":"已停止");
          });
        });
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_info",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_edit",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_del",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    toImpl(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_impl",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    toStart(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_start",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    toStop(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_stop",
        params: { id: param, moduleID: this.params.backendModuleID },
      });
    },
    dateFormat: function (row, column) {
      return moment(row[column.property]).format("YYYY-MM-DD HH:mm:ss");
    },
    
  },
  mounted() {
    this.search();
  },
  watch: {
    pmoduleID(newVal) {
      this.params.backendModuleID = newVal;
      //this.search();
    },
  },
};
</script>

