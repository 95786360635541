<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="backendConfigForm"
          :model="backendConfigForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：" prop="backendConfig.moduleCode">
                <el-input v-model="backendConfigForm.backendConfig.moduleCode" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：" prop="backendConfig.moduleName">
                <el-input v-model="backendConfigForm.backendConfig.moduleName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="配置文件代码：" prop="backendConfig.fileCode">
                <el-input v-model="backendConfigForm.backendConfig.fileCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配置文件名称：" prop="backendConfig.fileName">
                <el-input v-model="backendConfigForm.backendConfig.fileName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="版本号：" prop="backendConfig.version">
                <el-input v-model="backendConfigForm.backendConfig.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="版本标识：" prop="backendConfig.versionID">
                <el-input v-model="backendConfigForm.backendConfig.versionID" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态：" prop="backendConfig.status">
                <statusSelector v-model="backendConfigForm.backendConfig.status"></statusSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfigForm.backendConfig.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="内容：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="backendConfigForm.backendConfig.fileContent"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import {
  getBackendConfigById,
  editBackendConfig
} from "@/api/backend.js";
import statusSelector from "../../components/statusSelector";
export default {
  components: { statusSelector },
  data() {
    return {
      moduleID: "",
      dataId: "",
      titleName: "修改后端模块配置项",
      backendConfigForm: {
        backendConfig: {}
      },
      rules: {
        "backendConfig.moduleCode": [
          { required: true, message: "请输入模块代码", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-20位字符", trigger: "blur" }
        ],
        "backendConfig.moduleName": [
          { required: true, message: "请输入模块名称", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "请输入2-500位字符",
            trigger: "blur"
          }
        ],
        "backendConfig.fileCode": [
          { required: true, message: "请输入配置文件代码", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "请输入2-50位字符",
            trigger: "blur"
          }
        ],
        "backendConfig.fileName": [
          { required: true, message: "请输入配置文件名称", trigger: "blur" },
          { min: 2, max: 100, message: "请输入2-100位字符", trigger: "blur" }
        ],
        "backendConfig.version": [
          { required: true, message: "请输入配置版本号", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" }
        ],
        "backendConfig.status": [
          { required: true, message: "请选择状态", trigger: "blur" }
        ],
      }
    };
  },
  methods: {
    commit() {
      this.$refs["backendConfigForm"].validate(valid => {
        if (valid) {
          editBackendConfig(JSON.stringify(this.backendConfigForm)).then(
            response => {
              this.toInfo(response.id);
            },
            err => {
              if (err.response.status == 500) {
                this.$confirm(
                  err.response.data.data,
                  "修改后端模块配置异常",
                  {}
                );
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_info",
        params: { id: param,moduleID:this.moduleID }
      });
    }
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.dataId = this.$route.params.id;
    getBackendConfigById(this.dataId).then(response => {
      this.backendConfigForm.backendConfig = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {}
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>