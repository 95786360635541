<template>
  <!-- Main Sidebar Container -->
  <aside
    :class="[menu_style?'menu_sidebar':'main-sidebar']"
    class="main-sidebar"
    style="background-color:#fff"
    :style="{ height: clientHeight + 'px' }"
  >
    <div class="titleStyle" v-if="menu_style">
      <router-link :to="{ name: 'home' }">
        <el-popover
          placement="left-start"
          width="200"
          trigger="hover"
          :content="title"
          popper-class="rightBoxPopover"
        >
          <div class="sub-menus-bar-header" slot="reference">{{title}}</div>
        </el-popover>
      </router-link>
    </div>
    <vue-scroll :ops="ops">
      <el-menu :default-openeds="leftMenus.map((x) => x.code)">
        <el-submenu v-for="(g, gk) in leftMenus" v-bind:key="gk" :index="g.code">
          <template slot="title">
            <i :class="g.icon" style="margin-right: 5px" v-if="!disable_nav_icon"></i>
            <span>{{ g.name }}</span>
          </template>
          <router-link
            :to="{ name: t.route, query: t.query }"
            v-for="(t, tk) in Object.values(g.children).filter((x) => isVisible(x))"
            v-bind:key="tk"
          >
            <el-menu-item :index="t.route" :class="{ isActive: isActived(t) }">
              <i :class="t.icon" v-if="!disable_nav_icon"></i>
              {{ t.name }}
            </el-menu-item>
          </router-link>
        </el-submenu>
      </el-menu>
    </vue-scroll>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import { getConfig } from "@/api/desktop.js";
export default {
  name: "Sidebar",
  data: function() {
    return {
      isCollapse: false,
      clientHeight: window.innerHeight - 10,
      ops: {
        rail: {
          gutterOfSide: "0px"
        },
        bar: {
          background: "#ccc",
          size: "5px",
          minSize: 0.1
        }
      },
      key2: 1
    };
  },
  computed: {
    leftMenus() {
      let activeTop = Object.values(this.menus).find(x => this.isActived(x));
      return activeTop
        ? Object.entries(activeTop.children)
            .filter(x => this.isVisible(x[1]))
            .map(x => Object.assign(x[1], { code: x[0] }))
        : [];
    },
    ...mapState({
      menus: state => state.app.menus,
      appId: state => state.appModules.appId,
      disable_nav_icon: state =>
        state.app.config && state.app.config.disable_nav_icon,
      roles: state =>
        state.user && state.user.userdata && state.user.userdata.roles,
      title: state => state.app.title,
      rolePermissions: state =>
        state.user &&
        state.user.userdata &&
        state.user.userdata.rolePermissionVos,
      menu_style: state => state.app.config && state.app.config.menu_style //第二种菜单展现方式
    })
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    menuClick(gk) {
      this.currentGroup = gk;
    },
    toggleGroup(key) {
      this.activeMenus[key] = !this.activeMenus[key];
    },
    isVisible(menu) {
      if (!menu.roles && !menu.rolePermissions) {
        return true;
      }
      // 在本级节点设置了权限
      if (menu.roles || menu.rolePermissions) {
        if (
          menu.roles &&
          this.roles &&
          menu.roles.some(x => new Set(this.roles.map(y => y.code)).has(x))
        ) {
          return true;
        }
        if (
          menu.rolePermissions &&
          this.rolePermissions &&
          menu.rolePermissions.some(x =>
            new Set(
              this.rolePermissions.map(y => y.target + ":" + y.action)
            ).has(x)
          )
        ) {
          return true;
        }
      }
      // 含有子菜单，判断至少有一个可见的子菜单
      if (menu.children) {
        if (Object.values(menu.children).some(x => this.isVisible(x))) {
          return true;
        }
      }

      return false;
    },
    isActived(menu, key) {
      if (this.currentGroup && key) {
        this.activeMenu1 = menu.route;
        return this.currentGroup === key;
      }
      if (menu.route === this.$route.name) {
        if (menu.query) {
          //路由菜单带参数，必须参数完全匹配
          let t_match = true;
          Object.entries(menu.query).forEach(s_query => {
            const t_query = this.$route.query[s_query[0]];
            if ("" + s_query[1] !== "" + t_query) {
              t_match = false;
            }
          });
          if (t_match) {
            this.activeMenu1 = menu.route;
            return true;
          }
        } else {
          //路由菜单不带参数
          this.activeMenu1 = menu.route;
          return true;
        }
      }
      if (
        this.$route.meta &&
        this.$route.meta.nav_key &&
        this.$route.meta.nav_key == menu.route
      ) {
        this.activeMenu1 = menu.route;
        return true;
      }

      return (
        menu.children &&
        Object.values(menu.children).some(x => this.isActived(x))
      );
    },
    async loaddata() {
      let configs = (await getConfig(this.appId, "desktop_menus")) || [];
      let devConfig = configs.find(x => x.level === "DEV");
      if(devConfig){
        let configValue = devConfig.value;
        let obj = JSON.parse(configValue);
        delete obj.level;
        var menusJson = JSON.stringify(obj, null, 4);
        this.$store.commit("set_menus", {
          type: true,
          menusJson: JSON.parse(menusJson)
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
// 第二种菜单的样式
.menu_sidebar {
  padding-top: 0;
  left: 80px;
}
.titleStyle {
  height: 60px;
  border-bottom: 1px solid #f1f1f1;
  text-align: center;
  padding-top: 15px;
  padding-left: 10px;
  div {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// .app-logo {
//   display: block;
//   height: 100px;
// }
// 第一种菜单的样式
.skin-blue-light .sidebar-menu li.active > a {
  background-color: #dce7eba6;
  border-left-color: blue;
}
.skin-blue-light .sidebar-menu .treeview-menu > li.active > a {
  background-color: #81acc5;
  color: white;
}
.el-menu {
  border-right: none !important;
}
.el-menu-item {
  color: #444;
}
.el-menu-item.isActive {
  background-color: #ecf5ff;
  color: #409eff;
}
.el-menu-item.is-active {
  color: #444;
}
.el-aside {
  display: block;
  position: relative;
  overflow-y: scroll;
  background-color: #324157 !important;
}
</style>
<style>
/* 第二种菜单的样式 */
.el-popover .rightBoxPopover {
  height: 50px;
  font-size: 16px;
  padding: 12px !important;
}
</style>
