<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}{{backendModule.moduleCode}}</h1>
    </section>

    <section class="content">
      <el-main style="background: #fff;">
        <p class="bg-info" style="padding:15px">
          1、目前仅支持在linux服务器部署，且需确保linux系统支持unzip命令。<br />
          2、请确保服务器{{serverMachine.serverIP}}能正常远程连接，点击
          <el-button
            style="padding:0px"
            type="text"
            @click="testConnect()"
            :loading="testButton.loading"
          >【{{testButton.test}}】</el-button>服务器连接情况。
          <br />
          3、系统会先删除{{backendModule.moduleCode}}服务器{{implementTask.deployPath}}路径的所有文件，然后重新部署。<br />
          4、系统自动将{{backendModule.moduleCode}}的jar、配置文件、启动工具等打包部署到服务器{{serverMachine.serverIP}}的{{implementTask.deployPath}}路径下。
        </p>
        <div id="moduleFileList">
          <div v-html="moduleFileList"></div>
        </div>
        <div align="center">
          <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">返回</el-button>
          <el-button type="danger" icon="el-icon-upload2" @click="commit()">部署</el-button>
        </div>
      </el-main>
    </section>
  </div>
</template>

<script>
import {
  deployModuleFile,
  getImplementTaskById,
  getBackendModuleById,
} from "@/api/backend.js";
import { getServerMachineById, testConnect } from "@/api/server.js";
export default {
  data() {
    return {
      titleName: "实施部署",
      dataId: "",
      moduleID: "",
      implementTask: {},
      backendModule: {},
      serverMachine: {},
      testButton: {
        test: "测试",
        loading: false,
      },
      successContent: "",
      moduleFileList: "",
    };
  },
  created() {
    this.dataId = this.$route.params.id;
    this.moduleID = this.$route.params.moduleID;
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendModule = response;
    });
    let taskPromise = new Promise((resolve) => {
      getImplementTaskById(this.dataId).then((response) => {
        this.implementTask = response;
        resolve(this.implementTask.serverMachineID);
      });
    });
    taskPromise.then((serverMachineID) => {
      getServerMachineById(serverMachineID).then((response) => {
        this.serverMachine = response;
      });
    });
  },
  methods: {
    commit() {
      const loading = this.$loading({
        lock: true,
        text: "正在部署，请稍等......",
        spinner: "el-icon-loading",
      });
      deployModuleFile(this.dataId).then(
        (response) => {
          loading.close();
          if (response.meta.code == 1000) {
            this.$message.success({
              message: "恭喜，部署成功！",
            });
            this.moduleFileList =
              '<p class="bg-success" style="padding:15px">' +
              "在服务器" +
              this.serverMachine.serverIP +
              "的" +
              this.implementTask.deployPath +"/" + this.backendModule.moduleCode + "/" + this.implementTask.deployPort + "_" + this.backendModule.version + "_" + this.backendModule.versionID +
              "路径下部署了以下内容："+
            response.data.result.replace(/\n/g, "<br />") + "</p>";
          }
        },
        (err) => {
          loading.close();
          if (err.response.status == 500) {
            this.$message.error("部署失败了，请查看后端日志检查问题！");
          }
        }
      );
    },
    testConnect() {
      this.testButton.test = "测试中......";
      this.testButton.loading = true;
      testConnect(this.serverMachine.id)
        .then(
          (response) => {
            this.$alert(response.data.data, "服务器连接正常", { center: true });
          },
          (err) => {
            if (err) {
              this.$alert(
                " 确保服务器的IP、账号、密码和网络正常。",
                "服务器连接异常",
                {
                  type: "warning",
                  center: true,
                }
              );
            }
          }
        )
        .finally(() => {
          this.testButton.test = "测试";
          this.testButton.loading = false;
        });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_info",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID, tabName: "taskInfo" },
      });
    },
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>