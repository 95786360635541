<template>
  <div class="content-wrapper" :class="[ home_style? 'desktop' : '' ]"  >
    <!-- Main content -->
    <section class="content">
      <LayoutContainer :model="layout"></LayoutContainer>
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Vue from 'vue'
import axio from '@/api/request'
import { getConfig } from '@/api/desktop.js'
import LayoutContainer from './LayoutContainer'
import {mapState, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: { LayoutContainer },
  data() {
    return {
      empty: {},
      layout: {},
    }
  },
  computed: {
    ...mapGetters(['appId']),
     ...mapState({
      home_style: state => state.app.config && state.app.config.home_style
    }),
  },
  methods: {
    get_layout(name, root) {
      let r = root || this.layout
      if (r.id === name) {
        return r
      } else {
        if (r.children) {
          let result = null
          r.children.find((c) => {
            result = result || this.get_layout(name, c)
          })
          return result
        }
      }
    },
    reset_layout() {
      let layout = this.layout
      let modules = this.modules || []
      modules.forEach((m) => {
        if (typeof m.webparts === 'function') {
          m.install(Vue, this)
          let webparts = m.webparts()
          webparts.forEach((w) => {
            let p = this.get_layout(w.parent, layout)
            if (p && !p.components.some((x) => x.name == w.name)) {
              p.components.push(w)
            }
          })
        }
      })
      this.layout = layout
      this.$store.commit('set_layout', { layout })
    },
    loaddata_bk() {
      this.loaded = false
      axio({
        url: '/desktop/layout',
      }).then((res) => {
        this.empty = res
        this.layout = res
        this.reset_layout()
        this.loaded = true
      })
    },
    async loaddata() {
      this.loaded = false
      let configs = (await getConfig(this.appId, 'desktop_layout')) || []
      let devConfig = configs.find((x) => x.level === 'DEV')
      if (devConfig) {
        let configValue = devConfig.value
        this.empty = JSON.parse(configValue)
      } else {
        this.empty = await axio({ url: '/desktop/layout' })
      }
      this.layout = this.empty
      this.reset_layout()
      this.loaded = true
    },
  },
  mounted() {
    if (this.$store.state.app.inited) {
      this.loaddata()
    }
  },
  watch: {
    '$store.state.app.inited': function (n) {
      if (n) {
        this.$nextTick(() => {
          this.loaddata()
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.desktop {
  min-height: 10.5rem !important;
  background-color: #f4f4f4 !important;
}
.content:after{
    display: block;
    clear: both;
    content: "";
}
</style>
