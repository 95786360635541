<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>模块参数配置</h1>
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style="padding:0">
            <el-radio-group v-model="mode" size="small" @change="selectMode">
              <el-radio-button v-for="(m, mk) in modes" :key="mk" :label="m.code">{{m.name}}</el-radio-button>
            </el-radio-group>
            <el-button
              type="primary"
              size="small"
              class="pull-right"
              @click="saveAll"
              :disabled="!somethingChanged"
            >保存</el-button>
            <el-button
              size="small"
              class="pull-right"
              @click="loaddata"
              :disabled="!somethingChanged"
            >取消</el-button>
            <el-button size="small" class="pull-right" @click="toBack">返回</el-button>
            <el-card class="box-card">
              <el-tabs v-model="activeTab" tabPosition="left">
                <el-tab-pane :label="mk" :name="mk" v-for="(m, mk) in list" :key="mk">
                  <el-alert
                    :closable="false"
                    show-icon
                    v-if="Object.keys(m.meta).length === 0"
                    title="当前模块无可配置参数。"
                    type="info"
                  ></el-alert>
                  <el-form
                    :model="m.config"
                    :rules="rules"
                    size="small"
                    label-position="right"
                    label-width="150px"
                  >
                    <el-form-item label="appId：" prop="appId">
                      <el-input v-model="appId" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item :label="key" v-for="(meta, key) in m.meta" :key="key">
                      <el-color-picker v-model="m.config[key]" v-if="meta.type === 'color'"></el-color-picker>
                      <el-switch
                        v-model="m.config[key]"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        v-else-if="meta.type === 'boolean'"
                        :active-text="meta.activeText"
                        :inactive-text="meta.inactiveText"
                      ></el-switch>
                      <el-input
                        v-model="m.config[key]"
                        v-else
                        :placeholder="m.config[key] === '' ? '注意！当前值为空值，如要清空请按最右边按钮！': '<未设置>'"
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-delete"
                          @click="deleteField(mk, key)"
                        ></el-button>
                      </el-input>
                      <br />
                      <span class="module-config-hits">{{meta.description}}</span>
                      <p>
                        <strong>默认值：</strong>
                        <span
                          class="module-config-hits"
                        >{{m.defaults[key] == null ? '无' : m.defaults[key]}}</span>
                      </p>
                      <el-alert
                        :closable="false"
                        show-icon
                        v-if="m.config[key] === ''"
                        title="注意：当前值为“空串”，如想清空该值请点击最后边的删除按钮。"
                        type="warning"
                      ></el-alert>
                      <!-- 这是新增的菜单选项配置 -->
                      <!-- <el-select v-model="m.config[key]" placeholder="请选择" v-if="meta.type === 'boolean'">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>-->
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/api/request";
import systemManageModule from "@/module.js";
import { getAppById, getAppModuleListPublic } from "@/api/appModule.js";
import { load_module_js } from "@/api/moduleLoader.js";
import { mapState } from "vuex";
export default {
  name: "ModuleConfig",
  data() {
    return {
      activeTab: "",
      list: {},
      changeList: {},
      unwatchs: [],
      somethingChanged: false,
      mode: "",
      lastMode: "",
      all_modes: [
        { code: "dev", name: "开发参数", roles: ["XI_TONG_GUAN_LI_YUAN"] },
        { code: "pro", name: "产品参数", roles: ["XI_TONG_GUAN_LI_YUAN"] },
        {
          code: "deploy",
          name: "部署参数",
          roles: ["XI_TONG_GUAN_LI_YUAN", "SHI_SHI_REN_YUAN"]
        }
      ],
      appModules: [],
      dataId: "", //app的物理id
      appId: "",
      rules: {
        appId: [{ required: true, message: "appId不能为空", trigger: "blur" }]
      }
    };
  },
  computed: {
    modes() {
      return this.all_modes.filter(x => this.visiable(x));
    },
    ...mapState({ currentAppId: state => state.appModules.appId })
  },
  methods: {
    visiable(mode) {
      if (!mode.roles) return true;
      let roles = this.$store.getters.userdata.roles || [];
      return mode.roles.some(x => roles.some(r => r.code === x));
    },
    clearChanges() {
      this.unwatchs.forEach(x => {
        x();
      });
      this.unwatchs = [];
      this.changeList = {};
      this.somethingChanged = false;

      // 监控对象变化，
      //let list = this.modules || [];
      let list = this.appModules || [];
      list.forEach(x => {
        this.unwatchs.push(
          this.$watch(
            () => {
              return this.list[x.package.name]["config"];
            },
            () => {
              if (typeof this.changeList[x.package.name] === "undefined") {
                this.changeList[x.package.name] = "init";
              } else {
                this.changeList[x.package.name] = "changed";
                this.somethingChanged = true;
              }
            },
            {
              deep: true
            }
          )
        );
      });
    },
    loaddata() {
      const loading = this.$loading({
        lock: true,
        text: "需要加载多个前端模块的文件，请稍等......",
        spinner: "el-icon-loading"
      });
      //let list = this.modules || [];
      //根据appId查询出对应的前端模块，没有模块版本
      let p_appModules = new Promise(resolve => {
        getAppModuleListPublic(JSON.stringify({ appId: this.appId })).then(
          response => {
            let appModules = response;
            resolve(appModules);
          }
        );
      });
      //查询出所有的前端模块json数据，里面主要包括模块代码和模块版本
      let p_allModules = new Promise(resolve => {
        axios({
          url: "/module/all.json"
        }).then(res => {
          resolve(res);
        });
      });
      //通过比对获取appId对应的前端模块的核心信息，为了获取模块代码和模块版本
      Promise.all([p_appModules, p_allModules])
        .then(result => {
          let t_appModules = result[0];
          let t_allModules = result[1];
          let t_list = [];
          t_allModules.forEach(t_module => {
            if (
              t_appModules.some(item => {
                return (
                  item.moduleCode == t_module.name &&
                  (item.moduleVersion == t_module.version ||
                    !item.moduleVersion)
                );
              })
            ) {
              t_list.push(t_module);
            }
          });
          return t_list;
        })
        .then(moList => {
          let moPromises = [];
          //根据模块代码和模块版本获取模块js，这里读取js会很慢,暂无优化方案(保留原有实现方式，读前端配置需要加载整个js，是否合理？)
          moList.forEach(mo => {
            let moPromise = new Promise(resolve => {
              axios({
                url: `/module/${mo.name}-${mo.version}/${mo.name}.umd.min.js`,
                cache: process.env.VUE_APP_CACHE_MODULE !== "false",
                params: {
                  v: mo.id
                }
              }).then(res => {
                let m = load_module_js(mo, res);
                resolve(m);
              });
            });
            moPromises.push(moPromise);
          });
          //获取每个模块的前端配置
          Promise.all(moPromises).then(result => {
            this.appModules = result;
            this.appModules.push(systemManageModule); //添加desktop-web系统模块前端模块
            this.list = this.appModules.reduce((pre, x) => {
              let { name, version, description } = x.package;
              let config = {};
              let meta = (x.config && x.config[this.mode]) || {};
              let defaults =
                (x.config && x.config[`${this.mode}_defaults`]) || {};
              let result = {
                name,
                version,
                description,
                config,
                meta,
                defaults
              };
              axios({
                url: "/plugin/get_config",
                params: {
                  name: name,
                  appId: this.appId
                }
              }).then(res => {
                let c = res.find(x => x.level.toLowerCase() === this.mode);
                result.config = (c && c.value && JSON.parse(c.value)) || {};
              });
              pre[name] = result;
              return pre;
            }, {});
            loading.close();
            this.clearChanges();
            this.initDefaultTab();
          });
        });
    },
    saveAll() {
      let saves = [];
      Object.entries(this.changeList).forEach(k => {
        if (k[1] === "changed") {
          let m = this.list[k[0]];
          saves.push(this.save(m));
        }
      });
      Promise.all(saves).then(() => {
        //菜单的宽度变化
        this.clearChanges();
        //当前的AppId跟修改的appId，相同才能改变宽度
        if (this.currentAppId === this.appId) {
          if (this.appModules[2].config.pro_defaults.menu_style) {
            sessionStorage.setItem("activeIcon", JSON.stringify(true));
            sessionStorage.setItem("isCollapse", JSON.stringify(true));
            sessionStorage.setItem(
              "marginLeft",
              JSON.stringify("310px !important")
            );
          } else {
            sessionStorage.setItem(
              "marginLeft",
              JSON.stringify("230px !important")
            );
          }
        }
      });
    },
    save(m) {
      return new Promise((resolve, reject) => {
        let c = {};
        Object.keys(m.meta).forEach(k => {
          c[k] = m.config[k];
        });
        console.log(c);
        axios({
          url: `/plugin/save_${this.mode}_config`,
          params: {
            name: m.name,
            appId: this.appId
          },
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          data: JSON.stringify(c)
        })
          .then(() => {
            console.log(`模块参数 ${m.name} 保存成功.`);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    initMode() {
      let mode =
        (this.$route && this.$route.query && this.$route.query.mode) ||
        this.modes[0].code;
      this.mode = this.modes.some(x => x.code === mode)
        ? mode
        : this.modes[0].code;
      this.lastMode = this.mode;
    },
    initDefaultTab() {
      let name = this.$route && this.$route.query && this.$route.query.name;
      if (name && Object.keys(this.list).some(x => x.name === name)) {
        this.activeTab = name;
        return;
      } else if (this.list && Object.keys(this.list).length > 0) {
        this.activeTab = Object.keys(this.list)[0];
      }
    },
    deleteField(mk, key) {
      let n = {};
      let o = this.list[mk].config;
      Object.entries(o).forEach(x => {
        if (x[0] !== key) {
          n[x[0]] = x[1];
        }
      });
      this.list[mk].config = n;
    },
    selectMode(n) {
      if (this.somethingChanged) {
        this.$confirm("您已修改了配置项但未保存，点击确认将放弃未保存的修改？")
          .then(() => {
            this.loaddata();
            this.lastMode = n;
          })
          .catch(() => {
            this.mode = this.lastMode;
          });
      } else {
        this.loaddata();
        this.lastMode = n;
      }
    },
    selectTab() {},
    toBack() {
      this.$router.push({
        name: "sys_admin_app_info",
        params: { id: this.dataId },
        query: { id: this.dataId }
      });
    }
  },
  mounted() {
    this.dataId = this.$route.query.id || this.$route.params.id;
    let appPromise = new Promise(resolve => {
      getAppById(this.dataId).then(response => {
        this.appId = response.appId;
        resolve(null);
      });
    });
    appPromise.then(() => {
      this.initMode();
      this.loaddata();
    });
  }
};
</script>
<style>
.module-config-hits {
  color: gray;
}
</style>
