import moduleRoutes from './router/systemManageRoutes'
import moduleMenus from './router/systemManageMenus'
import appConfig from '../package.json'
// import desktop_todo from './views/components/desktop_todo'
import desktop_bookmark from './views/components/desktop_bookmark'
import desktop_message from './views/components/desktop_message'
import request from './api/request'
import authRequest from './api/auth'
import moduleConfig from './utils/config'

const components = [desktop_bookmark, desktop_message]
// const components = [desktop_todo, desktop_bookmark, desktop_message];

/**
 * 整合待办模块
 * 返回一个无参数方法，该方法在工作台首页时调用，返回一个数组，指定显示在工作台待办列表的项目。
 * name：为待办项目的名称
 * value：为待办数
 * view：为点击该项后跳转的页面路由
 * roles：为角色，如果设定值则当前用户必须拥有该角色才会显示（可选）
 * params：为传递给路由的参数（可选）。
 */
const todo = () => {
	// 以下为模拟数据。实际应用中应该调用一个ajax方法从后端读取当前用户的待办项。
	// return [
	//   { name: "目录管理", value: "41", view: "sys_admin-module_list", params: { type: "padding" } },
	//   { name: "任务填报", value: "21", view: "sys_admin-module_list", roles: ["SHI_SHI_REN_YUAN"] },
	//   { name: "数据收取", value: "31", view: "sys_admin-module_list" }];

	return []
}

/**
 * 整合常用功能模块
 * 返回一个无参数方法，该方法在工作台首页时调用，返回一个数组，指定显示在工作台常用功能列表的项目。
 * name：为常用功能按钮的名称
 * view：为点击该项后跳转的页面路由
 * roles：为角色，如果设定值则当前用户必须拥有该角色才会显示（可选）
 * params为传递给路由的参数（可选）
 */
var bookmarkList = []

const bookmark = () => {
	return bookmarkList
}

/**
 * 注册首页webpart
 * 返回一个无参数方法，该方法在工作台首页时调用，返回一个数组，在首页指定的布局中加载webpart。
 * parent：所在的布局名称
 * name：webpart名称
 * roles：为角色，如果设定值则当前用户必须拥有该角色才会显示（可选）
 */
const webparts = () => {
	return [
		// { parent: "left", name: "desktop_todo" },
		// { parent: 'right', name: 'desktop_bookmark' }
	]
}

/**
 * 模块安装脚本
 */
const install = function (Vue, app) {
	components.map((component) => {
		// console.log('install component', component.name)
		return Vue.component(component.name, component)
	})

	// 当入参app非空时（app指向主框架的Vue组件实例），以下代码设定如何把模块注册到主框架
	if (app) {
		// 合并路由（在主框架下，主框架约定第一个路由为主框架路由，在该路由下的子路页面将嵌入到主框架中）
		let mainRoute = app.$router.options.routes[0]
		mainRoute.children.push(...moduleRoutes)
		app.$router.addRoutes([mainRoute])

		// 合并菜单
		app.$store.commit('set_menus', {
			menus: moduleMenus
		})
	}
}

let {
	name,
	version
} = appConfig

// 模块配置项
const config = {
	dev: {
		base_url: {
			description: '后端接口地址。',
			type: 'string'
		},
		adminBaseURL: {
			description: '运行管理后端接口地址。',
			type: 'string'
		},
		noframe: {
			description: '隐藏主框架，只保留main内容，用于作为frame嵌入到其它系统中。',
			activeText: '隐藏主框架',
			inactiveText: '显示主框架',
			type: 'boolean'
		},
		initModules: {
			description: '在首次加载页面时必须加载的模块清单，如有多个以逗号分隔开。当设置该值时，优先加载清单中的模块，然后加载工作台，其它剩余的模块会通过异步方式加载。保持向后兼容，如果不设置该值，则所有模块都会同步加载，最后才加载工作台。',
			type: 'string'
		}
	},
	pro: {
		app_name: {
			description: '系统名称。',
			type: 'string'
		},
		app_name_url: {
			description: '系统名称跳转的路由',
			type: 'string'
		},
		top_menu_sort: {
			description: '主菜单的显示顺序。填写菜单的中文名称（必须完全一致），并且用半角逗号分隔。其中“系统首页”固化在最左边，不参与排序。没有设置的菜单放在最后。菜单项支持修改名称和图标，以半角分号隔开。例子：“菜单A:新菜单名:icon-a”，这样配置将把原始名为“菜单A”显示为“新菜单名”，并且使用名为“icon-a”的系统图标。',
			type: 'string'
		},
		max_main_menus: {
			description: '最多显示多少个主菜单（“首页和最后的用户图标不计算在内”），当超过配置值时，多出的菜单收到更多图标内，下拉再显示。',
			type: 'string'
		},
		top_menu_bg: {
			description: '顶部主菜单背景颜色。',
			type: 'color'
		},
		top_menu_bg_active: {
			description: '顶部主菜单选中时菜单背景颜色。',
			type: 'color'
		},
		top_menu_bg_hover: {
			description: '顶部主菜单鼠标进入时菜单背景颜色。',
			type: 'color'
		},
		main_bg: {
			description: '主面板背景颜色。',
			type: 'color'
		},
		disable_nav_icon: {
			description: '禁用左侧栏菜单图标',
			activeText: '禁用',
			inactiveText: '启用',
			type: 'boolean'
		},
		webparts: {
			description: '控制首页webpart对哪些角色可见。不推荐直接修改此配置项，可用t_dc（固化）登录后在首页有编辑功能。',
			type: 'string'
		},
		menu_style: {
			description: '控制一级菜单展示，true表示左侧展示一级菜单，false表示顶部展示一级菜单。',
			activeText: '禁用',
			inactiveText: '启用',
			type: 'boolean'
		},
		home_style: {
			description: '控制首页的样式',
			activeText: '禁用',
			inactiveText: '启用',
			type: 'boolean'
		},
		notuse_default_home: {
			description: '是否不展示系统默认首页',
			activeText: '不展示系统默认首页',
			inactiveText: '展示系统默认首页',
			type: 'boolean'
		},
		personal_home_roule: {
			description: '自定义首页路由名称。',
			type: 'string'
		},
	},
	deploy: {
		bookmarks: {
			description: '常用功能。格式为：[{"name":"名称", "href":"地址"}]',
			type: 'string'
		},
		client_name: {
			description: '显示在登录页下方的主办单位名称',
			type: 'string'
		},
		support_name: {
			description: '显示在登录页下方的技术支持名称',
			type: 'string'
		},
		
		loginTimeOut: {
			description: '设置用户登录后的超时时间，用户在界面超时无点击操作会登录页，单位毫秒，不设置则不启动该超时机制',
			type: 'string'
		},
		authBaseURL: {
			description: '认证服务后端接口地址。默认以config.js的VUE_APP_AUTH_URL值准，如果这里配置则以这里为准。',
			type: 'string'
		},
		shortLinkBaseURL: {
			description: '短链接后端接口地址。',
			type: 'string'
		},
		showShortLink: {
			description: '是否需要短链',
			type: 'boolean'
		},
		logoutDisabled: {
			description: '是否不显示退出系统按钮。无或关表示显示，开表示不显示。',
			type: 'boolean'
		},
		noticeTipsSwitch: {
			description: '通知公告提醒服务的开关，打开时需要设置adminBaseURL',
			type: 'boolean'
		},
		logoutURL: {
			description: '用户点退出后跳转的地址，业务应用系统可以指定登录地址',
			type: 'string'
		},
		icp_num: {
			description: '网站备案号。',
			type: 'string'
		},
		showRegist: {
			description: '是否支持注册',
			type: 'boolean'
		},
		desktop_inner_url: {
			description: '工作台内网接口地址。',
			type: 'string'
		},
		check_time_rate: {
			description: '只允许用户单客户端登录，会话是否有效检测时间频率，单位毫秒，不设置则不启动用户单客户端限制机制（若配置的是空字符串，则默认值为30000毫秒）',
			type: 'string'
		},

	},
	dev_defaults: {
		noframe: false,
		base_url: '/desktop-api/',
		adminBaseURL: '/admin-api/',
	},
	pro_defaults: {
		app_name: '',
		app_name_url: 'home',
		disable_nav_icon: true,
		max_main_menus: '999',
		menu_style: true,
		home_style: true,
		notuse_default_home: false,
	},
	deploy_defaults: {
		support_name: '广州市中智软件开发有限公司',
		shortLinkBaseURL: '/sl/',
		showShortLink: false,
		showRegist: false,
		
	},
	handler(config, vue) {
		const isProduction = process.env.NODE_ENV === 'production';
		if (isProduction && config.base_url) {
			request.defaults.baseURL = config.base_url
		}

		if (config.app_name) {
			vue.$store.commit('set_title', {
				title: config.app_name
			})
		}

		if (config.top_menu_sort) {
			vue.$store.commit('set_top_menu_sort', {
				sort: config.top_menu_sort
			})
		}

		if (config.bookmarks) {
			try {
				bookmarkList = JSON.parse(config.bookmarks)
			} catch {
				console.error('常用工具配置错有误', config.bookmarks)
			}
		}
		if (config.authBaseURL) {
			authRequest.baseURL = config.authBaseURL;
		}

		if (!config.adminBaseURL) {
			config.adminBaseURL = this.dev_defaults.adminBaseURL;
		}

		if (!config.shortLinkBaseURL) {
			config.shortLinkBaseURL = this.deploy_defaults.shortLinkBaseURL;
		}

		if (config.logoutURL) {
			moduleConfig.VUE_APP_CUSTOM_LOGOUT_URL = config.logoutURL
		}

		let showShortLink = config.showShortLink
		vue.$store.commit('set_showShortLink', {
			showShortLink
		})

		let noframe = !!config.noframe
		vue.$store.commit('set_noframe', {
			noframe
		})
		vue.$store.commit('set_config', {
			config
		})

		let showRegist = config.showRegist
		vue.$store.commit('set_showRegist', {
			showRegist
		})
		//是否不展示系统默认首页
		let notUseDefaultHomePage = config.notuse_default_home
		vue.$store.commit('set_notUseDefaultHomePage', {
			notUseDefaultHomePage
		})
		//个性化首页路由名称
		if (config.personal_home_roule) {
			vue.$store.commit('set_homePageRoule', {
				homePageRoule: config.personal_home_roule
			})
		}
        // 设置左上角标题跳转的路由
		if (!config.app_name_url) {
			config.app_name_url = this.pro_defaults.app_name_url;
		}
	}
}

export default {
	install,
	webparts,
	todo,
	bookmark,
	package: {
		name,
		version,
		description: '工作台内置模块'
	},
	config
}