<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>后端模块配置管理</h1>
      <span class="breadcrumb" align="right">
        <el-button type="primary" size="medium" plain @click="toAdd" icon="el-icon-plus">新增</el-button>
      </span>
    </section>
    <section class="content">
      <search-panel @search="search()">
        <!--
        <search-field>
          <el-form-item label="模块代码：">
            <el-input v-model="params.moduleCode" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="模块名称：">
            <el-input v-model="params.moduleName" />
          </el-form-item>
        </search-field>
        -->
        
        <!--
        <search-field>
          <el-form-item label="版本标识：">
            <el-input v-model="params.id" />
          </el-form-item>
        </search-field>
        -->
        <search-field>
          <el-form-item label="所属模块：">
            <el-select v-model="params.moduleCode" filterable :clearable="true" placeholder="请选择模块" @change="moduleCodeChange">
              <el-option v-for="item in moduleCodes" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="模块类型：">
            <typeSelector v-model="params.type"></typeSelector>
          </el-form-item>
        </search-field>
        
        <search-field>
          <el-form-item label="版本号：">
            <el-input v-model="params.version" />
          </el-form-item>
        </search-field>
        <search-field>
          <el-form-item label="是否在用：">
            <enabledSelector v-model="params.enabled" placeholder="请选择"></enabledSelector>
          </el-form-item>
        </search-field>
      </search-panel>
        <template >
            <span style="color: #888">
              共
              <span class="text-red">{{result.total}}</span> 条符合查询条件
            </span>

            <el-table v-loading="loading" :data="result.tableData" element-loading-text="Loading" border fit highlight-current-row>
              <el-table-column label="序号" align="center" width="60">
                <template slot-scope="scope">{{result.pageNumber*result.pageSize+scope.$index+1}}</template>
              </el-table-column>
              <el-table-column prop="moduleCode" label="模块代码"></el-table-column>
              <el-table-column prop="moduleName" label="模块名称"></el-table-column>
              <el-table-column prop="version" label="版本号"></el-table-column>
              <el-table-column prop="id" label="版本标识"></el-table-column>
              <el-table-column prop="type" label="模块类型" width="100"></el-table-column>
              <el-table-column prop="enabled" label="是否在用" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.enabled == true">在用</span>
                  <span v-else-if="scope.row.enabled == false">备案</span>
                </template>
              </el-table-column>
              <el-table-column prop="lastModifiedDate" label="更新日期" align="center" :formatter="dateFormat" width="160"></el-table-column>
              <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                  <el-button type="text" @click="toInfo(scope.row.id)">详情</el-button>
                  <el-button type="text" @click="toEdit(scope.row.id)">修改</el-button>
                  <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
                  <el-button type="text" @click="toDownloadFullApp(scope.row.id,scope.row.moduleCode+'-'+scope.row.version)">下载</el-button>
                  <el-button type="text" @click="toCopy(scope.row.id)">复制</el-button>
                </template>
              </el-table-column>
            </el-table>
            <list-pagination :total="result.total" @change="handleCurrentChange" />
        </template>
    </section>
  </div>
</template>

<script>
import axios from '@/api/request'
import { getBackendModuleList, getModuleCodes } from '@/api/backend.js'
import moment from 'moment'
import searchPanel from '@/components/list/SearchPanel.vue'
import searchField from '@/components/list/SearchField.vue'
import listPagination from '@/components/list/ListPagination.vue'
import typeSelector from '../components/typeSelector'
import enabledSelector from '../components/enabledSelector'

export default {
  components: {
    searchPanel,
    searchField,
    listPagination,
    typeSelector,
    enabledSelector,
  },
  data() {
    return {
      moduleCodes: [],
      params: {
        moduleCode: '',
        moduleName: '',
        version: '',
        versionID: '',
        type: '',
        enabled: true, //默认只查询所有
        pageNumber: 0,
        pageSize: 10,
        directionProperties: 'versionID',
      },
      result: {
        tableData: [],
        total: 0,
        pageNumber: 0,
        pageSize: 0,
      },
      loading: false,
      loadfailed: false,
    }
  },
  methods: {
    search() {
      getBackendModuleList(JSON.stringify(this.params)).then((response) => {
        this.result.tableData = response.content
        this.result.total = response.totalElements
        this.result.pageNumber = response.number
        this.result.pageSize = response.size
      })
    },
    handleCurrentChange: function (val) {
      this.params.pageNumber = val - 1
      this.search()
    },
    moduleCodeChange(){
      this.search()
    },
    toAdd() {
      this.$router.push({
        name: 'sys_admin_backend_module_add',
        params: { moduleTab: this.params.moduleCode },
      })
    },
    toInfo(param) {
      this.$router.push({
        name: 'sys_admin_backend_module_info',
        params: { id: param, moduleTab: this.params.moduleCode },
      })
    },
    toEdit(param) {
      this.$router.push({
        name: 'sys_admin_backend_module_edit',
        params: { id: param, moduleTab: this.params.moduleCode },
      })
    },
    toDel(param) {
      this.$router.push({
        name: 'sys_admin_backend_module_del',
        params: { id: param, moduleTab: this.params.moduleCode },
      })
    },
    toCopy(param) {
      this.$router.push({
        name: 'sys_admin_backend_module_copy',
        params: { id: param, moduleTab: this.params.moduleCode },
      })
    },
    dateFormat: function (row, column) {
      return moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
    },
    downloadLib() {
      axios({
        url: '/backendModule/downloadLib',
        method: 'get',
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      }).then(
        (response) => {
          if (!response) {
            this.$message.error('下载文件失败')
            return false
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: 'application/zip' })
          )
          // 文件地址
          const link = document.createElement('a')
          link.download = 'lib.zip'
          link.href = objectUrl
          link.click()
          URL.revokeObjectURL(link) // 释放内存
        },
        (err) => {
          if (err) this.$confirm('', '下载文件异常，请联系管理员！', {})
        }
      )
    },
    toDownloadFullApp(id, fileName) {
      axios({
        url: '/backendModule/downloadFullApp',
        method: 'get',
        params: { id: id },
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      }).then(
        (response) => {
          if (!response) {
            this.$message.error('下载文件失败')
            return false
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: 'application/zip' })
          )
          // 文件地址
          const link = document.createElement('a')
          link.download = fileName + '.zip'
          link.href = objectUrl
          link.click()
          URL.revokeObjectURL(link) // 释放内存
        },
        (err) => {
          if (err) this.$confirm('', '下载文件异常，请联系管理员！', {})
        }
      )
    },
  },
  created() {
    getModuleCodes().then((response) => {
      this.moduleCodes = response.data.moduleCodes
      if (this.moduleCodes.length >= 0) {
        this.params.moduleCode = this.moduleCodes[0]
      }
      if (this.$route.params.moduleTab) {
        this.params.moduleCode = this.$route.params.moduleTab
      }
      this.search()
    })
  },
  mounted() {},
}
</script>

<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}

.el-select {
  width: 100%;
}
</style>