<template>
  <el-checkbox-group v-model="svalue" :disabled="sdisabled">
    <el-checkbox
      v-for="useType in useType_Options"
      :label="useType.value"
      :key="useType.value"
      >{{ useType.label }}</el-checkbox
    >
  </el-checkbox-group>
</template>

<script>
export default {
  name: "useTypeCheckbox",
  props: {
    value: {},
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      svalue: this.value,
      sdisabled: this.disabled,
      useType_Options: [
        { value: "FRONTEND", label: "前端服务器" },
        { value: "BACKEND", label: "后端服务器" },
        { value: "AGENT", label: "代理服务器" },
        { value: "DATABASE", label: "数据库服务器" },
        { value: "FILE", label: "文件库服务器" },
      ],
    };
  },
  methods: {},
  watch: {
    value(newVal) {
      this.svalue = newVal;
    },
    disabled(newVal) {
      this.sdisabled = newVal;
    },
    svalue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>