<template>
	<div :class="model.style">
		<LayoutContainer :model="child" v-for="child in model.children" :key="child.id"></LayoutContainer>
		<div v-for="(c,k) in webparts" :key="k">
			<a
				class="document-link"
				href="javascript:void(0)"
				title="配置webpart。"
				v-if="isAdmin"
				@click="showRoles(c)"
			>
				<i class="el-icon-lock" />
			</a>
			<component v-bind:is="c.name" v-bind="c.params"/>
		</div>
		<el-dialog title="配置Webpart" :visible.sync="dialogVisible" width="50%">
			<el-alert
				title="此配置按钮只有系统管理员可见，不会影响正常用户使用。"
				type="warning"
				:closable="false"
				style="margin-bottom:20px"
			></el-alert>
			<el-alert
				title="反选所有时表示[所有用户]都可见。"
				v-if="!selectedRoles.length"
				type="warning"
				:closable="false"
				style="margin-bottom:20px"
			></el-alert>以下角色可见：
			<el-checkbox-group v-model="selectedRoles" :disabled="unset">
				<el-checkbox
					style="width:20%"
					:label="item.value"
					v-for="item in all_roles"
					:key="item.value"
				>{{item.label}}</el-checkbox>
			</el-checkbox-group>排序：
			<el-input-number v-model="selectedIndex" />
			<br />
			<el-switch
				style="display: block"
				v-model="unset"
				active-color="#13ce66"
				inactive-color="#ff4949"
				active-text="默认值"
				inactive-text="自定义"
			></el-switch>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleSave">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/api/request";

export default {
	name: "LayoutContainer",
	props: ["model"],
	data() {
		return {
			all_roles: [
				{ label: "政数局", value: "ZHENG_SHU_JU" },
				{ label: "业务部门", value: "YE_WU_BU_MEN" },
				{ label: "镇街", value: "ZHEN_JIE" },
				{ label: "村居", value: "CUN_JU" },
				{ label: "系统管理员", value: "XI_TONG_GUAN_LI_YUAN" },
				{ label: "部门管理员", value: "JI_GOU_GUAN_LI_YUAN" },
				{ label: "实施人员", value: "SHI_SHI_REN_YUAN" },
				{ label: "政务工作人员", value: "ZHENG_WU_GONG_ZUO_REN_YUAN" }
			],
			selectedCom: null,
			selectedRoles: [],
			selectedIndex: 999,
			unset: false,
			dialogVisible: false,
			oldConfig: {}
		};
	},
	methods: {
		async showRoles(com) {
			const c = this.webpartsConfig[com.name];
			this.selectedRoles = (c && c.roles) || com.roles || [];
			this.selectedIndex = (c && c.index) || 999;
			this.selectedCom = com;
			this.dialogVisible = true;
			this.unset = false;
		},
		visiable(com) {
			if (this.isAdmin) return true;
			if (!com.name) return false;
			const c = this.webpartsConfig[com.name];
			const comRoles = (c && c.roles) || com.roles;
			if (!comRoles) return true;
			let roles = this.$store.getters.userdata.roles || [];
			return comRoles.some(x => roles.some(r => r.code === x));
		},
		loaddata() {
			this.children = this.model.children || [];
			this.components = this.model.components || [];
		},
		async handleSave() {
			const name = this.selectedCom.name;
			const old = await this.loadConfig();
			let old_webparts;
			try {
				old_webparts = JSON.parse(old.webparts || {});
			} catch {
				old_webparts = {};
			}

			// 把对象重新复制，当是设置为unset时可以跳过该属性
			const n = Object.assign({}, old, { webparts: {} });
			for (let key in old_webparts) {
				if (key !== name) {
					n.webparts[key] = old_webparts[key];
				}
			}
			if (!this.unset) {
				n.webparts[name] = Object.assign({}, n.webparts[name], {
					roles: this.selectedRoles,
					index: this.selectedIndex
				});
			}

			n.webparts = JSON.stringify(n.webparts);
			this.saveConfig(n);
			this.dialogVisible = false;
		},
		saveConfig(c) {
			return new Promise((resolve, reject) => {
				axios({
					url: `/plugin/save_pro_config`,
					params: {
						name: "desktop-web",
						appId: this.appId || window.APP_CONFIG.VUE_APP_ID_DEFAULT
					},
					method: "post",
					headers: {
						"Content-Type": "application/json"
					},
					data: JSON.stringify(c)
				})
					.then(() => {
						this.$message.success("保存成功");
						resolve();
					})
					.catch(() => {
						this.$message.error("保存失败");
						reject();
					});
			});
		},
		loadConfig() {
			return new Promise((resolve, reject) => {
				let t_appId = this.appId || window.APP_CONFIG.VUE_APP_ID_DEFAULT;
				axios({
					url: "/plugin/get_config",
					params: {
						name: "desktop-web",
						appId:t_appId
					}
				})
					.then(res => {
						let c = res.find(x => x.level.toLowerCase() === "pro");
						resolve((c && c.value && JSON.parse(c.value)) || {});
					})
					.catch(() => {
						reject();
					});
			});
		}
	},
	computed: {
		webparts() {
			let list = this.model.components || [];
			return list
				.filter(x => this.visiable(x))
				.sort((x, y) => {
					const xi =
						(this.webpartsConfig[x.name] &&
							this.webpartsConfig[x.name].index) ||
						999;
					const yi =
						(this.webpartsConfig[y.name] &&
							this.webpartsConfig[y.name].index) ||
						999;
					return xi - yi;
				});
		},
		webpartsConfig() {
			try {
				return JSON.parse(this.configString);
			} catch {
				return {};
			}
		},
		...mapState({
			configString: state =>
				(state.app && state.app.config && state.app.config.webparts) || "{}",
			isAdmin: state =>
				state.user &&
				state.user.userdata &&
				state.user.userdata.roles &&
				state.user.userdata.roles.some(x => x.code === "XI_TONG_GUAN_LI_YUAN"),
			appId:state =>state.appId,
		})
	},
	watch: {
		unset(val) {
			if (val) {
				this.selectedRoles = [].concat(this.selectedCom.roles || []);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.document-link {
	position: absolute;
	display: block;
	width: 26px;
	height: 26px;
	right: 0px;
	cursor: pointer;
	background: #409eff;
	z-index: 1;
	border-radius: 0 0 6px 0;
	text-align: center;
	line-height: 26px;
	color: #fff;
	font-size: 18px;
	opacity: 0.1;
	&:hover {
		opacity: 1;
	}
}
</style>