<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{backendModule.moduleCode}}{{titleName}}</h1>

      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="backList" icon="el-icon-back">返回</el-button>
        <el-button type="primary" plain @click="toEdit(dataId)" icon="el-icon-edit">修改</el-button>
        <el-button type="primary" plain @click="toAddConfig(dataId)" icon="el-icon-plus">新增配置</el-button>
        <el-button type="primary" plain @click="toAddTask(dataId)" icon="el-icon-plus">新增任务</el-button>
        <el-button
          type="primary"
          plain
          @click="toDownloadFullApp(dataId,backendModule.moduleCode+'-'+backendModule.version)"
          icon="el-icon-download"
        >下载完整包</el-button>
        <el-button type="success" plain @click="toCopy(dataId)" icon="el-icon-plus">复制</el-button>
        <el-button type="danger" plain @click="toDel(dataId)" icon="el-icon-delete">删除</el-button>
      </span>
    </section>
    <section class="content">
      <el-tabs v-model="moduleCard" type="border-card" tab-position="top" >
        <el-tab-pane label="基本信息" name="baseInfo">
          <moduleBaseInfo v-if="moduleCard == 'baseInfo'"
            :pmoduleID="this.dataId" @backendMoule="getBackendMoule">
          </moduleBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="配置清单" name="configInfo" >
          <div><span style="color:red">注意：配置文件中的端口配置已经不生效，启动服务端口以部署实施中的端口为准！</span></div>
          <moduleConfigList v-if="moduleCard == 'configInfo'"
            :pmoduleID="this.dataId"
          ></moduleConfigList>
        </el-tab-pane>
        <el-tab-pane label="部署实施" name="taskInfo" >
          <implementTaskList v-if="moduleCard == 'taskInfo'"
            :pmoduleID="this.dataId"
          ></implementTaskList>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import axios from "@/api/request";
import moduleBaseInfo from "./baseInfo";
import moduleConfigList from "../config/list/index";
import implementTaskList from "../deploy/list/index";

export default {
  components: {
    moduleBaseInfo,
    moduleConfigList,
    implementTaskList,
  },
  data() {
    return {
      dataId: "",
      moduleTab: "",
      titleName: "后端模块详情",
      backendModule: {},
      moduleFile: {},
      moduleCard: "baseInfo",
    };
  },
  methods: {
    backList() {
      this.$router.push({
        name: "sys_admin_backend_module",
        params: { moduleTab: this.moduleTab },
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_backend_module_edit",
        params: { id: param, moduleTab: this.moduleTab },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_backend_module_del",
        params: { id: param, moduleTab: this.moduleTab },
      });
    },
    toAddConfig(param) {
      this.$router.push({
        name: "sys_admin_backend_module_config_add",
        params: { moduleID: param, moduleTab: this.moduleTab },
      });
    },
    toAddTask(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_add",
        params: { moduleID: param, moduleTab: this.moduleTab },
      });
    },
    toCopy(param){
      this.$router.push({
        name: "sys_admin_backend_module_cp",
        params: { moduleID: param, moduleTab: this.moduleTab },
      });
    },
    toDownloadFullApp(id, fileName) {
      axios({
        url: "/backendModule/downloadFullApp",
        method: "get",
        params: { id: id },
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
      }).then(
        (response) => {
          if (!response) {
            this.$message.error("下载文件失败");
            return false;
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: "application/zip" })
          );
          // 文件地址
          const link = document.createElement("a");
          link.download = fileName + ".zip";
          link.href = objectUrl;
          link.click();
          URL.revokeObjectURL(link); // 释放内存
        },
        (err) => {
          if (err) this.$confirm("", "下载文件异常，请联系管理员！", {});
        }
      );
    },
    handleCreate() {},

    getBackendMoule(backendModule){
       this.backendModule = backendModule
    }
  },
  created() {
    this.dataId = this.$route.params.id;
    this.moduleTab = this.$route.params.moduleTab;
    if (this.$route.params.tabName) {
      this.moduleCard = this.$route.params.tabName;
    }
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>