import Vue from "vue";
import store from "@/store/index.js";

/**角色指令**/
export const hasRole = Vue.directive("hasRole", {
  // 当被绑定的元素插入到 DOM 中时判断权限
  inserted: function(el, binding) {
    // 获取指令按钮权限，多个角色使用","分隔
    let roles = binding.value.split(",");
    if (roles) {
      let remove = true;
      if (store.getters.userdata && store.getters.userdata.roles && roles.some((x) => new Set(store.getters.userdata.roles.map((y) => y.code.trim())).has(x.trim()))) {
        remove = false;
      }
      if (remove) {
        el.parentNode.removeChild(el);
      }
    }
  },
});

/**权限指令**/
export const hasPermission = Vue.directive("hasPermission", {
  // 当被绑定的元素插入到 DOM 中时判断权限
  inserted: function(el, binding) {
    // 获取指令按钮权限，多个权限使用","分隔
    let permissions = binding.value.split(",");
    if (permissions) {
      let remove = true;
      if (
        store.getters.userdata &&
        store.getters.userdata.rolePermissionVos &&
        permissions.some((x) => new Set(store.getters.userdata.rolePermissionVos.map((y) => y.target.trim() + ":" + y.action.trim())).has(x.trim()))
      ) {
        remove = false;
      }
      if (remove) {
        el.parentNode.removeChild(el);
      }
    }
  },
});

/**角色和权限指令**/
export const hasRoleOrPermission = Vue.directive("hasRoleOrPermission", {
  // 当被绑定的元素插入到 DOM 中时判断权限
  inserted: function(el, binding) {
    // 获取指令按钮权限，多个权限使用","分隔
    let rolePermissions = binding.value.split(",");
    if (rolePermissions) {
      let remove = true;
      if (store.getters.userdata && store.getters.userdata.roles && rolePermissions.some((x) => new Set(store.getters.userdata.roles.map((y) => y.code.trim())).has(x.trim()))) {
        remove = false;
      }
      if (
        store.getters.userdata &&
        store.getters.userdata.rolePermissionVos &&
        rolePermissions.some((x) => new Set(store.getters.userdata.rolePermissionVos.map((y) => y.target.trim() + ":" + y.action.trim())).has(x.trim()))
      ) {
        remove = false;
      }
      if (remove) {
        el.parentNode.removeChild(el);
      }
    }
  },
});
