<template>
  <div :class="svgClass" v-html="content">
  </div>
</template>
 
<script>
import { mapState } from 'vuex'
import defaultIcons from './defaultIcons'
import { get_svg } from '@/api/iconApi'

export default {
  name: 'icon-svg',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    appId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      icons: {},
    }
  },
  computed: {
    content() {
      return this.icons[this.iconClass];
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    appIdToLoad() {
      return this.appId || this.appIdInSession || 'default'
    },
    ...mapState({
      appIdInSession: (state) => state.appModules.appId,
    }),
  },
  async mounted() {
    let icons = await get_svg(this.appIdToLoad);
    for (let k in icons) {
      icons[k] = icons[k].replace(/width="[^"]+"/g, 'width="100%"')
        .replace(/height="[^"]+"/g, 'height="100%"')
    }
    this.icons = { ...icons, ...defaultIcons }
  },
}
</script>
 
<style>
.svg-icon {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>