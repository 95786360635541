<template>
	<div class="content-wrapper">
		<section class="content-header">
			<h1>运行管理</h1>
		</section>
		<!-- Main content -->
		<section class="content">
			<div class="container-fluid">
			</div>
		</section>
	</div>
</template>

<script>
export default {
	
};
</script>



