<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{ titleName }}</h1>

      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="backList" icon="el-icon-back"
          >返回</el-button
        >
        <el-button
          type="danger"
          plain
          @click="toDel(packageName)"
          icon="el-icon-delete"
          >删除</el-button
        >
      </span>
    </section>
    <section class="content">
      <el-main style="background: #fff">
        <el-form label-width="120px" :disabled="true">
          <el-row>
            <el-col :span="12">
              <el-form-item label="依赖包名称：">
                <el-input
                  v-model="depPackageVo.packageName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="依赖包版本：">
                <el-input
                  v-model="depPackageVo.packageVersion"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="依赖包大小：">
                <el-input
                  v-model="depPackageVo.packageSize"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="依赖包路径：">
                <el-input
                  v-model="depPackageVo.path"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getDepPackageByName } from "@/api/deppackage.js";

export default {
  components: {},
  data() {
    return {
      packageName: "",
      titleName: "依赖包详情信息",
      depPackageVo: {packageSize:""},
    };
  },
  methods: {
    backList() {
      this.$router.push({
        name: "sys_admin_deppackage",
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_deppackage_del",
        params: { packageName: param },
      });
    },
    handleCreate() {},
  },
  created() {
    this.packageName = this.$route.params.packageName
      getDepPackageByName(this.packageName).then((response) => {
        this.depPackageVo = response;
        this.depPackageVo.packageSize = this.depPackageVo.packageSize+"K"
      });
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>