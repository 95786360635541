import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import Login from "../views/Login.vue";
import login from "../views/logout.vue";
import thirdAuthSuccess from "../views/thirdAuthSuccess.vue";
import thirdAuthFaild from "../views/thirdAuthFaild.vue";
import yzhAuthFaild from "../views/yzhAuthFaild.vue";
import Layout from "../components/Layout/Layout.vue";
import LayoutNew from "../components/LayoutNew/Layout.vue";
import LayoutHbxn from "../components/LayoutHbxn/Layout.vue";
import error404 from "@/views/error/404.vue";
import error403 from "@/views/error/403.vue";
import store from "../store";
import {
  toLogin,
  checkIfGlobalLogout,
  excludeGlobalLogout,
  checkAuthOnAppInited
} from '@/utils/loginManager'
import { getAppModuleListPublic,getPubAppList } from '@/api/appModule'
import {
  yzhAuth
} from "@/api/auth";
import QueryString from "qs"
import Cookies from 'js-cookie'
Vue.use(VueRouter);

const route_root = (window.APP_CONFIG && window.APP_CONFIG.VUE_APP_ROUTE_ROOT) || "/a/";

// 重要！！！
// 必须把 Layout 的路由放在第一，各个子模块合并路由时嵌入到第一个路由下。
const routes = [{
    path: route_root,
    component: Layout,
    name: "root",
    children: [{
      path: "home",
      name: "home",
      component: Home,
      meta: {
        nav: false,
        roles: ['XI_TONG_GUAN_LI_YUAN', 'QU_HUA_GUAN_LI_YUAN', 'BU_MEN_GUAN_LI_YUAN', 'ZHENG_WU_GONG_ZUO_REN_YUAN', 'SHI_SHI_REN_YUAN', 'AN_QUAN_YUAN', 'SHEN_JI_YUAN'],
      },
    }, ],
  },
  //相同名字的路由，按加载顺序优先匹配，顺序不能乱
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/:appId/login",
    name: "dynamicLogin",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: login,
  },
  {
    path: "/thirdAuthSuccess",
    name: "thirdAuthSuccess",
    component: thirdAuthSuccess,
  },
  {
    path: "/thirdAuthFaild",
    name: "thirdAuthFaild",
    component: thirdAuthFaild,
  },
  {
    path: "/yzhAuthFaild",
    name: "yzhAuthFaild",
    component: yzhAuthFaild,
  },
  {
    path: "/error/404",
    name: "error404",
    component: error404,
  },
  {
    path: "/error/403",
    name: "error403",
    component: error403,
  },
  {
    path: "/",
    name: "index",
    redirect: {
      name: (window.APP_CONFIG && window.APP_CONFIG.VUE_APP_CUSTOM_HOME) || "home",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});



router.beforeEach((to, from, next) => {
  checkIfGlobalLogout(store); //检查是否已全局退出
  if (window.APP_CONFIG.IS_GET_COOKIE != 'true') {
    let closeWindowTime = sessionStorage.getItem("closeWindowTime");
    if (!closeWindowTime) { //重新打开浏览器清理用户会话信息
      let TEMP_TOKEN_TIME = localStorage.getItem("TEMP_TOKEN_TIME");
      let cache_token_time = window.APP_CONFIG.CACHE_TOKEN_TIME || 10000;
      if (!TEMP_TOKEN_TIME || new Date().getTime() - TEMP_TOKEN_TIME > cache_token_time) { //没有临时token或者从关闭浏览器到重新打开浏览器超过10秒，清理用户会话信息，包括临时token
        cleanLoginData(store);
      }
    }
  }

  // 粤治慧第三方登录跳转到工作台 
  let YZH_EXCHANGE_TOKEN = to.query.YZH_EXCHANGE_TOKEN;
  // 判断是否存在YZH_EXCHANGE_TOKEN参数,若存在调用接口用YZH_EXCHANGE_TOKEN换取工作台token
  if (YZH_EXCHANGE_TOKEN) {
    let p = new Promise((resolve) => {
      // 先退出当前账号再登录
      store.dispatch('set_token', '')
      store.dispatch('set_userdata', '')
      localStorage.removeItem('TEMP_TOKEN')
      Cookies.remove('TEMP_TOKEN');
      resolve(true);
      console.log('先退出当前账号再登录');
    })
    p.then(() => {
      // 已经退出成功
      if (!store.getters.has_token) {
        yzhAuth(QueryString.stringify({
          yzhExchangeToken: YZH_EXCHANGE_TOKEN
        })).then(
          (res) => {
            if (res.meta.code == 200) {
              let token = res.data.jwt;
              store.dispatch("set_token", token);
              let pp = new Promise((resolve) => {
                checkAuthOnAppInited(this,store,to);
                resolve(true);
              })
              pp.then(() => {
                next();
              })
            } else {
              next({
                name: 'yzhAuthFaild'
              });
            }
          });
      }
    })
  }
  
  if (!YZH_EXCHANGE_TOKEN && store.getters.has_token && store.getters.userdata) {
    let userData = store.getters.userdata
    let flag = false
    //判断当前用户是否有权限访问路由页面，若没有权限则跳转到error403页面
    if (to.meta.roles || to.meta.rolePermissions) {
      if (to.meta.roles && to.meta.roles.some((roleCode) => new Set(userData.roles.map((c_role) => c_role.code.trim())).has(roleCode.trim()))) {
        flag = true;
      }
      if (
        to.meta.rolePermissions &&
        to.meta.rolePermissions.some((permission) =>
          new Set(userData.rolePermissionVos.map((c_permission) => c_permission.target.trim() + ":" + c_permission.action.trim())).has(permission.trim())
        )
      ) {
        flag = true;
      }
      if (!flag) {
        next({
          path: "/error/403"
        });
      }
    }
    next();
  } else {
    if (store.state.app.inited == true && (to.meta.roles || to.meta.rolePermissions)) { //vue为初始化完时，子路由还没挂载
      // 判断是否存在YZH_EXCHANGE_TOKEN参数,若不存在则跳到登录页面
      if (!YZH_EXCHANGE_TOKEN) {
        // 判断是否有loginType参数，有则传递
        toLogin(store,router, undefined, to.query.loginType);
      }
    } else {
      if (!YZH_EXCHANGE_TOKEN) {
        next();
      }
    }
  }

});
export default router;