<template>
  <div class="wrapper" :class="{ 'sidebar-collapse': hide_nav || noframe, noframe: noframe || menu_style }">

    <LeftNav />

    <MainHeader v-if="!noframe" @updateActiveIcon="updateMarginLeft" />

    <!-- 第一种菜单 -->
    <Sidebar v-if="!hide_nav && !noframe && !menu_style" />
    <!-- 第二种菜单isCollapse判断菜单的显示与否 -->
    <Sidebar v-if="!hide_nav && !noframe && isCollapse && menu_style" />

    <keep-alive :include="$store.state.cache.cachedRouteNames">
      <router-view v-if="isRouterAlive" :style="{ backgroundColor: bgcolor, marginLeft: marginLeft }"></router-view>
    </keep-alive>

    <aside class="control-sidebar control-sidebar-dark"></aside>
    <footer class="main-footer" v-if="!noframe"></footer>
  </div>
</template> 
<script>
import { mapState } from "vuex";
import { Sidebar, MainHeader, LeftNav } from "./components";
import $ from "jquery";
export default {
  name: "Layout",
  data: function () {
    return {
      marginLeft: null, //默认宽度是4%，登录进来的宽度
      isCollapse: false,
      //刷新当前页面
      isRouterAlive: true,
    };
  },
  computed: {
    hide_nav() {
      return this.$route.meta && this.$route.meta.nav === false;
    },
    noframe() {
      return !!this.$store.state.app.noframe;
    },
    ...mapState({
      bgcolor: (state) => (state.app.config && state.app.config.main_bg) || "",
      menu_style: (state) => state.app.config && state.app.config.menu_style,
    }),
  },
  components: {
    Sidebar,
    MainHeader,
    MainHeader,
    Sidebar,
    LeftNav
  },
  created() {
    //一开始初始化的时候
    if (this.$route.name === "home") {
      if (this.menu_style) {
        this.marginLeft = "91px !important"; //第二种菜单
      } else {
        this.marginLeft = "74 !important"; //第一种菜单
      }
    } else {
      // 当首页不是home的时候，宽度的设定
      if (this.menu_style) {
        this.isCollapse = true;
        this.marginLeft = "310px !important";
        sessionStorage.setItem("isCollapse", JSON.stringify(this.isCollapse));
        sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
      } else {//第一种菜单，其他非首页得宽度
        this.marginLeft = "244px !important";
        sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
      }
      // 如果设置了nav为false，则内容页全屏显示
      if (this.$route.meta && this.$route.meta.nav === false) {
        this.marginLeft = "74 !important";
        sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
      }
    }
    this.isCollapse =
      JSON.parse(sessionStorage.getItem("isCollapse")) || this.isCollapse;
    this.marginLeft =
      JSON.parse(sessionStorage.getItem("marginLeft")) || this.marginLeft;
  },
  methods: {
    reload() {
      // console.log('shuaxin')
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    fix_footer() {
      if (!this.inFrame) {
        setTimeout(() => {
          $.fn.layout.Constuctor.prototype.fix();
        }, 1);
      }
    },
    updateMarginLeft(itme) {
      //第二种菜单的折叠方式
      this.isCollapse = itme;
      sessionStorage.setItem("isCollapse", JSON.stringify(this.isCollapse));
      if (this.menu_style) {
        if (itme) {
          this.marginLeft = "310px !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        } else {
          this.marginLeft = "91px !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        }
      } else {
        if (itme) {
          this.marginLeft = "244px !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        } else {
          this.marginLeft = "74 !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        }
      }
    },
  },
  watch: {
    $route: function () {
      this.reload();
      this.fix_footer();
      if (this.menu_style) {
        // 不展示菜单的时候，页面全屏展示
        if (this.$route.meta && this.$route.meta.nav === false) {
          this.marginLeft = "91px !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
          return;
        }
        if (this.$route.name === "home") {
          this.marginLeft = "91px !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        } else {
          //页面回退到首页，首页再回退的时候宽度自适应
          if (this.isCollapse) {
            this.marginLeft = "310px !important";
          } else {
            this.marginLeft = "91px !important";
          }
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        }
      } else {
        if (this.$route.meta && this.$route.meta.nav === false) {
          this.marginLeft = "74 !important";
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
          return;
        }
        if (this.$route.name === "home") {
          this.marginLeft = "74 !important";
        } else {
          this.marginLeft = "244px !important";
        }
        sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
      }
    },
    menu_style: {
      handler(newValue) {
        if (!newValue) {
          if (this.$route.name === "home") {
            this.marginLeft = "74px !important";
          } else {
            this.marginLeft = "244px !important";
          }
          sessionStorage.setItem("marginLeft", JSON.stringify(this.marginLeft));
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fix_footer();
  },
};
</script>
<style scoped>
.sidebar-mini.sidebar-collapse .main-sidebar {
  display: none;
}

.sidebar-mini.sidebar-collapse .content-wrapper,
.sidebar-mini.sidebar-collapse .right-side,
.sidebar-mini.sidebar-collapse .main-footer {
  margin-left: 74 !important;
}
</style>
<style>
.page-header-nav li a {
  font-size: 12pt;
  padding: 5px 8px;
}

.fixed .noframe .content-wrapper {
  padding-top: 0 !important;
}

.fixed .content-wrapper {
  padding-top: 54px !important;
  background: #F5F7F9;
  margin-left: 74px;
}

.fixed .right-side {
  padding-top: 0 !important;
}

.main-footer {
  /* margin-left: 301px!important; */
  /* 折叠的宽度 */
  /* margin-left: 4% !important; */
  /* 打开的宽度 */
  /* margin-left: 17% !important; */
}

.main-header {
  max-height: 0px !important;
}
</style>