import request from "@/api/request";

const authRequest = {
    baseURL:window.APP_CONFIG.VUE_APP_AUTH_URL,
}

/**********************
 *
 * 认证接口服务
 *
 **********************/

//用户登录
export function userLogin(p_username, p_password, p_verifyCode, p_verifyCodeEnabled,p_verifyCodeToken, p_secondVerifyCode) {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/login",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "VERIFY-CODE-TOKEN": p_verifyCodeToken,
    },
    data: JSON.stringify({
      username: p_username,
      password: p_password,
      verifyCode: p_verifyCode,
      verifyCodeEnabled: p_verifyCodeEnabled,
      secondVerifyCode: p_secondVerifyCode,
    }),
  });
}

//获取验证码
export function getVerifyCode() {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getVerifyCode",
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getPublicKey() {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getPublicKey",
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//用户退出
export function userLogout() {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/logout",
  });
}

//获取当前用户信息
export function getCurrentLoginUser() {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getCurrentUser",
    method: "get",
  });
}

//获取cas当前用户信息
export function getCasCurrentLoginUser(casTicket, p_clientServiceUrl) {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getCurrentUser",
    method: "get",
    headers: { "CAS-TICKET": casTicket, clientServiceUrl: p_clientServiceUrl },
  });
}

//刷新usertoken
export function getRefreshToken() {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/refreshToken",
  });
}

//第三方认证服务登录，返回本地user token
export function thirdLogin(authType) {
  return request({
    baseURL: authRequest.baseURL,
    url: "/third/sec/login?authType=" + authType,
  });
}

//设置用户机构
export function setUserOrg(userOrgForm) {
    return request({
        baseURL: authRequest.baseURL,
        url: "/user/init/setUserOrg",
        method: "post",
        data: userOrgForm,
        headers: { "Content-Type": "application/json" },
    });
  }

  //获取用户登录有效性
export function getLoginValidity(param) {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getLoginValidity",
    method: "get",
    params: {token: param},
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getInfoByAccountId(param) {
  return request({
    baseURL: authRequest.baseURL,
    url: "/sec/getInfoByAccountId",
    method: "get",
    params: {accountId: param},
    headers: {
      "Content-Type": "application/json",
    },
  });
}


//工作台对接粤治慧服务认证
export function yzhAuth(yzhExchangeToken) {
  return request({
      baseURL: authRequest.baseURL,
      url: "/api/sec/yzh/auth",
      method: "post",
      data: yzhExchangeToken,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
}

export default authRequest