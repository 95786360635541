<template>
  <div class="box box-info">
    <div class="box-header with-border">
      <h3 class="box-title">消息提醒</h3>
    </div>
    <div class="box-body">
      消息提醒
    </div>
  </div>
</template>

<script>
export default {
  name: 'desktop_message'
}
</script>

<style>

</style>