<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form
          ref="appForm"
          :model="appForm"
          status-icon
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="名称：" prop="app.appName">
                <el-input v-model="appForm.app.appName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应用标识：" prop="app.appId">
                <el-input v-model="appForm.app.appId" :disabled=true></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：" prop="app.description">
                <el-input
                  v-model="appForm.app.description"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item align="center">
            <el-button type="warning" icon="el-icon-back" @click="toInfo(dataId)">取消</el-button>
            <el-button type="primary" icon="el-icon-upload2" @click="commit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getAppById,editApp } from "@/api/appModule.js";
export default {
  components: {
  },
  data() {
    return {
      titleName: "修改应用",
      appForm: {
        app: {},
      },
      rules: {
        "app.appName": [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, max: 100, message: "请输入2-100位字符", trigger: "blur" },
        ],
        "app.appId": [
          { required: true, message: "请输入应用标识", trigger: "blur" },
          { min: 2, max: 50, message: "请输入2-50位字符", trigger: "blur" },
        ],
        "app.description": [
          { max: 2000, message: "请输入2000位以内字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    commit() {
      this.$refs["appForm"].validate((valid) => {
        if (valid) {
          editApp(JSON.stringify(this.appForm)).then(
            (response) => {
              this.toInfo(response.id);
            },
            (err) => {
              if (err.response.status == 500) {
                this.$confirm(err.response.data.data, "修改应用异常", {});
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    toInfo(param) {
      this.$router.push({
        name: "sys_admin_app_info",
        params: { id: param },
        query: { id: param },
      });
    },
  },
  created() {
    this.dataId = this.$route.query.id;
    getAppById(this.dataId).then((response) => {
      this.appForm.app = response;
    });
  },
  mounted() {
    
  },
  filters: {},
  watch: {
    
  },
};
</script>
<style scoped>
.content-header-operation {
  right: 14x;
}
.content-header-operation el-button {
  padding: 8px 16px;
}
</style>