<template>
  <el-main>
    <el-row>
      <el-input v-model="json" :rows="10" type="textarea"></el-input>
    </el-row>
    <el-row style="padding-top:20px">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="reload">重新加载</el-button>
    </el-row>
  </el-main>
</template>

<script>
import { saveConfig, getConfig } from '@/api/desktop.js'

export default {
  name: 'HomePageConfig',
  data() {
    return {
      json: '',
    }
  },
  props: {
    appId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async reload() {
      let configs = await getConfig(this.appId, 'desktop_layout') || []
      let devConfig = configs.find(x => x.level === 'DEV') || { value: "{}"} 
      let configValue = devConfig.value
      let obj = JSON.parse(configValue)
      delete obj.level
      this.json = JSON.stringify(obj, null, 4)
    },
    async save() {
      let config = JSON.parse(this.json)
      config.level = 'DEV'
      await saveConfig(this.appId, 'desktop_layout', 'dev', config)
      this.reload()
    },
  },
  mounted() {
    this.reload()
  },
}
</script>

<style lang="scss" scoped>
</style>

