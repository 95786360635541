<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>{{titleName}}</h1>

      <span class="breadcrumb" align="right">
        <el-button type="warning" plain @click="toModule" icon="el-icon-back">返回</el-button>
        <el-button type="primary" plain @click="toEdit(dataId)" icon="el-icon-edit">修改</el-button>
        <el-button type="danger" plain @click="toDel(dataId)" icon="el-icon-delete">删除</el-button>
        <el-button type="primary" plain @click="toImpl(dataId)" icon="el-icon-setting">部署</el-button>
        <el-button type="primary" plain @click="toStart(dataId)" icon="el-icon-video-play">启动</el-button>
        <el-button type="primary" plain @click="toStop(dataId)" icon="el-icon-video-play">停止</el-button>
      </span>
    </section>
    <section class="content">
      <el-main style="background: #fff;">
        <el-form :disabled="true" label-width="120px">
          <el-row>
            <el-col>
              <el-form-item label="任务名称：">
                <el-input v-model="implementTask.taskName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模块代码：">
                <el-input v-model="backendModule.moduleCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块名称：">
                <el-input v-model="backendModule.moduleName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="模块版本号：">
                <el-input v-model="backendModule.version"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="模块版本标识：">
                <el-input v-model="backendModule.versionID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="服务器：">
                <serverSelector v-model="implementTask.serverMachineID"></serverSelector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="端口：" prop="implementTask.deployPort">
                <el-input v-model="implementTask.deployPort"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="部署基路径：">
                <el-input v-model="implementTask.deployPath"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4}"
                  placeholder="请输入内容"
                  v-model="implementTask.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </section>
  </div>
</template>

<script>
import { getImplementTaskById, getBackendModuleById } from "@/api/backend.js";
import serverSelector from "../../components/serverSelector";

export default {
  components: { serverSelector },
  data() {
    return {
      moduleID: "",
      dataId: "",
      titleName: "实施任务详情信息",
      implementTask: {},
      backendModule: {},
    };
  },
  methods: {
    toModule() {
      this.$router.push({
        name: "sys_admin_backend_module_info",
        params: { id: this.moduleID, tabName: "taskInfo" },
      });
    },
    toEdit(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_edit",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toDel(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_del",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toImpl(param) {
      this.$router.push({
        name: "sys_admin_backend_module_deploy_impl",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toStart(param){
      this.$router.push({
        name: "sys_admin_backend_module_deploy_start",
        params: { id: param, moduleID: this.moduleID },
      });
    },
    toStop(param){
      this.$router.push({
        name: "sys_admin_backend_module_deploy_stop",
        params: { id: param, moduleID: this.moduleID },
      });
    },
  },
  created() {
    this.moduleID = this.$route.params.moduleID;
    this.dataId = this.$route.params.id;
    getImplementTaskById(this.dataId).then((response) => {
      this.implementTask = response;
    });
    getBackendModuleById(this.moduleID).then((response) => {
      this.backendModule = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {},
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>