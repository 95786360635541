<template>
  <el-main style="background: #fff;">
    <el-form label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="模块代码：">
            <el-input v-model="backendModule.moduleCode" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="模块名称：">
            <el-input v-model="backendModule.moduleName" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="版本号：">
            <el-input v-model="backendModule.version" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="版本标识：">
            <el-input v-model="backendModule.versionID" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="模块类型：">
            <typeSelector v-model="backendModule.type" :disabled="true"></typeSelector>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否在用：">
            <enabledSelector v-model="backendModule.enabled" :disabled="true"></enabledSelector>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="备注：">
            <el-input
              :disabled="true"
              type="textarea"
              :autosize="{ minRows: 4}"
              placeholder="请输入内容"
              v-model="backendModule.description"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="应用程序包：">
            <el-button type="text" @click="downloadModuleFile">{{moduleFile.fileName}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-main>
</template>

<script>
import axios from "@/api/request";
import { getBackendModuleById, getModuleFileById } from "@/api/backend.js";
import typeSelector from "../components/typeSelector";
import enabledSelector from "../components/enabledSelector";

export default {
  name: "moduleBaseInfo",
  components: {
    typeSelector,
    enabledSelector,
  },
  props: {
    pmoduleID: {},
  },
  data() {
    return {
      dataId: this.pmoduleID,
      backendModule: {},
      moduleFile: {},
    };
  },
  methods: {
    downloadModuleFile() {
      axios({
        url: "/backendModule/downloadModuleFileByModuleId",
        method: "get",
        params: { id: this.moduleFile.id },
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
      }).then(
        (response) => {
          if (!response) {
            this.$message.error("下载文件失败");
            return false;
          }
          // 获取文件名
          let objectUrl = URL.createObjectURL(
            new Blob([response], { type: "application/zip" })
          );
          // 文件地址
          const link = document.createElement("a");
          link.download = this.moduleFile.fileName;
          link.href = objectUrl;
          link.click();
          URL.revokeObjectURL(link); // 释放内存
        },
        (err) => {
          if (err) this.$confirm("", "下载文件异常，请联系管理员！", {});
        }
      );
    },
  },
  created() {
    getBackendModuleById(this.dataId).then((response) => {
      this.backendModule = response;
      this.$emit("backendMoule",this.backendModule)
    });
    getModuleFileById(this.dataId).then((response) => {
      this.moduleFile = response;
    });
  },
  mounted() {},
  filters: {},
  watch: {
    pmoduleID(newVal) {
      this.dataId = newVal;
    },
  },
};
</script>
<style scoped>
.content-header .breadcrumb {
  top: 4px;
}

.content-header .breadcrumb .el-button {
  padding: 10px 20px;
}
</style>